import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Logo  from './../assets/Logo.png'

import './ErrorScreen.css'
import OslerButton from '../components/OslerButton';
import { db } from '../firebase/firebase-setup';
import { dateIntoString } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice'


export default function ErrorScreen({route}) {
    let navigate = useNavigate()
    let location = useLocation()

    const user = useSelector(state => state.user.data)
    const dispatch = useDispatch();

    const [reason, setReason] = useState(
        <>
        <p>Brincadeiras à parte, o mais provável é que seja um problema com a conexão. <u>Verifique sua rede, e tente novamente</u>.</p>

        <p><b>Se persistir, por favor envie um print dessa tela para nós.</b></p>
        </>
    )

    const errorMsg = location.state?.msg;
    const error = location.state?.data;


    useEffect(() => {
        console.log(location)
        console.log(errorMsg)
        dispatch(setIsLoading(false))
    }, [])


    useEffect(() => {
        if (error || errorMsg) {
            if (error.message === 'Failed to get document because the client is offline.') {
                setReason(<>
                    <p>O problema é que <b>você está offline</b>. Verifique sua conexão e Firewall, e tente novamente.</p>
                </>)
            }
            const when = dateIntoString(new Date())
            
            // De nota, se for um problema da conexão, isso aqui nunca vai funcionar.
            db.collection('app_errors').add({
                'error' : error.message,
                'msg' : errorMsg,
                'date' : when,
                'solved' : false,
                'user' : user.id
            })
        }
    }, [error, user.id])


    return (
        <div className = 'ErrorScreen'>
            <div className = 'ErrorScreen-Content' >
                <img className = 'ErrorScreen-Logo' src = {Logo} />

                <p className = 'ErrorScreen-Title'>Interno é foda, né?</p>

                <p>Contamina o campo cirúrgico, lê ECG ao contrário, se perde buscando papel na impressora, e trava a plataforma.</p>

                {reason}
    
                { user.id &&
                    <p className = 'ErrorScreen-Error'>{user.id}</p>
                }
                { errorMsg &&
                    <p className = 'ErrorScreen-Error'>{errorMsg}</p>
                }
                { error && error.message && 
                    <p className = 'ErrorScreen-Error'>{error.message}</p>
                }

                <OslerButton
                    style = {{
                        width: '10em',
                        marginTop: '2em',
                        marginBottom: '4em'}}
                    bgColor = 'blue'
                    text = 'Recarregar'
                    onClick = {() => navigate('/app')} />
            
            </div>
        </div>
    )
}
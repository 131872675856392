

import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";
import session from '../controllers/Session'

import BlackArrow from './../assets/left-arrow-black.png'
import GreyArrow from './../assets/left-arrow-grey.png'


import BlackArrowDarkmode from './../assets/left-arrow-darkMode.png'
import GrayArrowDarkmode from './../assets/left-arrow-darkMode-hover.png'

import './TestScreenNavBar.css'
import styled from 'styled-components';
import { useSelector } from 'react-redux';


const TestScreenNavBarContainer = styled.div`
    /* Mesma width de TestArea */
    width: 100%;
    height: 3vh;
    padding-top: 0.5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    @media (max-width: 500px) {
        /* Mesma width de TestArea */
        width: 100%;
        padding: 0 0.5em 0 0.5em;
        margin-top: 0.25em;
    }
`;

const TestScreenNavBarBackButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: pointer;
`;

const TestScreenNavBarBackButtonIcon = styled.img`
    width: 2em;

`;

const TestScreenNavBarInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const TestScreenNavBarNumber = styled.p`
    font-size: 0.8em;
    margin: 0;
    padding: 0;

    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200);
    ` : `
        color: black;
    `}    

    @media (max-width: 500px) {
        font-size: 0.9em;
        
    }
`;



export default function TestScreenNavBar(props) {
    let navigate = useNavigate();

    const [numberTests, setNumberTests] = useState(false)
    const [currentTest, setCurrentTest] = useState(false)
    const [allowGoBack, setAllowGoBack] = useState(false)


    const darkMode = useSelector(state => state.theme.darkModeOn)

    useEffect(() => {
        if (session) {
            session.addPermanentListener( updateScreen )
        }
        updateScreen()
    }, [])

    useEffect(() => {
        setAllowGoBack(props.allowGoBack)
    }, [props.allowGoBack])

    function updateScreen() {
        if (session) {
            setNumberTests( session.getNumberOfTests() )
            setCurrentTest( session.getCurrentIndex() + 1 )
        }
    }


    async function goBack() {
        // TODO ISso é um absurdo porque permite clicar 2x
        // no botão, o que nunca deveria ser válido,
        // ainda mais dado que temos um await aqui.
        
        // We avoid the history.goBack() method due to 
        // interference with the block on AnswerScreen.
        if(props.onGoBackAction) {
            await props.onGoBackAction()
        }
        
        navigate("/app")
    }


    return (
        <>
        { currentTest && numberTests && 
            <TestScreenNavBarContainer>
                    { allowGoBack && 
                        <TestScreenNavBarBackButton>
                            {/* Essa navabar é igual a de screencard, deveria generalizar */}
                            <TestScreenNavBarBackButtonIcon 
                                src = {darkMode ? BlackArrowDarkmode : GreyArrow}
                                onMouseOver = {e => e.currentTarget.src = darkMode ? GrayArrowDarkmode : BlackArrow}
                                onMouseOut = {(e) => e.currentTarget.src = darkMode ? BlackArrowDarkmode : GreyArrow}
                                onClick = {goBack} />

                        
                        </TestScreenNavBarBackButton>
                    }
                    { !allowGoBack &&
                        // Div deliberadamente vazio para alinhar as coisas
                        <div></div>
                    }
        
                    <TestScreenNavBarInfo>
                        <TestScreenNavBarNumber>{currentTest + ' de ' + numberTests}</TestScreenNavBarNumber>
                        {/* <ShortcutsHelpButton props={props.shortcutsButtonProps} /> */}
                    </TestScreenNavBarInfo>
            </TestScreenNavBarContainer>
        }
        </>
    )
}



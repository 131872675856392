// Isso aqui é uma desgraça, precisa dividir em dois componentes, um de MCQ e um de Flashcard
// e generalizar os botões da barra


import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import session        from './../controllers/Session'
import '../scss/Main.scss'
import { useIdleTimer } from 'react-idle-timer'
import FlashcardAnswerScreen from './FlashcardAnswerScreen'
import McqAnswerScreen from './McqAnswerScreen'



export default function AnswerScreen() {
    let navigate = useNavigate();
    

    useEffect(() => {
        if (!session || !session.created) {
            console.log("AnswerScreen: session not loaded, going back.")
            navigate('/app')
        }
    }, [session])



    const handleOnIdle = event => {
        console.log('AnswerScreen: user is idle, going back')
        // TODO No futuro, deletar os dados da session?
        navigate('/app')
    }


    // To avoid logging non-sense use times if user let the tab open
    // and go away, we use an idle timer. Detects 90 seconds.
    //  https://github.com/SupremeTechnopriest/react-idle-timer
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: handleOnIdle,
    })
    

    function nextQuestion(feedback) {
        // feedback pode ser ou o levelOfSuccess do card ou o metacognition das questões
        session.logUserAnswer(feedback)
        session.tryToMoveToNextQuestion()
        navigate("/question")
    }


    return (
        <>
            { session.testType === 'Flashcards' &&
                <FlashcardAnswerScreen
                    nextQuestion = {nextQuestion} />
            }
            { session.testType === 'Residencia' &&
                <McqAnswerScreen
                    nextQuestion = {nextQuestion} />
            }
        </>
    )
}
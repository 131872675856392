import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledIconBttnContainer = styled.div`
    height: 4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5em 1em 0.5em 1em;
    margin: 0.5em 0.5em 0.5em 0em;
    border-radius: 1em;



    // TODO Isso tudo está igual a SideBarButton, poderia generalizar, taokey
    ${props => props.theme.darkMode ? `
        background-color: rgb(90, 90, 90);
        color: rgb(200, 200, 200);

        &:hover {
            background-color: rgb(130, 130, 130);
        }

        &.IconBttnClicked {
            background-color: rgb(130, 130, 130);
        }
    
    ` : `
        background-color: white;
        color: black;

        &.IconBttnClicked {
            background-color: rgb(225, 225, 225) !important;
        }
    `}

    &:hover {
        cursor: pointer;
    }

    &.IconBttnClicked {
        cursor: default;
    }

    & > p {
        margin: 0;
        margin-left: 1em;
        text-align: center;
        font-size: 0.9em;
        font-weight: bold;
    }

    @media (max-width: 500px) {
        flex-direction: column-reverse;
        justify-content: center;
        width: 30%;
        height: auto;
        padding: 0.5em 0 0.5em 0;
        justify-content: flex-start;

        & > p {
            margin: 0;
        }

        .IconBttnImage {
            margin-top: 0.5em;
        }
    }
`;

const StyledIconBttnImage = styled.img`
    height: 2.2em;
`;

export default function CustomStudyTopBarBttn(props) {
    const [clickedClass, setClicked] = useState('');

    useEffect(() => {
        setClicked(props.clicked ? 'IconBttnClicked' : '');
    }, [props.clicked]);

    function registerClick() {
        props.setSelected(props.text);
    }

    return (
        <StyledIconBttnContainer className={clickedClass} onClick={registerClick}>
            <StyledIconBttnImage src={props.icon} />
            <p>{props.text}</p>
        </StyledIconBttnContainer>
    );
}
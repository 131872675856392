

import React, { useEffect, useRef, useState } from 'react'
import './StepBttn.css'
import styled from 'styled-components'
import ActivityAction from './ActivityAction'
import { darkModeGreen } from '../tests/FlashcardsStyles'


const Outer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 50%;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`



const Statistics = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    width: 20%;

    p {
        margin: 0 0.5em 0 0;
        font-size: 0.9em;
        font-weight: bold;


        color: ${props => props.theme.darkMode ? darkModeGreen : '#09852c'};
        font-family: 'Lora';
        text-align: end;
    }


    @media (max-width: 500px) {
        font-size: 0.9em;
        width: 30%;
    }
`




export default function ActivityInfo({
    loadedStatistics, type, activity, hasTests, startTestModeSession, startViewModeSession}) {

    const [firstCorrect, setFirst] = useState()
    const [lastCorrect, setLast] = useState()   


    useEffect(() => {
        if (loadedStatistics) { 
            const statistics = activity['statistics']
            
            const lastAnswerCorrectPercentage = statistics?.[type]?.lastAnswerCorrectPercentage ?? '-';

            console.log(activity)

            if (lastAnswerCorrectPercentage !== '-' && lastAnswerCorrectPercentage !== '-%') {
                setLast(lastAnswerCorrectPercentage);
            }
        }
    }, [loadedStatistics])


    return (
        <Outer>
            {/* <Container> */}
                {/* Para, interpretando como uma coluna, ocupar o espaço do ícone */}

                <Statistics>
                    { lastCorrect && <p>{lastCorrect}</p> }
                </Statistics>

                <ActivityAction
                    activity = {activity}
                    testType = {type}
                    notEmpty = {hasTests}
                    startTestModeSession = {startTestModeSession}
                    startViewModeSession = {startViewModeSession}/>

            {/* </Container> */}
        </Outer>
    )
}
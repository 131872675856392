import React from 'react'
import CustomStudy from './CustomStudy'


export default function FlashcardsScreen() {

    return (
        <CustomStudy
            testType = {'Flashcards'} />
    )
}
import React from 'react';

import './GridOfIcons.scss'

export default function GridOfIcons(props) {

    function getContent() {
        return props.data
    }

    function getItem(text, image, title) {
        let container = 'icon-container'

        if (props.invert) 
            container += ' icon-container-invert'

        return (
            <div className = {container}>
                <img className = "icon-image" src = {image} />
                <div className = "container-text">
                    <p className = 'grid-container-title'>{title}</p>
                    {text}
                </div>
            </div>
        )
    }

    function renderContent() {
        const content = getContent()

        const JSX = content.map((item) => getItem(item.text, item.image, item.title))

        return JSX
    }

    function getExtra() {
        return (
            <div className = 'grid-extra'>
                {props.extra}
            </div>
        )
    }


    let style = "grid-container"
    if (props.invert)
        style += ' grid-container-invert'

    return (

        <div className = {style}>

            { renderContent() } 

            { props.extra && getExtra() }
        
        </div>
    )
}

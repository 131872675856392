import React, { useEffect, useState } from 'react'

import CheckboxColumn from './CheckboxColumn'
import RadioButtonColumn from './RadioButtonColumn'

import './OthersCard.css'
import styled from 'styled-components';



const OthersCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200);
    `:`
        color: black;
    `}

    @media (max-width: 500px) {
        padding: 1em;
    }
`

export default function OthersCard(props) {

    const [selectedCheckboxes, setCheckboxes] = useState([])
    const [selectedRadioBttn, setRadioBttns]  = useState([])

    // Opções dos checkboxes -- algumas são só para as 
    // questões de residencia
    let checkbox_options = [
        {
            text: 'Aleatorizar',
            id: 'randomize'
        }
    ]

    if (props.testType === 'Residencia') {
        checkbox_options.push({
            text: 'Incluir anuladas',
            id: 'include_anuladas'
        })

        checkbox_options.push({
            text: 'Somente as comentadas',
            id: 'only_commented'
        })

        checkbox_options.push({
            text: 'Somente as do Extensivo',
            id: 'only_extensivo'
        })
    }


    // Opções dos radio buttons.

    const radio_bttns_options = [
        {
            text: 'Somente revisões', 
            id: 'only_reviews'
        }, 
        { 
            text: 'Somente testes novos',
            id: 'only_news'
        },
    ]


    // Carrega as opções que o usuário selecionou anteriormente
    // (após clicar, mudar de aba, e voltar).
    useEffect(() => {
        const checkboxes = []
        for (let option of checkbox_options) {
            if (props.previously.includes(option.id)) {
                checkboxes.push(option.id)
            }
        }

        const radio = []
        for (let option of radio_bttns_options) {
            if (props.previously.includes(option.id)) {
                radio.push(option.id)
            }
        }

        setCheckboxes(checkboxes)
        setRadioBttns(radio)
    }, [props.previously])


    // Listeners
    function listenerCheckboxes(newSelection) {
        setCheckboxes(newSelection)
        callParent(newSelection, selectedRadioBttn)
    }


    function listenerRadioBttns(selectedBttn) {
        console.log(selectedBttn)

        setRadioBttns(selectedBttn)
        callParent(selectedCheckboxes, selectedBttn)
    }


    function callParent(checkboxes, radioBttn) {
        const options = []
        options.push(...checkboxes.map(e => e.id))
        options.push(...radioBttn)

        console.log('oi')
        console.log(radioBttn)
        console.log(radioBttn.map(e => e.id))
        console.log(options)

        props.signalFilters('others', options)
    }

    
    return (
        <OthersCardContainer>
            <p><b>Filtros permitem que você customize sua lista de testes.</b></p>

            { props.testType === 'Flashcards' &&
                <p>Os flashcards vêm ordenados na sequência lógica (i.e., definição → epidemiologia → clínica → diagnóstico → tratamento). Se quiser aleatorizar, selecione o filtro abaixo.</p>
            }

            { props.testType === 'Residencia' &&
                <p>As questões vêm ordenadas por instituição; ainda <u>incluímos</u> as que ainda não têm comentários, e <u>excluímos</u> as anuladas. Você pode modificar isso usando os filtros abaixo.</p>
            }

            <CheckboxColumn
                data = {checkbox_options}
                listener = {listenerCheckboxes}
                previously = {selectedCheckboxes}/>
            
            <p>O padrão é carregar tanto os testes novos (i.e., que você nunca viu) quanto as revisões pendentes, mas você pode selecionar só um ou o outro. <i>Para fazer revisões que estão agendadas para o futuro, use o modo consulta.</i></p>

            <RadioButtonColumn
                options = {radio_bttns_options}
                listener = {listenerRadioBttns}
                previously = {selectedRadioBttn}/>
        </OthersCardContainer>
    )
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DumbbellIcon from './../assets/dumbbell.png';
import BooksIcon from './../assets/books.png';
import SandboxIcon from './../assets/sandbox.png';

import ConsultationMode from './ConsultationModeCardBttn';

const ConsultationModeCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-conent: flex-start;
    align-items: center;

    padding: 1em 0 0 1em;

    @media (max-width: 500px) {
        padding: 0.5em;
        margin: 0;
    }
`;


export default function ConsultationModeCard(props) {
    const [chosenMode, setChosenMode] = useState('test-mode');

    useEffect(() => {
        if (props.previouslyChosenMode && props.previouslyChosenMode !== '') {
            console.log('PRÉVIO');
            console.log(props.previouslyChosenMode);
            setChosenMode(props.previouslyChosenMode);
        }
    }, [props.previouslyChosenMode]);

    function changeChosenMode(newlySelected) {
        setChosenMode(newlySelected);
        props.signalChosenMode(newlySelected);
    }

    return (
        <ConsultationModeCardContainer>
            <ConsultationMode
                currentlyChosen={chosenMode}
                signalClicked={changeChosenMode}
                id={'consult-mode'}
                icon={BooksIcon}
                title={'Consulta'}
                text={
                    <>
                        <p>
                            Você irá ler o conteúdo dos testes, tendo acesso tanto às perguntas quanto às respostas, mas sem conseguir respondê-los.
                        </p>
                        <p>
                            É um estudo passivo, análogo a fazer uma leitura. Ideal para um primeiro contato com o tema ou para pesquisas.
                        </p>
                    </>
                }
            />

            <ConsultationMode
                currentlyChosen={chosenMode}
                signalClicked={changeChosenMode}
                id={'test-mode'}
                icon={DumbbellIcon}
                title={'Testes'}
                text={
                    <>
                        <p>
                            Você responderá aos testes, afetando as suas estatísticas de acerto/erro e a programação das suas revisões.
                        </p>
                        <p>
                            Você <u>não</u> terá acesso a testes que estão agendados para revisão no futuro. E os que você responder serão agendados de acordo.
                        </p>
                    </>
                }
            />

            <ConsultationMode
                currentlyChosen={chosenMode}
                signalClicked={changeChosenMode}
                id={'playground-mode'}
                icon={SandboxIcon}
                title={'Playground'}
                text={
                    <>
                        <p>
                            Você terá acesso a todos os testes e irá respondê-los. <u>Não</u> afetará suas estatísticas de acerto ou agendas de revisões.
                        </p>
                        <p>
                            É um estudo ativo, ideal quando você quer retomar um tema rapidamente (e.g., antes de uma prova).
                        </p>
                        <p>
                            Ou quando você quer mostrar para um colega (embaixadores, amamos vocês ♥️).
                        </p>
                    </>
                }
            />
        </ConsultationModeCardContainer>
    );
}
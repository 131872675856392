import React, { useEffect, useState } from 'react'
import { createCheckboxesOptions, extractCheckboxesOptions } from '../utils/Utils'
import CheckboxColumn from './CheckboxColumn'


import './YearsCard.css'

export default function YearsCard(props) {
    const [years, setYears] = useState([])
    const [previously, setPreviously] = useState()

    useEffect(() => {
        let checkboxes_options = createCheckboxesOptions(props.data)
        setYears(checkboxes_options)

        setPreviously( createCheckboxesOptions(props.previously))

    }, [props.data, props.previously])


    function listener(selectedCheckboxes) {
        const years = extractCheckboxesOptions(selectedCheckboxes)
        props.signalFilters('years', years)
    }

    return (
        <CheckboxColumn
            data = {years}
            listener = {listener}
            previously = {previously}
            onCheckboxClickAction = {props.onCheckboxClickAction} />
    )

}
// Jun, 2024
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LikeButton from '../components/LikeButton'
import BuryButton from '../components/BuryButton'
import session from './../controllers/Session'
import TestScreenButton from './TestScreenButton'
import PersonalNoteIcon from '../assets/personal_note.png'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../utils/BootstrapUtils'
import ShortcutsDialog from './ShortcutsDialog'
import ShortcutsIcon from '../assets/question.png'
import SupportIcon from '../assets/headphones.png'


const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    margin-top: 3em;
`


const AuxBttnsContainer = styled.div`
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 5px;

    @media (max-width: 500px) {
        width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    gap: 5px
    }
`



export default function AnswerScreenHelperBttns(props) {
    
    const navigate = useNavigate()
    const [showDialog, setShowDialog] = useState(false)
    const [shortcutsHelp, setShortcutsHelp] = useState(<></>)

    useEffect(() => {
        let shortcuts = []
    
        if (session.testType === 'Residencia') {
            shortcuts.push(
                {
                    'keys': '1 e 2',
                    'description': 'Dão o botão da metacogição correspondente. Ou seja, (1) para "não sabia / acertei por acaso" e (2) para "distração / sabia o conceito".'
                },
                {
                    'keys': 'Espaço ou Enter',
                    'description': 'Após informar a metacognição, te levam para a próxima questão.'
                },)
        } else {
            shortcuts.push(
                {
                    'keys': '1/2/3/4',
                    'description' : 'Dá feedback como Errei/Difícil/Normal/Fácil, respectivamente. No modo playground (1) é errei, e o resto é acertei.'
                },
                {
                    'keys': 'Espaço ou Enter',
                    'description' : 'Dá feedback como \"Normal\".'
                },
            )
        }
    
        shortcuts = shortcuts.concat([
            {
                'keys': 'SHIFT + E',
                'description': 'Enterra o teste atual.'
            },
            {
                'keys': 'SHIFT + S',
                'description': 'Salva o teste atual.'
            },
        ])
    
        setShortcutsHelp(shortcuts)
    }, [])



    return (
        <Container>

            <ShortcutsDialog 
                showDialog = {showDialog}
                handleClose = {() => setShowDialog(false)}
                shortcutsButtonProps = {shortcutsHelp} />
            

            <AuxBttnsContainer>
                { props.showAddNote && 
                    <TestScreenButton
                        text = {'Anotação'}
                        action = {props.startPersonalNote}
                        icon = {PersonalNoteIcon} />
                
                }

                <LikeButton 
                    ID = { session.getCurrentTestID() }
                    testType = {session.testType}
                    testMode = {true} /> 


                <BuryButton                             
                    ID = { session.getCurrentTestID() }
                    testType = {session.testType}
                    shouldShowDialog = {session.numberBuried === 0}
                    testMode = {true}
                    callOnBury={() => {
                        if(!session.buriedAnyQuestion) {
                            session.buriedAnyQuestion = true
                        }

                        session.removeCurrentQuestion()
                        navigate('/question')
                    }} />


                { !isMobile() && session.currentIndex <= 2 &&
                    <TestScreenButton
                        text = {'Atalhos'}
                        action = {() => { setShowDialog(true)}}
                        icon = {ShortcutsIcon} />  
                }

                <TestScreenButton
                    text = {'Feedback'}
                    icon = {SupportIcon}
                    action = {() => props.startFeedback()} />
                
            </AuxBttnsContainer>
        </Container>    

    )
    
}

import React from 'react';

import './SectionTitle.scss';

export default function SectionTitle(props) {
    return (
        <div className="section-title-container">		
            <p className = 'ST-info'><i>{props.info}</i></p>
            <p className = 'ST-title'>{props.title}</p>
            <p className = 'ST-subtitle'>{props.subtitle}</p>
        </div>
    )
}
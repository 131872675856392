import { useEffect, useRef, useState } from "react"
import { db } from "../firebase/firebase-setup"
import { useSelector } from "react-redux"
import OslerHorizontalBar from "./OslerHorizontalBar"
import styled from "styled-components"
import { ColumnCSS, RowCSS } from "../components/BasicComponents"
import OslerData, { KEYS } from "../controllers/OslerData"
import UserReviewsInfo from "../controllers/UserReviewsInfo"
import { pathToTagSequence, tagSequenceToPath } from "../utils/Utils"
import StatisticsExplanationCard from "./StatisticsExplanationCard"

import BlackArrow from './../assets/left-arrow-black.png' 
import GreyArrow from './../assets/left-arrow-grey.png'
import BlackArrowDarkmode from './../assets/left-arrow-darkMode.png'
import GrayArrowDarkmode from './../assets/left-arrow-darkMode-hover.png'

import SearchBar from "../custom/SearchBar"

import { debounce } from 'lodash';
import { darkBackgroundPrimaryColor, darkBackgroundQuintenaryColor, darkBackgroundSecondaryColor, darkBackgroundTertiaryColor, textWhiteBlack } from "../tests/FlashcardsStyles"


const Container = styled.div`
    ${ColumnCSS}

    width: 100%;
    align-self: center;

    margin-top: 2em;
    margin-bottom: 2em;

`


const TagpathContainer = styled.div`
    ${ColumnCSS}
    width: 90%;

    margin-top: 1em;
    border-radius: 1em;

    // background-color: #f2f2f2;

    padding: 1em;

    min-height: 200px;

    @media (max-width: 500px) {
        width: 100%;
    }
`


const TopBar = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;

    margin-top: 2em;
    margin-bottom: 1em;


`

const TopBarLeft = styled.div`
    ${RowCSS}
    width: 10%;
`

const TopBarRight = styled.div`
    ${RowCSS}
    justify-content: flex-end;
    width: 60%;

    padding-right: 0.5em;
`


const GoBack = styled.img`
    height: 2em;
    cursor: pointer;
`


const Info = styled.div`
    ${RowCSS}
    width: 100%;

    margin-bottom: 0.5em;
    p {
        margin: 0;
        font-size: 0.8em;
        font-weight: bold;
        color: gray;
    }
`

const Results = styled.div`
    ${ColumnCSS}
    width: 100%;
`

const Tagpath = styled.div`
    ${RowCSS}
    width: 100%;
    margin-bottom: 0.5em;

    border-radius: 0.5em;
    ${textWhiteBlack}

    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundPrimaryColor};
        &:hover {
            background-color: ${darkBackgroundTertiaryColor};
        }        
    `:`
        border: 1px solid #e6e6e6;    
        background-color: #f2f2f2;
        &:hover {
            background-color: #e6e6e6;
        }
    `}
    

    ${props => props.isClickable && `
        cursor: pointer;
    `}


    @media (max-width: 500px) {
        ${ColumnCSS}
    }
`


const Left = styled.div`
    ${ColumnCSS}

    padding-left: 2em;
    align-items: flex-start;
    justify-content: center;

    width: 30%;
    height: 100%;

    p {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }

    @media (max-width: 500px) {
        width: 100%;
        align-items: center;
        font-size: 1.2em;
        padding-left: 0;

        margin-top: 1.2em;
        margin-bottom: 0.6em;
    }
`

const Right = styled.div`
    ${RowCSS}
    justify-content: flex-start;
    width: 70%;


    @media (max-width: 500px) {
        ${ColumnCSS}
        width: 100%;  
    }
`

const Stats = styled.div`
    ${RowCSS}
    
    width: 40%;

    justify-content: space-around;

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Bar = styled.div`
    width: 60%;
    cursor: default;

    @media (max-width: 500px) {
        width: 100%;
        padding: 1em 0 1em 0;   
    }
`

const Statistics = styled.div`
    ${ColumnCSS}
`

const Number = styled.p`
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: bold;
    color: '#09852c';
    font-family: 'Lora';

`

const Sub = styled.p`
    margin: 0;
    padding: 0;
    font-size: 0.75em;
    color: '#09852c';

`


export default function ThemeStatistics() {
    const user = useSelector(state => state.user.data)

    const [data, setData] = useState(false)

    const [currentTagpaths, setCurrentTagpaths] = useState(false)

    const previousTags = useRef(false)

    const [searchedString, setSearchedString] = useState("")
    const hierarchy = UserReviewsInfo.tagHierarchy['Residencia']

    const tagpathsBeforeSearch = useRef()

    const darkMode = useSelector(state => state.theme.darkModeOn)


    useEffect(() => {
        async function load() {
            console.log(`ThemeStatistics: baixando dados...`)
            const doc = await db.doc(`/users/${user.id}/Residencia/statistics/assorted/nEachTypeTest`).get()
            console.log(`/users/${user.id}/Residencia/statistics/assorted/nEachTypeTest`)                
            if (doc.exists) {
                setData(doc.data())
                deepenTagpath()
            } else {
                setData(false)
            }
        }

        if (UserReviewsInfo.loaded) {
            load()
        }
    }, [])


    function percentage(x, total) {
        return Math.round((x / total) * 100)
    }


    function barFromTagpath(tagpath) {
        const dict = data[tagpath]

        const rc = dict['rightConfident'] ?? 0
        const rg = dict['rightGuess'] ?? 0
        const wd = dict['wrongDistraction'] ?? 0
        const wc = dict['wrongConcept'] ?? 0

        const total = rc + rg + wd + wc
        
        const barData = [
            {
                id: 'rc',
                label: 'Acertou com mérito',
                number: rc,
                percentage: percentage(rc, total),
                color: '#4CAF50', // Verde vivo (Green)
                colorHover: '#388E3C' // Verde vivo mais escuro
            },
            {
                id: 'wd',
                label: 'Errou por distração',
                number: wd,
                percentage: percentage(wd, total),
                color: '#FFC107', // Amarelo (Amber)
                colorHover: '#FFA000' // Amarelo mais escuro
            },
            {
                id: 'rg',
                label: 'Acertou por acaso',
                number: rg,
                percentage: percentage(rg, total),
                color: '#EF5350', // Vermelho claro (Red Light)
                colorHover: '#E53935' // Vermelho claro mais escuro
            },
            {
                id: 'wc',
                label: 'Errou por não saber',
                number: wc,
                percentage: percentage(wc, total),
                color: '#D32F2F', // Vermelho escuro (Red Dark)
                colorHover: '#B71C1C' // Vermelho escuro mais escuro
            },
        ]


        return barData
    }



    function changeTagpaths(newTagpaths) {
        // Só para garantir que será ordenado alfabeticamente. E, de nota, permite outras
        // ordenações no futuro. Por exemplo, se quisermos explicitar áreas deficitárias.
        const sortedTagpaths = newTagpaths.sort((a, b) => getLastTag(a) < getLastTag(b) ? -1 : 1)
        setCurrentTagpaths(sortedTagpaths)
    }


    function deepenTagpath(tagpath = undefined) {
        // tagpath é a opção sobre a qual o usuário clicou, e ele quer expandir, mostrando
        // os tagpaths mais profundos da árvore
        if (!allowClick(tagpath)) {
            return
        }

        if (!tagpath) {
            // Se não clicou em nenhum, a única explicação é que estamos inicializando.
            loadTotal()
        }
        else if (tagpath == 'Total') {
            // Se clicou em Total, damos as quatro grandes áreas
            changeTagpaths(['Clínica Médica', 'Clínica Cirúrgica', 'Pediatria', 'Ginecologia e Obstetrícia', 'Preventiva & Social'])
            previousTags.current = []
        }
        else {
            // O usuário clicou em uma opção que, a partir daqui, é uma tagpath válida
            previousTags.current.push(getLastTag(tagpath))
            const tags = pathToTagSequence(tagpath)

            update(tags)
        }
    }


    function getSubtree(tags) {
        let subtree = hierarchy

        for (let tag of tags) {
            subtree = subtree[tag]
        }

        return subtree
    }

    function getLastTag(tagpath) {
        const parts = tagpath.split('/')
        return parts[parts.length - 1]
    }


    function getTagpath(tag) {
        if (tag === 'Total') {
            return 'Total'
        }
        else {
            return tagSequenceToPath([...previousTags.current, tag])
        }
    }


    function goUp() {
        if (currentTagpaths[0] === 'Total') {
            return
        } 
        else if (previousTags.current.length == 0) {
            loadTotal()
        }
        else {
            // Quando estamos mostrando tudo dentro de Clínica Médica, a lastTag é Clínica Médica
            if (previousTags.current.length == 1) {
                changeTagpaths(['Clínica Médica', 'Clínica Cirúrgica', 'Pediatria', 'Ginecologia e Obstetrícia', 'Preventiva & Social'])
                previousTags.current = []
            } else {    
                previousTags.current.pop()

                // igual de deepen() -- generalizar?
                update(previousTags.current)
                
            }
        }
    }


    function update(tags) {
        let subtree = getSubtree(tags)
        const nextTagpathsLastTags = Object.keys(subtree)
        const nextTagpaths = getNexTagpaths(nextTagpathsLastTags)

        changeTagpaths(nextTagpaths)
    }


    function loadTotal() {
        changeTagpaths(['Total'])
        previousTags.current = []
    }


    function getNexTagpaths(nextTagpathsLastTags) {
        return nextTagpathsLastTags.map(tag => {
            return tagSequenceToPath([...previousTags.current, tag])
        })
    }


    function allowGoBack() {
        if (!currentTagpaths || currentTagpaths[0] === 'Total' || searchableString()) {
            return false
        } else {
            return true
        }
    }


    function allowClick(tagpath) {
        if (!tagpath || tagpath === 'Total') {
            return true
        } else {
            const tags    = pathToTagSequence(tagpath)
            const subtree = getSubtree(tags)

            return subtree != undefined
        }
    }


    function searchableString() {
        return searchedString && searchedString !== "" && searchedString.length > 1
    }


    useEffect(() => {
        if (searchableString()) {
            const tagpaths = Object.keys(data).filter(tagpath => {
                const lastTag = getLastTag(tagpath)
                
                return lastTag.toLowerCase().includes(searchedString.toLowerCase())
            })

            tagpathsBeforeSearch.current = currentTagpaths
            changeTagpaths(tagpaths)

        } else {
            if (!tagpathsBeforeSearch.current || tagpathsBeforeSearch.current[0] === 'Total') {
                loadTotal()
            } else {
                update(previousTags.current)
            }


            // deepenTagpath()
        }
    }, [searchedString])


    function searchedThemeExists() {
        // A função de search() cruza o termo pesquisado comsnte com o que o usuário já fez
        // de testes, através de seu nEachTypeTest.
        //
        // Aqui, cruzamos com todas as tagpaths...

        const results = Object.keys(UserReviewsInfo.infoPerPath['Residencia']).filter(tagpath => {
            const lastTag = getLastTag(tagpath)
            return lastTag.toLowerCase().includes(searchedString.toLowerCase())
        })
        

        return results.length > 0
    }




    function getInfo() {
        if (currentTagpaths[0] === 'Total') {
            return 'Todas as questões do app'
        }
        else if (currentTagpaths.includes('Clínica Médica')) {
            return 'Grandes áreas'
        }
        else {
            return currentTagpaths[0].split('/').slice(0, -1).join('/')
        }
    }

    function renderResult() {
        if (currentTagpaths && currentTagpaths.length > 0) {
            return (
                <>

                { !searchableString() &&
                    <Info>
                        <p>{getInfo()}</p>
                    </Info>                
                }
                <Results>
                    {
                        currentTagpaths.map(tagpath => {
                            const tag = getLastTag(tagpath)
            
                            if (data[tagpath]) {
                                const d = barFromTagpath(tagpath)
                                const percentageRight = d[0].percentage
            
                                const total = d[0].number + d[1].number + d[2].number + d[3].number
                                
                                let available;
                                if (tagpath === 'Total') {
                                    available = UserReviewsInfo.info['Residencia'].nAvailableTests
                                } else {
                                    available = UserReviewsInfo.infoPerPath['Residencia'][tagpath]['availableTests']
                                }
                
                                const percentageDone = Math.round((total / available) * 100)
        
                                return (
                                    <Tagpath isClickable = {allowClick(tagpath)} onClick = {() => deepenTagpath(tagpath)} key = {tagpath}>
                                        <Left>
                                            <p>{tag}</p>
                                        </Left>    
                                        <Right>
                                            <Stats>
                                                <Statistics>
                                                    <Number>{total}</Number>
                                                    <Sub>questões feitas</Sub>
                                                </Statistics>

                                                <Statistics>
                                                    <Number>{percentageDone}%</Number>
                                                    <Sub>do banco</Sub>
                                                </Statistics>

                                                <Statistics>
                                                    <Number>{percentageRight}%</Number>
                                                    <Sub>de acerto</Sub>
                                                </Statistics>
                                            </Stats>

                                            <Bar>
                                                 <OslerHorizontalBar data={d} />
                                            </Bar>
                                        </Right>
                                    </Tagpath>   
                                )
                            }
                        }) 
                    }
                </Results>
                </>
            )
        } 
        else {
            if (searchedThemeExists()) {
                return (
                    <p>Oops. Parece que nós temos esse tema na plataforma, mas você ainda não fez nenhuma questão dele.</p>
                )
            
            }
            else {
                return (
                    <>
                    <p>Desculpe, não encontramos nenhum tema correspondente à sua pesquisa, nem entre os testes feitos, e nem em toda a plataforma.</p>
                    <p>Sugestões:</p>
                    <ul>
                      <li>Verifique se há erros de digitação</li>
                      <li>Tente usar termos mais gerais</li>
                      <li>Tente usar sinônimos</li>
                    </ul>
                  </>
                )
            }
        }
    }


    return (
        <>
            {data && currentTagpaths && 
                <Container>

                    <StatisticsExplanationCard
                        emoji = '✅'
                        highlight = 'Performance nas questões'
                        text = 'Sua porcentagem de completude e de acerto para cada um dos temas dos quais já respondeu alguma questão. Se você errou e, depois, acertou, contabilizará como acerto.' />


                    <TagpathContainer>
                        <TopBar>
                            <TopBarLeft>
                                { allowGoBack() && 
                                    <GoBack 
                                        onClick={goUp}
                                        src = {darkMode ? GrayArrowDarkmode : GreyArrow}
                                        onMouseOver = {e => e.currentTarget.src = BlackArrow}
                                        onMouseOut = {(e) => e.currentTarget.src = GreyArrow}
                                    />           
                                }
                            </TopBarLeft>
                            
                            <TopBarRight>
                                <SearchBar
                                    showBorder = {true}
                                    currentString = {searchedString}
                                    setSearch = {setSearchedString}
                                    visible = {true} />
                            </TopBarRight>

                        </TopBar>

                        { renderResult() }
                    </TagpathContainer>
                    
                </Container>
            }
            { !data && 
                <Container>
                    <StatisticsExplanationCard
                        emoji = '✅'
                        highlight = 'Performance nas questões'
                        text = 'Aqui, aparecerá sua % completude e de acerto nos temas, mas parece que você ainda não fez nenhuma.' />
                </Container>
            }
        </>
    )
}
// Jun, 2024
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import AnswerScreenHelperBttns from './AnswerScreenHelperBttns'
import FeedbackArea from './FeedbackArea'
import PersonalNoteJSX from './PersonalNoteJSX'
import session from './../controllers/Session'
import TestScreen from './TestScreen'
import { TestArea } from './FlashcardsStyles';
import McqFeedbackButtons from './McqFeedbackButtons'
import MCQAnswerJSX from './MCQAnswerJSX'
import OslerData, { KEYS } from '../controllers/OslerData'


import StickyIcon from './../assets/sticky-note.png'
import iPadIcon from './../assets/personal_note.png'
import { useDispatch, useSelector } from 'react-redux'
import { setShowNotebook } from '../redux/notebookSlice'
import { tagSequenceToPath } from '../utils/Utils'
import NotebookController from '../controllers/NotebookController'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHover } from '@remirror/react'



const ProceedBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 1em;
    padding: 2em;

    @media (max-width: 500px) {
        padding: 0 0 1em 0;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    text-transform: uppercase;

    background-color: ${props => props.bg};

    color: white;

    max-width: 30%;


    padding: 1em;
    border-radius: 0.5em;
    font-weight: bold;
    text-align: center;

    &:hover {
        background-color: ${props => props.bgHover};
        cursor: pointer;
    }

    img {
        height: 1.8em;
        margin-right: 0.5em;
    }

    p {
        font-size: 1em;
        margin: 0;
    }

    @media (max-width: 500px) {
        img {
            height: 1.3em;
            margin-right: 0.5em;
        }
        p {
            font-size: 0.8em;
        }
    }

    
`


function Button({icon, text, onClick, bg = 'rgba(90, 90, 90, 0.8)', bgHover = 'rgba(90, 90, 90, 1.0)'}) {
    return (
        <ButtonContainer 
            onClick = {onClick}
            bg = {bg}
            bgHover = {bgHover} >
                <img src = {icon} />
                <p>{text}</p>
        </ButtonContainer>
    )

}


export default function McqAnswerScreen({nextQuestion}) {
    const [shortcutsEnabled, setShortcutsEnabled] = useState(true)

    const [showFeedbackArea, setShowFeedbackArea] = useState(false)
    const [userMetacognition, setUserMetacogniton] = useState(false)
    const [showPersonalNote, setShowPersonalNote] = useState(false)


    const dispatch = useDispatch()
    const showNotebook = useSelector(state => state.notebook.showNotebook)

    // // Atalhos para funçẽos da session
    // // Please be aware that there are some hotkeys that we cannot override, 
    // // because they would interfere with a safe browsing experience for the user.
    // // These depend on the browser.
    // // (https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage)    
    // useHotkeys('space', () => nextQuestion(2), {enabled: shortcutsEnabled,}) // normal
    // useHotkeys('enter', () => nextQuestion(2), {enabled: shortcutsEnabled}) // normal
    // useHotkeys('1', () => nextQuestion(0), {enabled: shortcutsEnabled}) // errei
    // useHotkeys('2', () => nextQuestion(1), {enabled: shortcutsEnabled}) // difícil
    // useHotkeys('3', () => nextQuestion(2), {enabled: shortcutsEnabled}) // normal
    // useHotkeys('4', () => nextQuestion(3), {enabled: shortcutsEnabled}) // fácil




    const test = useRef()

    useEffect(() => {
        test.current = session.getCurrentTest()
        setShowPersonalNote( test.current.personalNote )

        // const testID = session.getCurrentTest().testID
        // console.log(testID)
        // const adjstedID = testID.replace("residencia_", "")
        // const tagpath_tmp = OslerData.data[KEYS.RESIDENCIA][KEYS.TAGPATH_PER_ID][adjstedID]
        // setTestTagpath(tagpath_tmp)
    }, [])
    

    function startFeedback() {
        setShowFeedbackArea(true)
        setShortcutsEnabled(false)
    }


    function stopFeedback() {   
        setShortcutsEnabled(true)
        setShowFeedbackArea(false)
    }


    function startPersonalNote() {
        setShowPersonalNote(true)
        setShortcutsEnabled(false)
    }


    function logFeedback(metacognition) {
        console.log(metacognition)
        setUserMetacogniton(metacognition)
    }

    function proceed() {
        dispatch(setShowNotebook(false))
        nextQuestion(userMetacognition)
    }


    function proceedThroughShortcut() {
        if (!showFeedbackArea && (userMetacognition || session.mode === 'playground-mode')) {
            proceed()
        }
    }
    
    useHotkeys('space', proceedThroughShortcut)
    useHotkeys('enter', proceedThroughShortcut)

    if (!test.current) return
    return (
        // <Container>
            
            <TestScreen
                allowGoBack = {false}
                testJSX = {(
                        <TestArea>

                            <MCQAnswerJSX   
                                test = { test.current }
                                consultMode = {false} />
                            

                            { !showFeedbackArea && !showPersonalNote && 
                                <AnswerScreenHelperBttns
                                    startFeedback = {startFeedback}
                                    showAddNote = {false} />
                            }


                            { showFeedbackArea && 
                                <FeedbackArea
                                    test = {test.current}
                                    testType = {'Residencia'}
                                    close = {stopFeedback} />
                            }

                                    
                            { !showFeedbackArea && (!userMetacognition && session.mode !== 'playground-mode') &&
                                <McqFeedbackButtons
                                    gaveRightAnswer = {test.current.answer == session.mcqChosenAnswer }
                                    logFeedback = {logFeedback} />
                            }


                            { showPersonalNote && userMetacognition && !showFeedbackArea &&
                                <PersonalNoteJSX
                                    close = {() => setShowPersonalNote(false) }
                                    test = { test.current } />                                    
                            }

                            { !showFeedbackArea && (userMetacognition || session.mode === 'playground-mode') &&
                                <ProceedBttns>
                                    { !showNotebook &&
                                        <Button 
                                            onClick = {() => {
                                                const tagpath = tagSequenceToPath(test.current.tags)
                                                NotebookController.saveTagpathRecommendation(tagpath)
                                                dispatch(setShowNotebook(true))
                                            }}
                                            icon = {iPadIcon}
                                            text = "Caderno" />
                                                                        
                                    }

                                    { !showPersonalNote &&
                                        <Button 
                                            onClick = {() => startPersonalNote(true)}
                                            icon = {StickyIcon}
                                            text = "Anotação" />
                                    }
                                        
                                    <Button
                                        onClick = {() => proceed()}
                                        bg = 'rgba(38, 120, 196, 0.8)'
                                        bgHover = 'rgba(38, 120, 196, 1.0)'
                                        text = 'PRÓXIMA QUESTÃO' />

                                </ProceedBttns>
                            }

                            {/* { !showFeedbackArea && !editingPersonalNote && userMetacognition && hasPersonalNote && 
                                <ProceedBttns>
                                    <Continue onClick = {() => proceed()}>
                                        Próxima questão
                                    </Continue>
                                </ProceedBttns>
                            } */}

                        </TestArea>

                )} />





        
            // </Container>
        )
}
// Jun, 2024
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AnswerScreenHelperBttns from './AnswerScreenHelperBttns'
import FeedbackArea from './FeedbackArea'
import PersonalNoteJSX from './PersonalNoteJSX'
import { useHotkeys } from 'react-hotkeys-hook'
import FlashcardAnswerJSX from './FlashcardAnswerJSX'
import session from './../controllers/Session'
import TestScreen from './TestScreen'
import { TestArea } from './FlashcardsStyles';
import ButtonSRS from './ButtonSRS'
import { ColumnCSS } from '../components/BasicComponents'

const FeedbackBttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-top: 2em;
    width: 100%;

    @media (max-width: 500px) {
        margin-bottom: 1em;
    }
`


const colorMap = {
    fail: { backgroundColor: '#8E0011', hoverColor: 'rgba(142, 0, 17, 0.9)' },
    normal: { backgroundColor: 'rgb(35, 146, 7)', hoverColor: 'rgba(35, 146, 7, 0.9)' },
  };



const PlaygroundBttn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
    font-weight: 600;

    height: 4em;

    border-radius: 0.5em;

    margin-right: 0.1vw;
    margin-left: 0.1vw;

    box-shadow:inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);

    cursor: pointer;
    width: 48%;

    background-color: ${props => colorMap[props.colorClass].backgroundColor};

    &:hover {
        background-color: ${props => colorMap[props.colorClass].hoverColor};
    }
`


const PlaygroundBttnLabel = styled.p`
    margin: 0;
    padding: 0;
    text-align: 0;
`

const BttsContainer = styled.div`
    width: 100%;
    ${ColumnCSS}
`


export default function FlashcardAnswerScreen({nextQuestion}) {


    const [shortcutsEnabled, setShortcutsEnabled] = useState(true)
    const [showFeedbackArea, setShowFeedbackArea] = useState(false)
    const [hasPersonalNote, setHasPersonalNote] = useState(false)
    const [showingPersonalNote, setShowingPersonalNote] = useState(false)

    const [timeToNextReview, setTimeToNextReview] = useState(false)

    // Atalhos para funçẽos da session
    // Please be aware that there are some hotkeys that we cannot override, 
    // because they would interfere with a safe browsing experience for the user.
    // These depend on the browser.
    // (https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage)    
    useHotkeys('space', () => nextQuestion(2), {enabled: shortcutsEnabled,}) // normal
    useHotkeys('enter', () => nextQuestion(2), {enabled: shortcutsEnabled}) // normal
    useHotkeys('1', () => nextQuestion(0), {enabled: shortcutsEnabled}) // errei
    useHotkeys('2', () => nextQuestion(1), {enabled: shortcutsEnabled}) // difícil
    useHotkeys('3', () => nextQuestion(2), {enabled: shortcutsEnabled}) // normal
    useHotkeys('4', () => nextQuestion(3), {enabled: shortcutsEnabled}) // fácil


    useEffect(() => {
        setTimeToNextReview(session.getTimeUntilNextReview() )

        const note = session.getCurrentTest().personalNote

        if (note) {
            setHasPersonalNote(true)
            setShowingPersonalNote(true)
        }

    }, [])
    

    function startFeedback() {
        setShowFeedbackArea(true)
        setShortcutsEnabled(false)
    }


    function stopFeedback() {   
        setShortcutsEnabled(true)
        setShowFeedbackArea(false)
    }


    function startPersonalNote() {
        setShowingPersonalNote(true)
        setShortcutsEnabled(false)
    }




    return (
            <TestScreen
                allowGoBack = {false}
                testJSX = {(
                    <TestArea>
                        <FlashcardAnswerJSX   
                            test = { session.getCurrentTest() } />
                        

                        <BttsContainer>
                            { !showFeedbackArea && 
                                <AnswerScreenHelperBttns
                                    startFeedback = {startFeedback}
                                    startPersonalNote = {startPersonalNote}
                                    showAddNote = {!showingPersonalNote} />
                            }


                            { showFeedbackArea && 
                                <FeedbackArea
                                    test = {session.getCurrentTest()}
                                    testType = {'Flashcards'}
                                    close = {stopFeedback} />
                            }

                            
                            { (showingPersonalNote) && !showFeedbackArea &&
                                <PersonalNoteJSX
                                    test = { session.getCurrentTest() }
                                    close = {() => setShowingPersonalNote(false)} />                                    
                            }
                                    
                            { !showFeedbackArea &&

                                <FeedbackBttnsContainer>
                                    { session.mode === 'test-mode' &&
                                    <>
                                        <ButtonSRS 
                                            submitAnswer={nextQuestion} 
                                            colorClass="fail" 
                                            levelOfSuccess={0} 
                                            text="Errei"
                                            time={timeToNextReview[0]} 
                                            />
            
                                        <ButtonSRS 
                                            submitAnswer={nextQuestion} 
                                            colorClass="hard" 
                                            levelOfSuccess={1} 
                                            text="Difícil"
                                            time={timeToNextReview[1]} 
                                            />
            
                                        <ButtonSRS 
                                            submitAnswer={nextQuestion} 
                                            colorClass="normal" 
                                            levelOfSuccess={2} 
                                            text="Normal" 
                                            time={timeToNextReview[2]} 
                                            />
            
                                        <ButtonSRS 
                                            submitAnswer={nextQuestion} 
                                            colorClass="easy" 
                                            levelOfSuccess={3} 
                                            text="Fácil"
                                            time={timeToNextReview[3]} 
                                            />
                                    </>
                                    }

                                    { session.mode === 'playground-mode' &&
                                    <>
                                        <PlaygroundBttn colorClass = {'fail'} onClick = {() => nextQuestion(0)}>
                                            <PlaygroundBttnLabel>Errei</PlaygroundBttnLabel>
                                        </PlaygroundBttn>

                                        <PlaygroundBttn colorClass = {'normal'} onClick = {() => nextQuestion(3)}>
                                            <PlaygroundBttnLabel>Acertei</PlaygroundBttnLabel>
                                        </PlaygroundBttn>
                                    
                                    </>
                                    }

                                </FeedbackBttnsContainer>
                            }

                        </BttsContainer>
                    </TestArea>
                )} />
        
        )
}
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from './../redux/loadingSlice'
import OslerButton from '../components/OslerButton';
import GeneralDialog from '../tests/GeneralDialog';
import AmbassadorDashboardProfile from './AmbassadorDashboardProfile.js'

import styled from 'styled-components';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(231, 231, 231);
    padding: 3em;

    width: 100%;
    flex-grow: 1;
`

const OnboardingContainer = styled.div`

    align-self: center;
    width: 45%;
`

const OnboardingBttns = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`


export default function AmbassadorOnboarding( props ) {
    const dispatch = useDispatch()

    const [showOnboardDialog, setShowOnboardDialog] = useState(false)
    const [showRemoveDialog, setShowRemoveDialog] = useState(false)


    return (
        <Container>

            <GeneralDialog
                open={showOnboardDialog}
                title="🫂 Aceitar o embaixador?"
                onClose={() => setShowOnboardDialog(false)}
                actions={[
                    { label: 'Voltar', onClick: () => setShowOnboardDialog(false), style: 'neutral'},
                    { label: 'Aceitar', onClick: props.approveAmbassador, style: 'green' },
                ]}
            >
                <p>Você irá <u>aceitar</u> o embaixador no programa. Prosseguir?</p>
            </GeneralDialog>


            <GeneralDialog
                open={showRemoveDialog}
                title="⚠️ Remover o embaixador?"
                onClose={() => setShowRemoveDialog(false)}
                actions={[
                    { label: 'Voltar', onClick: () => setShowRemoveDialog(false), style: 'neutral'},
                    { label: 'Remover', onClick: props.cancelOnboarding, style: 'destructive' },
                ]}
            >
                <p>Você irá <u>remover</u> a candidatura do embaixador. Isso é irreversível.</p>
            </GeneralDialog>

            <OslerButton
                style = {{
                    width: '10em',
                    marginTop: '2em',
                    marginBottom: '4em',
                    alignSelf: 'flex-start'}}
                bgColor = 'dark-grey'
                text = 'Fechar'
                onClick = {props.unselectAmbassador} />

            <AmbassadorDashboardProfile
                ambassador = {props.ambassador} />

            <OnboardingContainer>
                <p style = {{color: '#c73126', fontSize: '1.5', marginTop: '2em'}}><b>Esse embaixador ainda <u>não</u> tem onboarding registrado.</b></p>

                <p>Se ele já realizou a reunião e você deseja habilitá-lo no programa, clique no botão de aprovação.</p>
                
                <p>Do contrário, se houver um erro, ou ele não qualificou durante a reunião de onboarding, delete a inscrição com o botão vermelho. Se você é daltônica, busque ajuda do suporte.</p>


                <OnboardingBttns>
                    <OslerButton
                        style = {{
                            width: '10em',
                            marginTop: '2em',
                            marginBottom: '4em',
                            marginLeft: '8em'}}
                        bgColor = 'red'
                        text = 'Remover embaixador'
                        onClick = {() => setShowRemoveDialog(true)} />


                    <OslerButton
                        style = {{
                            width: '10em',
                            marginTop: '2em',
                            marginBottom: '4em',
                            marginLeft: '8em'}}
                        bgColor = 'green'
                        text = 'Aprovar'
                        onClick = {() => setShowOnboardDialog(true)} />
                </OnboardingBttns>

            </OnboardingContainer>

        </Container>
    )
}
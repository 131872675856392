import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';
import { RowCSS } from '../components/BasicComponents';


const CheckboxColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`


const CheckboxColumnRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(33.33% - 1em);
    margin-right: 1em;
    margin-bottom: 0.5em;
    box-sizing: border-box;
`


const CheckboxColumnRowClickable = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0 0 1em 0;
`

const CheckboxColumnTitle = styled.p`
    font-weight: bold;
    font-size: 0.9em;
    color: ${props => props.theme.darkMode ? 'rgb(200, 200, 200)' : 'black'};
    margin: 0 0 0 0.5em;
    padding: 0;
`


export default function CheckboxColumn(props) {
  const [optionsJSX, setOptions] = useState(<></>);
  const clickedCheckboxesID = useRef([]);

  useEffect(() => {
    if (props.previously) {
      clickedCheckboxesID.current = props.previously.map(checkboxOption => checkboxOption.id);
    }

    renderCheckboxes();
  }, [props.data, props.previously]);

  useEffect(() => {
    renderCheckboxes();
  }, [props.searchedString]);

  function shouldBeVisible(optionText) {
    if (!props.searchedString || props.searchedString === '') {
      return true;
    } else if (optionText.toLowerCase().includes(props.searchedString.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  function renderCheckboxes() {
    const JSX = props.data.map(option => {
      let visible = shouldBeVisible(option.text);

      if (visible) {
        return (
          <CheckboxColumnRow key={option.id}>
            <CheckboxColumnRowClickable
              onClick={() => {
                checkboxClicked(option.id);

                if (props.onCheckboxClickAction) {
                  props.onCheckboxClickAction();
                }
              }}
            >
              <Checkbox
                checked={clickedCheckboxesID.current.includes(option.id)}
                mildlyChecked={false}
                action={props.onCheckboxClickAction}
              />
              <CheckboxColumnTitle>{option.text}</CheckboxColumnTitle>
            </CheckboxColumnRowClickable>
          </CheckboxColumnRow>
        );
      } else {
        return null;
      }
    });

    setOptions(JSX);
  }

  function checkboxClicked(checkboxID) {
    const index = clickedCheckboxesID.current.indexOf(checkboxID);

    if (index !== -1) {
      clickedCheckboxesID.current.splice(index, 1);
    } else {
      clickedCheckboxesID.current.push(checkboxID);
    }

    renderCheckboxes();

    let selected = props.data.filter(checkboxOption => {
      return clickedCheckboxesID.current.includes(checkboxOption.id)
    });

    props.listener(selected);
  }

  return (
    <CheckboxColumnContainer>
        {optionsJSX}
    </CheckboxColumnContainer>
  );
}
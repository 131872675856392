import React, { useEffect, useState } from 'react'
import MSCard from './MSCard'

import Statue from './../assets/statue.png'
import Strong from './../assets/strong.png'
import DumbbellIcon from './../assets/dumbbell.png'


import './ButtonsCard.css'
import sessionBuilder from '../controllers/SessionBuilder'
import UserReviewsInfo from '../controllers/UserReviewsInfo'


export default function PendingReviewsCard(props) {

    // const [nFlashcards, setN] = useState(props.reviewInfo.Flashcards.nPendingReviews)
    // const [nResidencia, setR] = useState(props.reviewInfo.Residencia.nPendingReviews)


    const [nFlashcards, setN] = useState( UserReviewsInfo.info['Flashcards'].pendingReviews.length)
    const [nResidencia, setR] = useState( UserReviewsInfo.info['Residencia'].pendingReviews.length)

    const [text, setText] = useState("")

    const f1 = () => startReviews('Flashcards')
    const f2 = () => startReviews('Residencia')


    useEffect(() => {

        if (nFlashcards > 0 && nResidencia > 0) {
            setText(<>
                <p><b>Revise todos os dias e não deixe acumular. Exige disciplina, mas você se surpreenderá com o quanto irá decorar.</b></p>

                <p>Você tem revisões pendentes de <i>flashcards</i> e questões de residência.</p>
            </>)
        }
        else if (nFlashcards > 0) {
            setText(<>
                <p><b>Você já revisou as questões — bora atacar os <i>flashcards</i>?</b></p>
                
                <p>Seguindo a programação, você garante que terá toda a materia, em seus ínfimos detalhes, na ponta da língua.</p>
            </>)
        }
        else if (nResidencia > 0) {
            setText(<>
                <p><b>Você já revisou os flashcards: hora de usar a teoria para acertar as questões!</b></p>
                
                <p>Acerte as questões que errou antes. Reveja o conteúdo para continuar acertando. E, agora que sabe mais, tenha novos <i>insights</i> durante a resolução.</p>
            </>)
        }
        else {
            setText(<>
                <p><b>Todas as suas revisões, tanto de questões quanto de <i>flashcards</i>, estão em dia!</b>🚀 🚀 🚀</p>

                <p>É essa disciplina que garante a memorização necessária àqueles 85% de acertos na prova.</p>
            </>)
        }
    }, [nFlashcards, nResidencia])
    
    
    function startReviews(testType) {
        props.loadTests(testType, 'only_reviews', false)
    }

    return (
        <MSCard 
            title = {'Revisões'}
            icon  = {DumbbellIcon} 
            
            flashcards = {nFlashcards}
            flashcardsAction = {f1}
            flashcardsTxt    = {'Flashcards'}

            residencia = {nResidencia}
            residenciaAction = {f2}
            residenciaTxt    = {'Questões'}

            text = {text}
            noBttnImg = {Statue}
            oneBttnImg = {Strong}
            
            />
    )
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { darkBackgroundQuarternaryColor, darkBackgroundQuintenaryColor, darkBackgroundSecondaryColor, darkBackgroundSextenaryColor, darkBackgroundTertiaryColor } from '../tests/FlashcardsStyles';

const ScheduleButtonContainer = styled.div`
    height: 3em;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    margin: 0 1em 0 1em;
    padding: 1em 2em 1em 2em;

    border-radius: 1em;



    background-color: ${props => props.theme.darkMode ? darkBackgroundQuarternaryColor : 'white'};

    &:hover {
        background-color: ${props => props.theme.darkMode ? darkBackgroundQuintenaryColor : 'lightgray'};
        cursor: pointer;
    }

    ${props => props.clicked && `
        background-color: ${props.theme.darkMode ? darkBackgroundSextenaryColor : 'rgb(225, 225, 225)'} !important;
        cursor: default;        
    `}

    p {
        margin: 0 0 0 1em;
        padding: 0;
        font-size: 1em;
        font-weight: bold;
    }

    img { 
        height: 2em;
    }

    @media (max-width: 900px) {
        padding: 1em;

        p {
            margin-left: 0.5em;
            font-size: 0.8em;
        }

        img {
            height: 1.5em;
        }
    }

    @media (max-width: 500px) {
        padding: 0.25em 0.75em 0.25em 0.75em;
        margin: 0 0.5em 0 0.5em;

        p {
            margin-left: 0.5em;
            font-size: 0.8em;
        }

        img {
            height: 1.5em;
        }
    }
`


const ScheduleButtonImage = styled.img`
  height: 2em;
`


export default function ScheduleButton(props) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(props.clicked);
  }, [props.clicked]);

  function registerClick() {
    props.buttonAction();
  }

  return (
    <ScheduleButtonContainer clicked = {clicked} onClick={registerClick} >
      <img className="ScheduleBttnImage" src={props.icon} />
      {props.text !== '' && <p>{props.text}</p>}
    </ScheduleButtonContainer>
  );
}
import React, { useEffect, useState, memo } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import NoteIcon from './../assets/personal_note.png'
import Logo from './../assets/Logo.png'
import LogoDark from './../assets/LogoDark.png'
import Signature from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import Setup from './../assets/settings.png'
import Stetho from './../assets/stethoscope.png'
import Home from './../assets/home.png'
import Graph from './../assets/analytics.png'
import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import KeyIcon from './../assets/extensivo.png'
import SideBarButton from './MobileBarButton'
import DarkModeToggle from './DarkModeToggle'
import Moon from './../assets/lua.png'
import Sun from './../assets/sol.png'
import { setShowSidebar } from '../redux/notebookSlice'
import Bedside from './../assets/bedside.png'
import Trilha from './../assets/trilha.png'
import { textWhiteBlack } from '../tests/FlashcardsStyles'


// TODO PRecisava colocar as widths como algo calculado dinamicamente
// senão eu altero uma coisa e fica um caos mudar todads as outras
// dependentes na mão.


const expanded = 170
const collapsed = 60
const padding = 5


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: ${props => props.expanded ? `${expanded}px` : `${collapsed}px`};
    max-width: ${props => props.expanded ? `${expanded}px` : `${collapsed}px`};
    
    transition: width 0.4s ease; /* Aumentando a duração da transição */

    padding-top: 1em;
    
    padding-left:  ${padding}px;
    padding-right: ${padding}px;

    // cuidado para não ficar acima do overlay
    z-index: 500;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
    ` : `
        background-color: white;
    `}
`


const animation = css`
    opacity: ${props => props.expanded ? 1 : 0};
    animation: ${props => {
        return (props.animate
        ? props.expanded 
            ? css`${fadeIn} 1.0s ease`
            : css`${fadeOut} 0.2s ease`
        : 'none'
    )}};
`



const LogoIcon = styled.img`

    
    width: 40px;
    filter: none !important;
`


const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`


const SignatureIcon = styled.img`
    margin-left: 1em;

    // À esquerda do logo, 5px + 5px = 10px de padding
    // Logo tem 40px
    // Expandido tem 170px

    width: 90px;
    filter: none !important;

    ${animation}
`

const ToggleContainer = styled.div`
    // Mesma discussão. Quando expandido,
    // 170px = 5px + 5px + (40px da imagem) + 5px + ??? + 5px
    // ??? = 110px
    //
    // O toggle me si tem 60px, aprox. Logo, para centralizar:
    margin-left: 25px;

    ${animation}
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;


    // largura total = padding bilateral externo + padding da imagem + largura da imagem
    //
    // Quando colapsado,
    //  60px = 10px + ?? + 40px
    //
    // Logo, o padding da imagem é 10px

    padding: 0.5em 0.5em 0.5em 5px;

    border-radius: 0.5em;
    margin-bottom: 0.75em;
    ${props => props.theme.darkMode ? `
        color: white;
    ` : `
       color: black;
        // background-color: yellow;
    `}

    

    ${props => !props.action ? `
        background-color: transparent;
        ` : props.selected ? `
                cursor: default;
                background-color: ${props.theme.darkMode ? 'rgb(139, 139, 139)' : 'rgb(213, 213, 213)'};
                color: white;
        ` : `
                cursor: pointer;
                background-color: transparent;
                color: black;

                &:hover {
                    background-color: #9a9797;
                }
    `}

    
`

const Icon = styled.img`
    // width: 2.5vw;
    width: 40px;
`

const Text = styled.p`
    font-weight: bold;
    font-size: 0.8em;
    margin: 0 0 0 15px;

    opacity: ${props => props.expanded ? 1 : 0};

    ${textWhiteBlack}

    @keyframes IN {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes OUT {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    ${animation}
`



function Button({icon, text, expanded, selected, action, animate}) {

    return (
        <Item selected = {selected} onClick = {action} expanded = {expanded} action = {action} >
            <Icon src = {icon} />
            <Text expanded = {expanded} animate = {animate}>{text}</Text>
        </Item>
    )
}


function SideBar() {
    let navigate = useNavigate()
    const path = useLocation().pathname
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkModeOn)

    const dispatch = useDispatch()

    const expanded = useSelector(state => state.notebook.showSidebar)
    const [animate, setAnimate] = useState(!expanded)

    const bttns = [
        { icon: Stetho, text: 'Inicial', where: '/app' },
        { icon: KeyIcon, text: 'Cronogramas', where: '/track' },
        { icon: Flashcards, text: 'Flashcards', where: '/flashcards' },
        { icon: Residencia, text: 'Residência', where: '/residencia' },
        { icon: Trilha, text: 'Correção de Erros', where: '/mistakes' },
        { icon: NoteIcon, text: 'Caderno', where: '/notebook' },
        { icon: Graph, text: 'Estatísticas', where: '/statistics' },
        { icon: Setup, text: 'Configurações', where: '/user' },
        { icon: Home, text: 'Website', where: '/home' },
        // { icon: Bedside, text: 'Bedside', where: '/bedside' },
    ]


    return (
        <Container
            expanded={expanded}s
            onMouseEnter={() => dispatch(setShowSidebar(true))}
            onMouseLeave={() => {
                dispatch(setShowSidebar(false))
                setAnimate(true)
            }} >

            <Item action = {false}>
                <LogoIcon src={darkMode ? LogoDark : Logo} expanded={expanded} />
                <SignatureIcon src={darkMode ? SignatureDark : Signature} expanded={expanded} animate = {animate} />
            </Item>


            {user && user.subscription && user.subscription.isActive && 
                <Item>
                    <Icon src = {darkMode ? Moon : Sun} />
                    <ToggleContainer expanded={expanded} animate = {animate}>
                        <DarkModeToggle />
                    </ToggleContainer>                      
                </Item>
            }


            {bttns.map(bttn => (
                <Button 
                    icon={bttn.icon}
                    text={bttn.text}
                    expanded={expanded}
                    selected={bttn.where === path}
                    action={() => navigate(bttn.where)}
                    animate = {animate} />
            ))}
        </Container>
    )
}



const MemoSideBar = React.memo(SideBar)

export default MemoSideBar

import React, { useState } from 'react'
import { getUserProfile } from '../firebase/firebaseUtils'
import { toastMsg } from '../utils/Utils'
import { auth } from './../firebase/firebase-setup'

import { useDispatch } from 'react-redux' 
import { setIsLoading } from './../redux/loadingSlice'
import { saveUser } from './../redux/userSlice'
import { emailInput, txtInput } from './TextInput'

export default function SignIn(props) {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("")
    const [passwd, setPasswd] = useState("")

    const [offerPasswdReset, setOfferPasswdReset] = useState(false)
    const [sentResetEmail, setSentResetEmail] = useState(false)
    const [errorOnReset, setErrorOnReset] = useState(false)
    
    async function tryLogin(e) {
        // To prevent refresh, because button has type = 'submit'.
        // https://stackoverflow.com/questions/38860900/firebase-project-results-in-auth-network-request-failed-error-on-login
        e.preventDefault()

        console.log("Will try to login with " + email + " / " + passwd)
        try {
            dispatch(setIsLoading(true))

            // Sinaliza que devemos manter o usuário logado mesmo
            // que ele feche a janela.
            // await auth.setPersistence('local')

            // De fato tenta logar.
            const credential = await auth.signInWithEmailAndPassword(email.trim(), passwd.trim())

            const userData = await getUserProfile(credential.user.uid, credential.user.emailVerified)

            dispatch(setIsLoading(false))

            if (userData !== -1) {
                console.log("tryLogin(): success.")
                // dispatch( saveUser(userData) )
            }
            else {
                console.log("tryLogin(): signed in, but failed fetching profile.")
                toastMsg("☠️ Sua conta existe, mas não achamos os dados. Fale com o Suporte!")
            }
        }

        catch (error) {
            dispatch(setIsLoading(false))
            console.log(error)
            switch (error.code) {
                case 'auth/user-disabled' :
                    toastMsg("☠️ Conta desativada. Fale com o Suporte!")
                    break;

                case 'auth/user-not-found' :
                    toastMsg("👁️ Usuário não foi encontrado.")
                    break

                case 'auth/wrong-password' :
                    toastMsg("❌ Senha errada!")
                    break

                case 'auth/invalid-email' :
                    toastMsg("❌ Email mal formatado!")
                    break

                case 'auth/network-request-failed':
                    toastMsg("🐌 Verifique sua conexão com a internet.")
                    break

                default:
                    toastMsg("🙃 Erro inesperado. Considere falar com nosso Suporte. ")
            }

        }
    }


    async function sendPasswordResetEmail() {
        try {
            await auth.sendPasswordResetEmail(email)
            setSentResetEmail(true)
            setErrorOnReset(false)

        } catch (error) {

            console.log("ERROR")
            if (error.code === 'auth/user-not-found') {
                toastMsg("👁️ Este usuário não foi encontrado.")
            }
            
            setErrorOnReset(true)
        }
         
    }


    function signInJSX() {
        return ( 

            <form className = 'signUpInputContainer' onSubmit = {tryLogin} autoComplete = 'on'>

                { emailInput('Email', email, setEmail)       }
                { txtInput('Senha', passwd, setPasswd, 'password')  }

                <a 
                    className='forgotPasswd'
                    href="#" 
                    onClick={() => setOfferPasswdReset(true)} > 
                        Esqueceu sua senha?
                </a>

                <button 
                    onClick={tryLogin} 
                    className="Button bttn-margin-top">
                        Entrar
                </button>


                <span className='signUp'>
                    <strong>
                    Não tem uma conta? <a href="#" onClick={() => props.changeMethod()}>
                        Cadastre-se
                    </a>.
                    </strong>
                </span>
                    
            </form>
                
        
        )
    }


    function changePasswordJSX() {

        const goBackBttn = (
            <button 
                onClick={() => { 
                    setSentResetEmail(false)
                    setOfferPasswdReset(false)
                }} 
                className="Button bttn-margin">
                    Voltar
            </button>
        )


        return (
            <div className = 'changePasswordContainer'>
                <p><strong>Esqueceu sua senha?</strong> Insira o email do seu cadastro, e te enviaremos por lá um link para você mudá-la.</p>

                <p><strong>Isso não resolve seu problema?</strong> Entre em contato conosco via Instagram (@oslermedicina) ou email (suporte@osler-ensino.com.</p>

                { sentResetEmail && !errorOnReset &&
                    <div className = "resetedPasswdContainer">
                        <p>Email enviado para <strong>{email}!</strong> Aguarde alguns instantes e verifique sua caixa de entrada.</p>
                        
                        {goBackBttn}

                    </div>
                }    

                { !sentResetEmail && 
                    <>
                    <input 
                        className = 'changePasswordInput'
                        placeholder = "Email"
                        type = 'text'
                        value = {email} 
                        onChange = {(e) => setEmail(e.target.value)} />
        
                    <div className = "bttns-row bttn-margin-top">
          
                        {goBackBttn}
        
                        <button 
                            onClick={sendPasswordResetEmail} 
                            className="Button bttn-margin" >
                                Recuperar senha
                        </button>
                    </div>
                    </>
                }

            </div>
        )

    }

    
    if (offerPasswdReset) {
        return changePasswordJSX()
    }
    else {
        return signInJSX()
    }

}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AppContainer from '../app-container/AppContainer'
import TreeJSX from '../custom/TreeJSX'
import IsLoading from '../main/IsLoading'
import tree from '../custom/Tree'

import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import Forward from './../assets/tree/forward.png'


import './ResetHistoryScreen.css'
import CustomStudyTopBarBttn from '../custom/CustomStudyTopBarBttn'
import { db } from '../firebase/firebase-setup'

export default function ResetHistoryScreen(props) {
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [user, setUser] = useState( useSelector(state => state.user.data) )
    const [testType, setTestType] = useState('Flashcards')

    useEffect(() => {
        loadScreen('Flashcards')
    }, [])


    async function loadScreen(testType) {
        setLoadedInfo(false)
        await tree.loadData(testType, true)
        tree.createTree(testType)
        setLoadedInfo(true)
    }


    function registerBttnClick (clickedBttn) {
        loadScreen(clickedBttn)
        setTestType(clickedBttn)
    }


    function resetHistory() {
        db.collection(`/users/${user.id}/${testType}/`)
    }

    return (
        <AppContainer>
            { !loadedInfo &&
                <IsLoading />
            }
            {
            <div className = 'ResetHistoryScreenContainer'>

                <div className = 'ResetHistoryTopBar'>
                    <CustomStudyTopBarBttn
                        icon = {Flashcards}
                        text = {'Flashcards'}
                        setSelected = {registerBttnClick}
                        clicked = {testType == 'Flashcards'} />


                    <CustomStudyTopBarBttn
                        icon = {Residencia}
                        text = {'Residencia'}
                        setSelected = {registerBttnClick}
                        clicked = {testType == 'Residencia'} />

                </div>

                <div className = 'TreeContainer'>
                    <TreeJSX
                        loadedTree = {loadedInfo}
                        searchedString = {''}
                        consultMode = {false}
                        reviewsOnly = {true}
                        testType = {'Flashcards'}
                    />
                </div>


                <div className = 'StartSessionContainer'>
                    <div 
                        className = "TreeScreenStartBttn" 
                        onClick = {resetHistory}>
                            <p>Apagar! </p>
                            <img className = "TreeScreenStartBttnIcon" src={Forward} />    
                        </div>
                </div>
            </div>
            }
        </AppContainer>
    )
}

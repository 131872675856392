import React, { useState, useEffect } from 'react';
import styled from 'styled-components';




const Emj = styled.span`
  font-size: 1.6em;
`

const Txt = styled.span`
  font-size: 1.3em;
  font-weight: bold;
`


export default function Anna() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const currentYear = now.getFullYear();
      let targetDate = new Date(currentYear, 8, 2)

      // Se a data já passou este ano, use o próximo ano
      if (now > targetDate) {
        targetDate = new Date(currentYear + 1, 8, 13);
      }

      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    calculateTimeLeft();

    // Atualiza o contador a cada segundo
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <p>
        <Emj>
          🏊🏻‍♀️ 🥇 
        </Emj>
        <Txt>{timeLeft.days} dias, {timeLeft.hours} horas, {timeLeft.minutes} minutos, {timeLeft.seconds} segundos </Txt>
        <Emj>
          🫀 👙
        </Emj>
      </p>
    </div>
  );  
}
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation} from "react-router-dom"
import { useSelector } from 'react-redux'
import OslerLogo  from './../assets/Logo.png'
import OslerLogoDark from './../assets/LogoDark.png'
import SignatureIcon from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import Bedside from './../assets/bedside.png'
import Setup from './../assets/settings.png'
import Stetho from './../assets/stethoscope.png'
import Home from './../assets/home.png'
import Menu from './../assets/menu.png'
import Graph from './../assets/analytics.png'
import KeyIcon from './../assets/extensivo.png'
import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import styled from 'styled-components'
import SideBarButton from './MobileBarButton'
import Moon from './../assets/lua.png'
import Sun from './../assets/sol.png'
import DarkModeToggle from './DarkModeToggle'
import NoteIcon from './../assets/personal_note.png'
import Trilha from './../assets/trilha.png'

const Container = styled.div`
    width: 100vw;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    ` : `
        background-color: white;
        // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
    `}
`

const MenuContainer = styled.div`
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0.2em 0 0.2em;

    height: ${props => props.height}px;
    

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        color: rgb(220, 220, 220);
    ` : `
        background-color: white;
    `}
`


const Overlay = styled.div`
    position: absolute;
    width: 50vw;
    left: 50vw;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
    height: ${props => props.height}px;

    
`


const Logo = styled.img`
    width: 2em;
`


const Signature = styled.img`
    height: 1.8em;
    margin-left: 1em;
`


const MenuLogo = styled.img`
    width: 3em;
`


const MenuTxt = styled.p`
    text-align: center;
    font-weight: bold;
    margin-top: 1em;
    font-size: 0.9em;
`


const Brand = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`


const MenuButton = styled.div`
    & > img {
        width: 1.8em;
    }
`


const DarkModeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    padding: 0.6em;
`

const Icon = styled.img`
    width: 40px;
`


export default function TopBar() {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    let navigate = useNavigate();
    const path = useLocation().pathname
    const [ menuVisible, setMenuVisibible ] = useState(false)


    const bttns = [
        { icon: Stetho, text: 'Inicial', where: '/app' },
        { icon: KeyIcon, text: 'Cronogramas', where: '/track' },
        { icon: Flashcards, text: 'Flashcards', where: '/flashcards' },
        { icon: Residencia, text: 'Residência', where: '/residencia' },
        { icon: Trilha, text: 'Correção de Erros', where: '/mistakes' },
        { icon: NoteIcon, text: 'Caderno', where: '/notebook' },
        { icon: Graph, text: 'Estatísticas', where: '/statistics' },
        { icon: Setup, text: 'Configurações', where: '/user' },
        { icon: Home, text: 'Website', where: '/home' },
        // { icon: Bedside, text: 'Bedside', where: '/bedside' },
    ]


    function showMenu() {
        setMenuVisibible (!menuVisible)
    }


    function closeMenu() {
        // console.log('Scrolling')
        // window.scrollTo(0, 0) // acho que isso não tá funcionando????
        setMenuVisibible(false)
    }


    return (
        <div>
            {menuVisible && (
            <>
                <MenuContainer height={document.documentElement.scrollHeight}>

                    <MenuLogo src = {darkMode ? OslerLogoDark : OslerLogo} />

                    <MenuTxt>Não é fácil ser foda! 🔥</MenuTxt>

                    <DarkModeContainer>
                        <Icon src = {darkMode ? Moon : Sun} />
                        <DarkModeToggle />
                    </DarkModeContainer>

                    
                    {bttns.map((bttn, index) => (
                        <SideBarButton
                            key={index}
                            icon={bttn.icon}
                            text={bttn.text}
                            selected={bttn.where === path}
                            action={() => navigate(bttn.where)}
                        />
                    ))}
                </MenuContainer>

                <Overlay onClick={closeMenu} height={document.documentElement.scrollHeight} />
                </>
            )}


            {/* Barra do topo */}
            <Container>
                <MenuButton onClick={showMenu}>
                    <img src={Menu} alt="Menu" />
                </MenuButton>

                <Brand>
                    <Logo src = {darkMode ? OslerLogoDark : OslerLogo} />
                    <Signature src = {darkMode ? SignatureDark : SignatureIcon} />
                </Brand>

                <div></div> {/* Spacer */}
            </Container>
        </div>
    )
}
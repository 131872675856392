import React from 'react';

import './FAQ.scss';

import { Link } from 'react-router-dom';

import { htmlToReact } from './../utils/HtmlToReact'
import SectionTitle from './SectionTitle';

export default function FAQ() {
    const questions = [
        ["Qual o risco de assinar?", (<div><p><strong>O risco é zero:</strong> nosso sistema de pagamento é seguro (Stone) e você tem 7 dias de garantia de reembolso integral. Basta mandar um e-mail para suporte@osler-ensino.com que fazemos seu estorno, rapidamente!</p></div>)],


        ["O acesso é por 12 meses?", (<div><p>Não. O acesso é até 31 de Janeiro de algum ano subsequente, a depender do plano que você adquiriu.</p></div>)],


        ["Há a possibilidade um plano por 12 meses?", (<div><p>Não! Nós vendemos acesso até uma data futura fixa, tal qual os demais preparatórios sérios!</p></div>)],


        ["Há a possibilidade de 'parcelamento inteligente' ou boletos mensais?", (<div><p>Não! Por questões técnicas, estamos restritos ao pagamento por boleto à vista ou parcelado em cartão (com "consumo de limite", como todo parcelamento).</p></div>)],


        ["É um aplicativo? Onde eu uso?", (<div><p>A plataforma é acessada online, através do seu computador, tablet, ou celular. <u>Não</u> é um aplicativo da loja: você acessa tudo através de nosso site.</p></div>)],
        

        ["É apenas para quem está prestando residência?", (<div><p>De modo algum! Embora a maioria dos nossos clientes estejam se preparando para a prova, muitos estão no ciclo clínico ou no começo do internato.</p></div>)],


        ["Criei uma conta e não chegou o email de confirmação.", (<div><p>Ou está na caixa de spam (verifique!) ou você digitou seu email errado durante o cadastro (tente sair e entrar novamente e verifique se o email está correto).</p></div>)], 


        ["Tem teste gratuito?", (<div><p>Não! Por questões de segurança, só há acesso após o pagamento. Mas, se você comprar, poderá testar tudo livremente. Se não se adaptar, peça cancelamento nos primeiros 7 dias e terá estorno do pagamento!</p></div>)],
    ]

    const formatted = questions.map( (question, index) => {
        return (
            <div className="question-div" key={index}>
                <h4 className="faq-question">{ htmlToReact(question[0]) }</h4>
                { question[1] }
            </div>
        )
    })

    return (
        <div className="section section-dark-bg faq-container">
            <SectionTitle subtitle="FAQ" title="Suas perguntas, respondidas." />
            <div className="question-holder">
            {formatted}
            </div>
        </div>
    )


}


import React, { useEffect, useState, useRef, useMemo} from 'react';
import styled from 'styled-components'
import { SocialEditor } from '@remirror/react-editors/social'
import OslerTextEditor from './OslerTextEditor';
import OslerButton from '../components/OslerButton'
import OslerData, { KEYS } from '../controllers/OslerData'
import { db } from '../firebase/firebase-setup';
import { useSelector } from 'react-redux';
import Notebook from '../controllers/NotebookController';
import OslerDropdown from '../components/OslerDropdown';
import { removeHTMLTags, sleep } from '../utils/Utils';
import NotebookEditor from '../mistakes/NotebookEditor';
import { set } from 'remirror';
import NoteSelector from '../mistakes/NoteSelector';
import NotebookController from '../controllers/NotebookController';
import GeneralDialog from './GeneralDialog';


const Container = styled.div`

    // width: ${props => props.isOpen ? '30vw' : '0vw'}; 

    width: 35vw;
    height: 100vh;

    padding: 3em;
    overflow-y: auto;

    position: fixed;  // Mantém o editor no mesmo lugar
    right: ${props => props.isOpen ? '0' : '-35vw'};  // Desliza para dentro e fora da tela
    transition: right 0.5s ease-in-out;  // Suaviza a transição


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${props => props.theme.darkMode ? `
        background-color: transparent;
        box-shadow: none;
        color: white;
        border: none;
    ` : `
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
        color: black;
    `}

    @media (max-width: 500px) {
        width: 70%;
    }
`

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;
`


const BttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
`

// Óbvia similaridade com Notebook, mas lá é tela cheia e aqui é slide.
// Precisa ser, de algum modo, generalizado

export default function NotebookSlide({ isOpen, close }) {

    const [selectedNoteID, setSelectedNoteID] = useState(false)
    const [selectedNoteTitle, setSelectedNoteTitle] = useState(false)

    const [hasThemeNote, setHasThemeNote] = useState(false)


    const notebook = useRef()
    const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false)

    // const [nNotes, setNumberNotes] = useState(0)

    


    // useEffect(() => {
    //     if (!NotebookController.metadata) return

    //     let c = 0;
        
    //     Object.entries(NotebookController.metadata).forEach(([theme, notesInTheme]) => {
    //         c += Object.keys(notesInTheme).length
    //     })

    //     setNumberNotes(c)

    //     // Colocamos selectedNoteID para forçar novo cálculo quando uma nota é criada
    // }, [selectedNoteID])




    async function createNote() {
        notebook.current.save()
        const ID = await notebook.current.createNote()
        setSelectedNoteID(ID)
    }


    function deleteNote() {
        notebook.current.deleteNote()
        setSelectedNoteID(false)
        setSelectedNoteTitle(false)
        setShowDeleteNoteDialog(false)
    }


    useEffect(() => {
        console.log('NotebookSlide: iremos procurar nota de algum tagpath...')
        
        if (Notebook.metadata) {
            console.log(`O tagpath é: ${Notebook.recommendedTagpath}`)
            const recommendedTagpath = Notebook.recommendedTagpath
            
             if (recommendedTagpath) {
                const ID = Notebook.noteIDForTagpath(recommendedTagpath)

                console.log(`Para o tagpath, achamos o noteID: ${ID}`)
                if (ID) {
                    setHasThemeNote(true)
                    setSelectedNoteID(ID)
                    return
                }
            }

            setHasThemeNote(false)
        }
    }, [isOpen])


    if (!isOpen) return null;

    return (
        <Container isOpen = {isOpen}>


            <GeneralDialog
                open = {showDeleteNoteDialog}
                title = '💀 Deletar o resumo?'
                onClose = {() => setShowDeleteNoteDialog(false)}
                actions = {[
                    { label: 'Cancelar', onClick: () => setShowDeleteNoteDialog(false), style: 'neutral' },
                    { label: 'Deletar', onClick: deleteNote, style: 'destructive' }
                ]} >

                <p>Isso é — absolutamente, catastroficamente — irreversível.</p>
                
                <p>Prosseguir?</p>

            </GeneralDialog>

            <Actions>
                <NoteSelector
                    selectedNoteID = {selectedNoteID}
                    selectedNoteTitle = {selectedNoteTitle}
                    changeNote = {(newNoteID) => setSelectedNoteID(newNoteID)} />
                <BttnsContainer>                            

                { selectedNoteID && 
                    <>
                   
                        <OslerButton
                            size = 'small'
                            bgColor = 'blue'
                            text = 'Criar resumo'
                            onClick = {createNote}
                            style = {{marginRight: '1em'}}  />

                        <OslerButton
                            size = 'small'
                            bgColor = 'red'
                            text = 'Deletar'
                            onClick = {() => setShowDeleteNoteDialog(true)}
                            style = {{marginRight: '1em'}} />
                   
                    </>
                    }
                    { !hasThemeNote && 
                        <OslerButton
                            size = 'small'
                            bgColor = 'gray'
                            text = 'Fechar'
                            onClick = {() => notebook.current.exit(false)} />
                    
                    }

                   
                </BttnsContainer>

            </Actions>


            { !hasThemeNote && !selectedNoteID && 
                <>
                <p>Você não tem nenhum resumo deste tema.</p>

                <OslerButton
                    size = 'small'
                    bgColor = 'blue'
                    text = 'Criar resumo'
                    onClick = {createNote}
                    style = {{marginRight: '1em'}}  />
                </>
            }

                <NotebookEditor
                    ref = {notebook}
                    onClose = {close}
                    chosenNoteID = {selectedNoteID}
                    signalTitleChanged = {newTitle => setSelectedNoteTitle(newTitle)} />
        
        </Container>
    )
}

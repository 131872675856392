import React, { useState } from 'react'


import SignIn from './SignIn'
import SignUp from './SignUp'

import './LoginScreen.css'
import logo from './../assets/Logo.png'

export default function LoginScreen() {
    
    const [method, setMethod] = useState('signIn')
    function changeMethod() {
        setMethod( method == 'signIn' ? 'signUp' : 'signIn' )
    }
    
    return (
        <div className="LoginScreen">

            
            <div className="LoginContainer">
                <img className="Logo" src={logo} />


                <div className = "input-container">

                    { method == 'signIn' &&
                        <SignIn 
                            changeMethod = {changeMethod} />
                    }

                    { method == 'signUp' && 
                        <SignUp
                            changeMethod = {changeMethod} /> 
                    }

                </div>
            </div>


        </div>
    )
}

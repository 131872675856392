import React, { useState } from 'react'
import { db } from '../firebase/firebase-setup'
import { useDispatch, useSelector } from 'react-redux'

import UserScreenCard from "./UserScreenCard";

import SubscriptionIcon from './../assets/credit_card_icon.png' 

import './PaymentProcessing.css'
import NiceButton from '../components/NiceButton';
import { sleep } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice';

import firebase from 'firebase/compat/app';
import OslerButton from '../components/OslerButton';


export default function PaymentProcessing(props) {

    const user = useSelector(state => state.user.data)
    const dispatch = useDispatch();

    const [tries, setTries] = useState(0)

    async function tryLoadBoleto() {
        dispatch(setIsLoading(true))
        await sleep(2000)
        props.verify()
        setTries(tries + 1)
        dispatch(setIsLoading(false))
    }


    async function cancelBoleto() {
        dispatch(setIsLoading(true))

        await db.collection("users").doc(user.id).update({
            subscription: firebase.firestore.FieldValue.delete()
        })

        // await sleep(2000)

        props.verify()
        dispatch(setIsLoading(false))
    }

    function getContent() {
        if (user && user.subscription && user.subscription.paymentMethod && user.subscription.paymentMethod === 'credit_card') {
            return (
                <div className = 'payment-processing-txt'>
                    <p><strong>Seu pagamento está em processamento!</strong></p>

                    <p>Como você pagou com cartão, a liberação costuma ocorrer em menos de cinco minutos. Confira no app do banco se o pagamento está ok, e atualize a página daqui a pouco!</p>

                    <p>Se você acha que algo deu errado, entre em contato conosco via DM (@oslermedicina) ou, preferencialmente, pelo email do suporte (suporte@osler-ensino.com).</p>
                </div>
            )
        }
        else if (user && user.subscription && user.subscription.paymentMethod && user.subscription.paymentMethod === 'boleto') {
            if (user.subscription.status === 'processing') {
                if (tries == 0) {
                    return (
                        <div className = 'payment-processing-txt'>
                        <p className = 'payment-main-msg'><strong>Seu boleto está sendo criado!</strong></p>
        
                        <p>Em poucos minutos, ele estará disponível aqui, e também te enviaremos por email.</p>
    
                        <NiceButton
                            color = '#2779c4'
    
                            style = {{width: '12em', alignSelf: 'center', marginTop: '1em', marginBottom: '1.5em'}}
                            text = {"Ver se o boleto chegou"}
    
                            action = {tryLoadBoleto} />
    
                        <p>Se você acha que algo deu errado ou está com alguma dúvida, entre em contato conosco via DM (@oslermedicina) ou, preferencialmente, pelo email do suporte (suporte@osler-ensino.com).</p>
                        </div>
                    )
                }
                else {
                    return (
                        <div className = 'payment-processing-txt'>
                        <p className = 'payment-main-msg'><strong>Puts, <u>ainda</u> estamos criando seu boleto.</strong></p>
        
                        <p>Pode demorar até 5 minutos. Bom momento para ir tomar (mais um) expresso. ☕</p>
    
                        <NiceButton
                            color = '#2779c4'
    
                            style = {{width: '12em', alignSelf: 'center', marginTop: '1em', marginBottom: '1.5em'}}
                            text = {"Ver se o boleto chegou"}
    
                            action = {tryLoadBoleto} />
    
                        <p>Se você acha que algo deu errado ou está com alguma dúvida, entre em contato conosco via DM (@oslermedicina) ou, preferencialmente, pelo email do suporte (suporte@osler-ensino.com).</p>
                        </div>
                    )
                }
            }
            else if (user.subscription.status === 'waiting_payment'){
                return (
                    <div className = 'payment-processing-txt'>
                        <p className = 'payment-main-msg2'><strong>Seu boleto está pronto, só falta pagar!</strong> 😂</p>
                        <ul>
                        <li><strong>Código de barras: </strong><span className='payment-barcode'>{user.subscription.transaction_boleto.barcode}</span></li>
                        
                        <li>Ou, se preferir, acesse o boleto <a target="_blank" href={user.subscription.transaction_boleto.url}><strong>clicando aqui</strong></a>!</li>
                        </ul>
                    
                        <p>Após pagar o boleto, você terá acesso quando ocorrer a compensação, o que ocorre em, geralmente, até no máximo 3 dias úteis.</p>
    
                        <p>Se você acha que algo deu errado ou está com alguma dúvida, entre em contato conosco via DM (@oslermedicina) ou, preferencialmente, pelo email do suporte (suporte@osler-ensino.com).</p>

                        <p><strong>Se arrependeu desse boleto?</strong> Se você <u>ainda NÃO pagou</u> e quer mudar o método para cartão de crédito, você pode deletá-lo.</p>

                        <OslerButton
                            bgColor = 'red'
                            text = 'Cancelar boleto'
                            onClick={cancelBoleto}
                            style={{marginTop: '1em'}} />
                            
                    </div>
                )
            }

        }
        else {
            return (
                <>
                <p>Você não devia estar lendo isso. Manda um print para nosso suporte, imediatamente?</p>

                <p>Via DM (@oslermedicina) ou pelo email suporte@osler-ensino.com.</p>
                </>
            )
        }
    }

    return (
        <>
        { getContent() }
        </>
    )
}
import React from 'react'
import CustomStudy from './CustomStudy'


export default function ResidenciaScreen() {

    return (
        <CustomStudy
            testType = {'Residencia'} />
    )
}
import Test from './Test.js'
import { changeCommandsForHtmlImages } from './../utils/ImageLoader'

// import stetho from './../assets/stethoscope.png'

class Flashcard extends Test {
    constructor(testID, data) {
        super(testID, data, 'Flashcards')

        this.question = data.question
        this.answer   = data.answer

        this.dataWasLoaded = false

        this.type      = data.type ? data.type : "classic"
        this.reference = this.safeLoad(data.reference)
        this.prefix    = this.safeLoad(data.prefix)

        if (this.prefix != "") {
            // Presumimos, razoavelmente, que this.question sempre
            // começa com '<p>' e queremos inserir o prefixo no início
            // dessa primeira frase, com um espaço.
            this.question = this.question.replace("!@#", this.prefix)

            // Mesmo raciocínio. Desde o cloze, podemos ter prefixo
            // na resposta.
            this.answer   = this.answer.replace("!@#", this.prefix)
        }

        if (this.reference != "") {
            this.answer += '<span class = "Flashcard-Reference">' + this.reference + '</span>'
        }
    }


    safeLoad(string) {
        if (string && string !== "null") {
            return string
        }
        else {
            return ""
        }
    }


    async loadData(statisticsManager, loadStatistics) {
        if (this.dataWasLoaded) {
            return;
        }

        // Obtain days since last review, which will be used to generate
        // information to the SRS buttons, but also to submit data when
        // the user logs an answer.
        if (loadStatistics) {
            await this.loadStatistics(statisticsManager)
        }

        this.question = await changeCommandsForHtmlImages(this.question)
        this.answer   = await changeCommandsForHtmlImages(this.answer)

        // const source = require('./../assets/stethoscope.png')
        // this.stetho = await localImageToBase64( source )

        this.dataWasLoaded = true;
    }


    getType() {
        return this.type;
    }


    getQuestion() {
        return this.question;
    }


    getAnswer() {
        return this.answer;
    }



}



export default Flashcard;
import React, { useEffect, useState, useRef } from 'react'
import './SocialProof.scss'
import { useNavigate } from "react-router-dom";
import { sleep } from './../utils/Utils'

import LeftArrowIcon from './../assets/left.png'
import RightArrowIcon from './../assets/right.png'

import { isMobile } from '../utils/BootstrapUtils'
import Offer from './Offer'

export default function SocialProof() {

    let navigate = useNavigate();

    function action(planID) {
        // Recebe o plano escolhido como parâmetro, mas não
        // utiliza.
        navigate('/login')
    }

    const [carouselIndex, setCarouselIndex] = useState(2)

    let carouselWaiting = useRef(false);

    useEffect(() => {
        async function runCarousel() {
            console.log('Carousel image changed')
            if (!carouselWaiting.current) {
                console.log('Is not awaiting, will set new change')
                // Evita que um novo sleep seja iniciado várias vezes, após vários cliques. É mais fácil
                // não iniciar do que cancelar o anterior.
                carouselWaiting.current = true;
                await sleep(4000)
                nextCarouselImg()
                carouselWaiting.current = false;
                console.log('Changed')
            }
            else {
                console.log('failed')
            }
        }

        runCarousel()
    }, [carouselIndex])

    // Os filenames vão de 1 a 39
    const carouselMaxImgs = 121


    function getCarouselImgPath(index) {
        return (`./../assets/testimonials/img_${index}.jpg`)
    }


    function nextCarouselImg() {
        if (carouselIndex < carouselMaxImgs) {
            setCarouselIndex(carouselIndex + 1)
        }
        else {
            setCarouselIndex(1)
        }
    }

    function previousCarouselImg() {
        if (carouselIndex != 0) {
            setCarouselIndex(carouselIndex - 1)
        }
        else {
            setCarouselIndex(carouselMaxImgs)
        }
    }


    function getLeftArrowJSX() {
        return (
            <div className = 'OslerCarousel-LeftArrow' onClick = { previousCarouselImg }>
                <img src = {LeftArrowIcon} className = 'OslerCarousel-ArrowIcon'/>
            </div>
        )
    }


    function getRightArrowJSX() {
        return (
            <div className = 'OslerCarousel-RightArrow' onClick={ nextCarouselImg }>
                <img src = {RightArrowIcon} className = 'OslerCarousel-ArrowIcon'/>
            </div>
        )

    }



    function getCarouselImgJSX() {
        return (
            <img 
                src = {require(`./../assets/testimonials/img_${carouselIndex}.jpg`)}
                className = 'OslerCarousel-Image' />
        )
    }

    return (
        <>
        <div className = "section section-light-bg social-proof-container">

            <div className = 'OslerCarousel-Container'>
                <div className = 'OslerCarousel-ImageContainer'>
                    { getCarouselImgJSX() }
                </div>
            </div>

            <div className="social-proof-text-container">
                <p className = "social-proof-title">+3.630 alunos</p>
                <p className = "social-proof-text">Olha os depoimentos e avalia se você já viu isso para um preparatório médico.</p>
            </div>
       </div>

       {/* <div className = "section section-light-bg section-full-height ">
                <Offer
            buttonText = {'Garantir esse preço'}
            action = {action} />
       </div> */}
</>

    )
}
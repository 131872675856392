import React from 'react'
import GridOfIcons from './GridOfIcons'
import SectionTitle from './SectionTitle'
import './StudyMethod.css'


import Flashcard from './../assets/principles/flashcard.png'
import Dumbbell from './../assets/principles/dumbbell.png'
import Amnesia from './../assets/principles/amnesia.png'
import Calendar from './../assets/principles/calendar.png'

export default function StudyMethod() {

    const data = [
        {
            image: Dumbbell, 
            title: 'Testing Effect',
            text: (
                <>
                <p><b className = 'destaque'><i>Testing effect</i></b> é princípio da psicologia de que você <strong>entende melhor e memoriza mais</strong> quando estuda através testes (e.g., flashcards, questões de residência) ao invés de por métodos passivos (e.g., videoaulas, apostilas).</p>

                <p>Além disso, testes melhoram até o estudo passivo: quando você faz questões antes de ler ou ver à aula, você direciona sua atenção para o que realmente é cobrado (<strong><em>priming</em></strong>).</p>
                
                <p>Por fim, testes geram <b>autoconhecimento</b>: fica explícito o que você sabe e o que não sabe, evitando autoengano, e otimizando seu tempo de estudo para aqueles assuntos que você ainda não tem maestria.</p>
                </>
            )   
        },
        {
            image: Amnesia, 
            title: 'Active Recall',
            text: (
                <>
                <p>O melhor tipo de teste é aquele baseado em perguntas abertas.</p>
                
                <p>Questões de múltipla escolha (como as residência) são boas para aprender a fazer prova.</p>

                <p>Mas o aprendizado é mais robusto quando você precisa lembrar do conhecimento (<b className = 'destaque'><i>active recall</i></b>, rememorar) e raciocinar a respeito ao invés de reconhecer a alternativa correta e/ou excluir as erradas.</p>

                <p>Além disso, você acaba <u>decorando questões</u>, excluindo o benefício de revisá-las.</p>
                    
                <p>O aprendizado é maior quando há mais esforço envolvido (<b className = 'destaque'><i>desirable difficulty</i></b>). Se você quer ficar forte, é melhor levantar um peso rosa de 2kg ou um peso que te desafia, dói o braço, e dá certo desespero? 🧠 💪🏼</p>

                </>
            )
        },
        {
            image: Calendar, 
            title: 'Repetição Espaçada',
            text: (
                <>
                <p><b>Não importa o quão bom seja seu método de estudos: você vai esquecer</b>. Somos humanos e o volume de informações da medicina não colabora.</p>

                <p><b className = 'destaque2'>Se seu método de estudos não incorpora revisão de modo sistemática, ele não está otimizado.</b></p>

                <p><b className = 'destaque'><i>Spaced repetition</i></b> (em português, <b>repetição espaçada</b>) é um algoritmo de revisão no qual o intervalo entre cada revisão cresce exponencialmente, e é maior quando você acerta e tem facilidade.</p>
                
                <p>Deste modo, <u>minimiza-se o número de revisões, mas garante-se a memorização de &gt;90% do conteúdo</u>.</p>
                
                <p>Tem gente (esforçada) que faz isso com planilha no Excel (sim, em 2023...), mas o melhor é utilizar um algoritmo como o do Anki.</p>
                </>
            )
        },
        {
            image: Flashcard, 
            title: 'Flashcards',
            text: (
                <>
                <p><b className = 'destaque'><i>Flashcards</i></b><b> são o melhor modo de implementar os princípios acima.</b>E saiba que <i>cards</i> não são resumos pequenos, mas sim testes  baseados em perguntas com respostas curtas e direcionadas.</p>
                
                <p><b>Nos Estados Unidos, ninguém estuda para o Step 1 ("residência americana") sem flashcards.</b> Porque o método simplesmente funciona, a ponto de <u>já ter até estudos no PubMed demonstrando a superioridade</u>.</p>
                
                <p><b>Estudar sem flashcards é estar de mãos dadas com o passado.</b> É crescente o número de alunos entre os aprovados que usaram o Anki, a Osler, ou alguma outra plataforma de flashcards.</p>
                </>
            )
        },
        
    ]
    
    return (
        <div className = 'section section-dark-bg study-method'>
            
            <SectionTitle
                title = "Seu método de estudo é eficaz?"
                subtitle = "Veja se ele implementa os princípios abaixo." />


            <GridOfIcons 
                invert = {true}
                data = {data} />

        </div>
    )
}
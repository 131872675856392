import React, { useEffect, useState, useRef } from "react";
import CheckboxColumn from "./CheckboxColumn";
import DeleteFilterDialog from './DeleteFilterDialog'
import { useSelector } from 'react-redux'
import { db } from '../firebase/firebase-setup'

import Applied from './../assets/approved.png'

import "./CustomFiltersCard.css";
import OslerData from "../controllers/OslerData";
import styled from "styled-components";


const CustomFilterContainer = styled.div`


	background-color: ${props => props.theme.darkMode ? `rgb(130, 130, 130);` : `rgb(235, 232, 232);`}
  display: flex;
  flex-direction: row;
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 3em;

  color: ${props => props.theme.darkMode ? `rgb(200, 200, 200);` : `black;`}
`;



const CustomFilterTitle = styled.div`
    font-size: 1.2em;
    font-weight: bold;
`;

const CustomFilterActionButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5em;
    width: 8em;
    border-radius: 3em;
    cursor: pointer;

	${props => props.theme.darkMode ? `
		box-shadow: none;
		background-color: rgb(90, 90, 90);

		&:hover {
			background-color: rgb(110, 110, 110);
		}
	`:`
		background-color: white;
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	
		&:hover {
			background-color: rgba(255, 255, 255, 0.8);
			box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
		}
	`}


    &:hover {
        font-weight: bold;
    }
`;


export default function FiltersCard(props) {
	const customFilters = useRef()
	
	const [showDeleteFilterDialog, setShowDeleteFilterDialog] = useState(false)



	const [JSXfilters, setJSX] = useState()

    const user = useSelector(state => state.user.data)

	const appliedFilters = useRef([])

	const filterToDelete = useRef()

	useEffect(() => {
		customFilters.current = props.data;
		appliedFilters.current = []
		filterToDelete.current = undefined
		getFilters()
	}, []);

	function applyFilter(filter) {
		props.signalFilters('institutions', filter['institutions'])
		props.signalFilters('years', filter['years'])

		appliedFilters.current.push(filter['filter_name'])
		getFilters()
		props.onApplyFilter()
	}


	function removeFilter(filter_name) {
		// TODO Chamar função da custom study
		filterToDelete.current = filter_name
		setShowDeleteFilterDialog(true)

	}


	function listenerDeletion() {
		let newFilters = customFilters.current.filter(filter => filter['filter_name'] != filterToDelete.current)

        db.doc(`/users/${user.id}/personal/residencia_filters`).set({
            'filters' : newFilters
        })

		// Garantimos que o arquivo novo será baixado, e forçamos a atualização
		// na tela inicial. E, mesmo antes do download, já copiamos algo preliminar.
		props.updateResidenciaFilters(newFilters)

		OslerData.downloadResidenciaFilters()

		customFilters.current = newFilters

		setShowDeleteFilterDialog(false)

		// TODO Se não sobrou nenhum, tirar da tela

        // // Se não sobrou nenhum, saímos da tela de filtros.
        // if (newFilters.length == 0) {
        //     changeSelectedTab('Temas')
        // }

        // // Como os filtros foram deletados, eles não podem mais estar selecionados.
        // // Por via das dúvidas, limpamos todas as seleções: talvez não satisfaça
        // // alguns edge cases, mas é mais compreensível.
        // resetAllSelections()

		getFilters()
	}


	function getFilters() {
		let JSX = customFilters.current.map(filter => {
			let filter_name = filter['filter_name']

			let institutions = ''
			console.log(filter)

			filter['institutions'].forEach((name, index) => {
				// Extraímos a sigla entre parenteses

				console.log(name)

				const sigla = name.substring(name.indexOf('(') + 1, name.indexOf(')'));
				institutions += sigla

				if (index < filter['institutions'].length - 1) {
					institutions += ` | `
				}
			});

			let years = ''
			filter['years'].forEach((year, index) => {
				years += year
				if (index < filter['years'].length - 1) {
					years += ' | '
				}
			})
	
			return (
				<CustomFilterContainer>
					<div className = 'CustomFilter-Data'>
						<CustomFilterTitle>{filter_name}</CustomFilterTitle>
						<p>{institutions}</p>
						<p>{years}</p>
					</div>

					<div className = 'CustomFilter-Actions'>
						{ appliedFilters.current.includes(filter_name) && 
							<img className = 'CustomFilter-IconApplied' src = {Applied} />
						}

						{ !appliedFilters.current.includes(filter_name) && 
												<>
						<CustomFilterActionButton
								onClick = {() => applyFilter(filter) }>
							Aplicar
						</CustomFilterActionButton>

						<CustomFilterActionButton
								onClick = {() => removeFilter(filter_name) }>
							Deletar
						</CustomFilterActionButton>
						</>
					}
					</div>
	
				</CustomFilterContainer>
				)
		})

		console.log('Updating STATE')
		setJSX(JSX)
	}

	return (

		<div className="CustomFiltersCard-Container">

			{ JSXfilters }

			<DeleteFilterDialog
				showDialog = { showDeleteFilterDialog }
				setShowDialog = { setShowDeleteFilterDialog }
				deleteFilters = { listenerDeletion } />
		</div>
	);
}

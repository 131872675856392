import React, { useEffect, useState, useRef } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setIsLoading } from '../redux/loadingSlice'

import '../scss/Main.scss'
import './CustomStudy.css'

import TreeJSX from './TreeJSX.js'
import session from '../controllers/Session'
import sessionBuilder from '../controllers/SessionBuilder'
import tree, { TreeFilters } from './Tree'

import Forward from './../assets/tree/forward.png'
import { db } from '../firebase/firebase-setup'
import getDoc from '../firebase/firebaseDocs'

import Logo from './../assets/Logo.png'
import LogoDark from './../assets/LogoDark.png'

import { toastMsg } from '../utils/Utils';
import AppContainer from '../app-container/AppContainer'
import { guaranteeSubscription } from '../firebase/firebaseUtils';
import IsLoading from '../main/IsLoading'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import CustomStudyTopBar from './CustomStudyTopBar'
import InstitutionsCard from './InstitutionsCard'
import YearsCard from './YearsCard'
import CustomFiltersCard from './CustomFiltersCard'
import CustomFilterModal from './CustomFilterModal'
import OthersCard from './OthersCard'
import TreeScreenStartBttn from './TreeScreenStartBttn'
import SaveFilterDialog from './SaveFilterDialog'

import OslerData, { KEYS } from '../controllers/OslerData'

import ConsultationModeCard from './ConsultationMode'
import styled from 'styled-components'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles.js'


export const TreeScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${backgroundWhiteBlack}

    padding-top: 2em;



    width: 90%;
    min-height: 100vh;


    @media (max-width: 1600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 0;
        margin-bottom: 4em;
        margin-top: 20px;
    }
`


export const TreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    width: 80%;
    padding: 1em;
    box-sizing: border-box;

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    `:`
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    `}

    border-radius: 0.5em;

    @media (max-width: 1600px) {
        width: 95%;
        margin-top: 1em;
    }


    @media (max-width: 500px) {
        width: 100%;
        box-shadow: none;
        margin-top: 1em;
        padding: 0;
        align-items: center;
    ${backgroundWhiteBlack}   
    }
`




export default function CustomStudy(props) {
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkModeOn)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false)

    const testType = useRef()

    const [selectedTab, setSelectedTab] = useState('Temas')
    const [loadedInfo, setLoadedInfo] = useState(false)


    // const includeAnuladas = useRef()
    const [includeAnuladas, setAnuladas] = useState(false)
    const [searchString, setSearch] = useState("")
    // const [saveButton, setSaveButton] = useState(false)
    // const [resetButton, setResetButton] = useState(false)
    const [anyInstitutionOrYearSelected, setAnyInstitutionOrYearSelected] = useState(false)
    const [anyCustomFilterSelected, setAnyCustomFilterSelected] = useState(false)
    

    // Opções: test-mode, consult-mode, playground-mode
    const chosenMode = useRef('test-mode')


    const institutionsTabData = useRef()
    const yearsTabData = useRef()
    const customFiltersData = useRef()

    // const modalReturnedValue = useRef()
    // const modalOperationType = useRef()

    // Respectivamente: 
    //  - instituições selecionadas, anos selecionados, e filtros do usuário (só para residência)
    //  - outros filtros (e.g., só revisões, só novos, etc)
    const filters = useRef({
        institutions: [],       
        years: [],
        customFilters: [],
        others: []
    })

    
    
    useEffect(() => {
        console.log("CustomStudy: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])



    useEffect(() => {
        async function loadInstitutions() {
            if (testType.current === 'Residencia') {
                institutionsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS]['institutions'].sort()
            }
        }


        async function loadYears() {
            if (testType.current === 'Residencia') {
                yearsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.YEARS]['years'].sort()
            }
        }


        async function loadCustomFilters() {
            // Isso aqui são os filtros de questões de residência (i.e., combinações de
            // instituição + ano).
            if (testType.current === 'Residencia') {
                const filters = OslerData.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS]
                customFiltersData.current = filters && filters['filters'] ? filters['filters'] : []
            }
        }


        async function load() {
            if (!UserReviewsInfo.isReady() ) {
                console.log("TreeScreen(): exiting to /app because data not ready")
                navigate('/app')
            }
            else {
                testType.current = props.testType;
                
                await Promise.all([
                    loadInstitutions(),
                    loadYears(),
                    loadCustomFilters()
                ])

                setLoadedInfo(true)
            }
        }

        load()
    }, [])


    function changeSelectedTab(newTab) {
        if (selectedTab != newTab) {
            setSearch("")
            setSelectedTab(newTab)
        }
    }
    

    function resetAllSelections() {
        signalFilters('institutions', [])
        signalFilters('years', [])
        signalFilters('customFilters', [])
        setAnyInstitutionOrYearSelected(false)
        setAnyCustomFilterSelected(false);
    }
    

    function computeShouldShowCustomFilterBttns() {
        // TODO Se fossem estados, nem seria necessário essa função de atualização, não?
        // Isos é vantajoso?
        let f = filters.current
        setAnyInstitutionOrYearSelected( f.institutions.length > 0 || f.years.length > 0)
        setAnyCustomFilterSelected(f.customFilters.length > 0)
    }


    async function loadCustomSession() {
        const paths = tree.extractCheckedNodes()

        if (paths.length == 0) {
            toastMsg('Você precisa escolher algum tema! 👻');
        }
        else {
            console.log("Will load custom session")
            dispatch(setIsLoading(true))
            
            const shouldSaveAsLastSession = (chosenMode.current == 'test-mode') ? true : false

            // await sessionBuilder.start(
            //     testType.current, 
            //     'custom', 
            //     shouldSaveAsLastSession, 
            //     [paths, chosenMode.current, filters.current])
            
            console.log(paths)

            await sessionBuilder.start(
                testType.current, 
                'custom', 
                chosenMode.current,
                shouldSaveAsLastSession,
                paths,
                filters.current
            )

        

            
            if (session.sessionSize > 0) {
                session.addTemporaryListener(callToChangeScreen)
                // session.addSessionWasModifiedListener(moveToTests)
            }
            else {
                dispatch(setIsLoading(false))
                toastMsg('Oops! Não há testes disponíveis para esses filtros. 👻')
            }
        }
    }


    function callToChangeScreen() {
        // É chamado quando Session acabou de carregar.
        dispatch(setIsLoading(false))
        if (session.session.length > 0) {
            console.log('Will change screen now: TreeScreen -> test')
    
            if (chosenMode.current == 'consult-mode') {
                navigate('/consult')
            }
            else {
                navigate('/question')
            }
        }
        else {
            // Por alguma razão, não conseguimos carregar nenhum teste.
            toastMsg('Oops! Não conseguimos carregar nenhum dos testes que você pediu. Dê um alô no Telegram. ⚠️')
        }
    }


    function signalFilters(typeFilter, selectedOptions) {           
        filters.current[typeFilter] = selectedOptions
    }


    function updateResidenciaFilters(newFilters) {
        customFiltersData.current = newFilters
    }


    function saveFilter(filterName) {
        const selectedInstitutions = filters.current.institutions;
        const selectedYears = filters.current.years;

        const allFilters = customFiltersData.current

        const newFilter = {
            'filter_name': filterName,
            'institutions': [...selectedInstitutions],
            'years': [...selectedYears]
        }

        if( !(allFilters.map(f => f.filter_name.toLowerCase())).includes(newFilter.filter_name.toLowerCase())) {
            try {

                customFiltersData.current.push(newFilter)
                db.doc(`/users/${user.id}/personal/residencia_filters`).set({
                    'filters' : customFiltersData.current
                })

                toastMsg(`Seu filtro "${newFilter.filter_name}" foi salvo.\nPara acessá-lo, clique na aba \"Meus filtros\".`);
            } catch (e) {
                console.log(e)
            }
        }
        // ... e já tinha algum filtro com o mesmo nome
        else {
            toastMsg('Você já tem um filtro personalizado com esse nome! Tente de novo! 👀')
        }

        setShowSaveFilterDialog(false)
    }


    function signalChosenMode(newlySelected) {
        // Somos informados por ConsultationModeCard qual o modo selecionado
        // pelo usuário.
        chosenMode.current = newlySelected
    }


    return (
        <AppContainer>
            <TreeScreenContainer>
                { !loadedInfo &&
                    <IsLoading />
                }
                { loadedInfo &&
                    <>
                    
                    <SaveFilterDialog
                        showDialog = { showSaveFilterDialog }
                        setShowDialog = { setShowSaveFilterDialog }
                        saveFilter = { saveFilter } />


                    <CustomStudyTopBar
                        searchString = {searchString}
                        setSearch = {setSearch}
                        testType = {testType.current}
                        selectedTab = {selectedTab}
                        setSelectedTab = {changeSelectedTab}
                        renderCustomFilters = {customFiltersData.current && customFiltersData.current.length > 0} />

                    <TreeContainer>
                        { selectedTab === 'Temas' && 
                            <>
                            <TreeJSX
                                searchedString = {searchString}
                                mode = {chosenMode.current}
                                testType = {testType.current}
                                filters = {filters.current['others']} />
                            </>
                        }   
                        { selectedTab === 'Modo' &&
                            <>
                            <ConsultationModeCard
                                signalChosenMode = {signalChosenMode}
                                previouslyChosenMode = {chosenMode.current}
                                />
                            </>
                        }      
                        { selectedTab === 'Instituições' &&
                            <InstitutionsCard
                                signalFilters = {signalFilters}
                                searchedString = {searchString}
                                data = {institutionsTabData.current}
                                previously = {filters.current.institutions} 
                                onCheckboxClickAction = {computeShouldShowCustomFilterBttns} />
                        }
                        { selectedTab === 'Anos' &&
                            <YearsCard
                                signalFilters = {signalFilters}
                                data = {yearsTabData.current}
                                previously = {filters.current.years}
                                onCheckboxClickAction = {computeShouldShowCustomFilterBttns} />
                        }
                        {
                            selectedTab === 'Meus filtros' && 
                            <CustomFiltersCard
                                signalFilters = {signalFilters}
                                data = {customFiltersData.current}
                                previously = {filters.current.customFilters}
                                onApplyFilter = {computeShouldShowCustomFilterBttns}
                                updateResidenciaFilters = {updateResidenciaFilters} />
                        }
                        { selectedTab === 'Outros' &&
                            <OthersCard
                                testType = {testType.current}
                                signalFilters = {signalFilters}
                                previously = {filters.current.others} 
                                user = { user } />
                        }          

                    </TreeContainer>    


                    <div className = 'StartSessionContainer'>
                        {
                            testType.current === 'Residencia' && 
                            <>
                                { anyInstitutionOrYearSelected &&
                                    <TreeScreenStartBttn
                                        text = {'Limpar seleção'}
                                        action = {resetAllSelections}
                                        classes = {'ResetSelectionBttn'} />
                                }

                                { anyInstitutionOrYearSelected && selectedTab != 'Meus filtros' &&
                                    <TreeScreenStartBttn
                                        text = {'Salvar filtro'}
                                        action = {() => setShowSaveFilterDialog(true)}
                                        classes = {'SaveSelectionBttn'} />
                                }

                                { selectedTab === 'Meus filtros' && anyCustomFilterSelected &&
                                    <TreeScreenStartBttn
                                        text = {'Excluir filtro'}
                                        // action = {() => setShowDeleteFilterDialog(true)}
                                        classes = {'DeleteSelectionBttn'} />
                            }
                            </>
                        }

                        <TreeScreenStartBttn
                            text = {'Começar! '}
                            action = {loadCustomSession}
                            icon = {Forward} />
                    </div>
                
                    <div className = 'TreeScreenSpacer'>
                        <img
                            src = {darkMode ? LogoDark : Logo }
                            className = 'TreeScreenSpacerIcon'
                        />
                    </div>

                    </>
                }

            </TreeScreenContainer>  
        </AppContainer>   
    )
}

import { db } from './../firebase/firebase-setup'
import firebase from 'firebase/compat/app';
import { 
    tagSequenceToPathS, 
    getDateFormattedForFirebase, 
    errorIntoDict,
    tagSequenceToPath,
    deckRootFromFlashcardID,
    measureTime} from './../utils/Utils'
import TestStatistics from './TestStatistics';
import { hashTestID, hashTestIDToSolved } from './HashTestID';


/*
    Esta classe abstrai as atualizações das estatísticas do
    usuário, que são solicitadas por Session conforme ele
    responde aos testes.

    Enquanto UserStatisticsManager irá atualizar todas
    as estatísticas do usuário após um teste ter sido respondido,
    TestStatistics será utilizada para cuidar da parte que é
    específica ao teste (e.g., data da próxima revisão em função
    da repetição espaçada).
*/



class UserStatisticsManager {
    constructor(testType, userID) {
        this.testType = testType;
        this.userID = userID;
        this.testStatistics = new TestStatistics(this.userID, this.testType)
        this.nTestsUpdated = 0
        this.testsUpdated = []

        this.batch = []
        this.nonNumericalBatches = []
        this.listsPromises = []
        this.todayAndOverallStatistics = {}
        this.nEachTypeTest = {}
    }




    async updateStatisticsAfterAnswer(test, userFeedback, timeSpent, mcqAnswer) {
        /*
        Atualizamos os seguintes documentos *individuais* do usuário:
            
            users/${this.userID}/${this.testType}/statistics/assorted/nEachTypeTest
            Para cada tagpath, número total de testes e estado (learning vs lapsed)
            
            users/${this.userID}/${this.testType}/statistics/assorted/overallStatistics
            Para cada tagpath, tempo dedicado, número de testes respondidos, e tipo status da 
            resposta (i.e., se era teste novo ou não, e a dificuldade que teve)

            users/${this.userID}/${this.testType}/statistics/daily/${todayString}
            Igual a overallStatistics, mas individualizado para cada dia.

            users/${this.userID}/${this.testType}/tests/reviewed tests/${testID}
            Para este teste, guarda todas as respostas que o usuário já deu, e o estágio dele na
            repetição espaçada.

            users/${this.userID}/${this.testType}/tests/reviews_summary/**
            Indexação do formato { testID : dateNextReviewMs }

            users/${this.userID}/${this.testType}/lists/**
            A depender do tipo de teste e outras variáveis, atualizamos um conjunto de documentos
            que representam listas que possuem este teste, e precisam da resposta mais recente
            dada a ele.


        Ainda, atualizamos os seguintes documentos *coletivos*:

            users_statistics/${testType}/assorted/overall_statistics
            Igual overall statistics, mas coletivo.

            users_statistics/${testType}/daily/{todayString}
            Igual daily, mas coletivo.

            users_statistics/${testType}/daily_who/{todayString}
            Registro de todos os usuários ativos por dia, e quantos testes responderam.
        */

        // Evitamos que um teste seja atualizado mais de uma vez, como segurança.
        if (this.testsUpdated.includes(test.testID)) {
            console.log(`\tupdateStatisticsAfterAnswer(): error, tried to update ${test.testID} twice` )
            return
        }
        else {
            console.log(`\tupdateStatisticsAfterAnswer(): will update ${test.testID} statistics`)
            this.testsUpdated.push(test.testID)
        }
        
        this.batch = []
        this.nonNumericalBatches = []
        this.listsPromises = []
        this.todayAndOverallStatistics = {}
        this.nEachTypeTest = {}

        var gaveRightAnswer;
        if (this.testType === 'Residencia') {
            gaveRightAnswer = (mcqAnswer == test.answer)
        }


        const updateF = (this.testType === 'Flashcards') ? 
            this.testStatistics.updateFlashcardStatistics.bind(this.testStatistics) : 
            this.testStatistics.updateResidenciaStatistics.bind(this.testStatistics)
    
        // newType é new/learning/lapsed para cards
        // ou new/review para residencia
        const [newType, nextReviewMS, result] = updateF(
            test, userFeedback, 
            mcqAnswer, gaveRightAnswer)

        // Como é um dicionário para cada teste respondido, não adianta juntar de todos e 
        // mergeAndSum. Então, já guardamos aqui.
        this.nonNumericalBatches.push([
            db.doc(`users/${this.userID}/${this.testType}/tests/reviewed tests/${test.testID}`),
            result
        ])

        this.updateTodayAndOverallStatistics(test, userFeedback, timeSpent, gaveRightAnswer)
        this.updateNumEachTypeTest(test, newType, userFeedback)

        const promises = (this.testType == 'Residencia') ? 
            this.updateResidenciaTestLists(test, mcqAnswer, gaveRightAnswer) : 
            this.updateFlashcardTestLists(test, userFeedback)
        this.listsPromises.push(...promises)


        this.updateReviewsDocument(test.testID, nextReviewMS, userFeedback)
        
        
        await measureTime(() => this.saveDocuments(), `\t\t * updateStatisticsAfterAnswer(): updated ${this.nTestsUpdated} tests, ${test.testID} done`)

        this.nTestsUpdated++;
    }


    updateResidenciaTestLists(test, mcqAnswer, gaveRightAnswer) {
        const now = new Date()
        const answerLog = {
            dateAnswered: now.getTime(),
            alternativeChosen: mcqAnswer,
            gaveRightAnswer: gaveRightAnswer
        }
        const ID = test.testID

        let root = `users/${this.userID}/Residencia/lists`
        let listsDocsRefs = [
            `${root}/institutions_lists/${test.institution}`,
            `${root}/exams_lists/${test.institution}_${test.year}`,
        ]

        let tagpath = tagSequenceToPath(test.tags)
        let adjusted = tagpath.replaceAll('/', '|')
        listsDocsRefs.push(`${root}/tagpaths_lists/${adjusted}`)
        if (test.observations.includes('EXTENSIVO')) {
            listsDocsRefs.push(`${root}/extensivo_tagpaths_lists/${adjusted}`)
        }


        return this.updateDocumentList(listsDocsRefs, ID, answerLog)
    }


    updateFlashcardTestLists(test, levelOfSuccess) {
        const now = new Date()
        const answerLog = {
            dateAnswered: now.getTime(),
            levelOfSuccess: levelOfSuccess
        }
        const ID = test.testID

        let root = `users/${this.userID}/Flashcards/lists`
        let deckRoot = deckRootFromFlashcardID(ID)

        let listsDocsRefs = []
        listsDocsRefs.push(`${root}/deck_lists/${deckRoot}`)

        return this.updateDocumentList(listsDocsRefs, ID, answerLog)
    }


    updateDocumentList(listsDocsRefs, ID, answerLog) {    
        const promises = listsDocsRefs.map(tagpath => {
            return db.runTransaction(async transaction => {
                const docRef = db.doc(tagpath)
                const doc = await transaction.get(docRef)
    
                let updateData = {}
                if (!doc.exists || !doc.data()[ID]) {
                    // Define a primeira resposta se o documento ou a questão específica não existir
                    updateData[ID] = { first_answer: answerLog, last_answer: answerLog }
                } else {
                    // Atualiza a última resposta se o documento e a questão específica existirem
                    updateData[ID] = { last_answer: answerLog }
                }
    
                transaction.set(docRef, updateData, { merge: true })
            });
        });

        return promises
    }
    


    async saveDocuments() {
        // Para os dados de updateTodayAndOverallStatistics
        const today = getDateFormattedForFirebase( new Date() )

        const uRoot = `users/${this.userID}/${this.testType}/statistics`
        // const cRoot = `users_statistics/${this.testType}`

        const paths = [
            `${uRoot}/daily/${today}`,
            `${uRoot}/assorted/overallStatistics`,
            // `${cRoot}/daily/${today}`,
            // `${cRoot}/assorted/overallStatistics`
        ]

        // Para todos esses paths, precisamos aplicar this.todayAndOverallStatistics
        for (let path of paths) {
            this.batch.push([
                db.doc(path),
                this.todayAndOverallStatistics
            ])
        }

        // const dailyWho = {}
        // dailyWho[this.userID] = this.nTotalTestsAnswered
        // this.batch.push([
        //     db.doc(`${cRoot}/daily_who/${today}`),
        //     dailyWho
        // ])

        // if (this.testStatistics.testType === 'Flashcards') {
            this.batch.push([
                db.doc(`${uRoot}/assorted/nEachTypeTest`),
                this.nEachTypeTest
            ])
        // }

        //  1. Transformar os números de todos os dados em increment
        //  2. Transformar tudo que está nos batches em promises
        //  3. Juntamos com as promises das transactions
        //  4. Profit


        const writePromises = this.batch.map(([docRef, dataToWrite]) => {
            const modifiedData = {};

            for (let tagpath in dataToWrite) {
                modifiedData[tagpath] = {}

                for (const [key, value] of Object.entries(dataToWrite[tagpath])) {
                    console.log(key)
                    console.log(value)
                    modifiedData[tagpath][key] = firebase.firestore.FieldValue.increment(value);
                }
            }
            
            return docRef.set(modifiedData, { merge: true });
        })


        // Por ora, é só para o documento das estatísticas de *cada* teste, que é não
        // numérico, e não faz sentido usar o increment
        const nonNumericalPromises = this.nonNumericalBatches.map(([docRef, dataToWrite]) => {
            return docRef.set(dataToWrite, { merge: true });
        })


        console.log(`Writes: ${writePromises.length}`)
        console.log(`Transactions: ${this.listsPromises.length}`)

        const promises = [
            ...this.listsPromises,
            ...writePromises,
            ...nonNumericalPromises
        ]

        await Promise.all(promises)
    }


    async updateReviewsDocument(testID, nextReviewMS, userFeedback) {
        console.log('Updating reviews document!!!!')


        const docName = hashTestID(testID)

        console.log(docName)

        const data = {}

        if (this.testType === 'Flashcards' || userFeedback !== 'rightConfident') {
            data[testID] = nextReviewMS
        }
        else {
            data[testID] = firebase.firestore.FieldValue.delete()

            // Precisamos adicionar em solved_questions
            const solvedName = hashTestIDToSolved(testID)
            const solvedDoc = db.doc(`users/${this.userID}/${this.testType}/tests/solved_questions/${solvedName}`)

            this.nonNumericalBatches.push([
                solvedDoc,
                { [testID] : true }
            ])
        }


        const docPath = `/users/${this.userID}/${this.testType}/tests/reviews_indexed/${docName}`
        this.nonNumericalBatches.push([
            db.doc(docPath),
            data
        ])
    }





    // getDocName(testID) {
    //     let doc_id_selector = 0;

    //     if (this.testType === 'Flashcards') {
    //         const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

    //         const parts = testID.split('_')

    //         var unidecode = require('unidecode')
    //         const root = unidecode(parts[1])

    //         const letter = root[0].toLowerCase()

    //         doc_id_selector = letters.indexOf(letter) % 2
    //     }
    //     else if (this.testType === 'Residencia') {
    //         // No geral, é da forma: residencia_UFF_2021_R1_Q33
    //         // Mas algumas instituições tiveram mais de uma prova no ano, aí oé do formato: 2020-2
    //         let year = testID.split("_")[2]

    //         if ( year.includes("-") ) {
    //             year = year.slice(0, 4)
    //         }

    //         year = parseInt(year)

    //         doc_id_selector = year % 2
    //     }

    //     if (doc_id_selector == 0) {
    //         return 'ms_per_id_1'
    //     }
    //     else {
    //         return 'ms_per_id_2'
    //     }

    // }


    updateTodayAndOverallStatistics(test, levelOfSuccess, timeSpent, gaveRightAnswer = false) {
        // Os documentos daily e overallStatistics possuem a mesma estrutura, 
        // a única diferença é que um é do dia, e outro de todo o histórico do usuário
        //
        // Além disso, eles também existem na estatística coletiva. Todos esses documentos precisam
        // ser incrementados pela mesma estrutura de dados, que prepararemos aqui.
        // Atualizaremos tudo aqui.

        // type é new, learning, ou lapsed
        const type = test.statistics.type
        const los  = this.getLevelOfSuccessDescriptor(levelOfSuccess)

        // Registramos informações gerais da resposta. 
        //
        // Primeiro, se o tipo de teste e o grau de confiança, através de 
        // uma key do formato "newAgain", "learningGood", etc.
        //
        // Segundo, o número total de testes respondidos, e o tempo total
        // dedicado.
        //
        // Terceiro, quando MCQ, se o usuário acertou ou não.
        var data = {}
        data['timeSpent'] = timeSpent
        data['nTestsAnswered'] = 1

        
        if (this.testType === 'Flashcards') {
            data[`${type}${los}`] = 1            
        }
        else {
            const isNew = test.statistics.lastReview != undefined
            const capitalized = levelOfSuccess.charAt(0).toUpperCase() + levelOfSuccess.slice(1);
            const residType = `${isNew ? 'review' : 'new'}${capitalized}`

            data[residType] = 1
        }

        // Iremos aplicar a esturura de dado a todas as tag paths
        const dataIntoEachTagPath = this.createDictOfTagPaths(test.tags, data)

        this.todayAndOverallStatistics = this.mergeAndSumDictionaries(
            this.todayAndOverallStatistics,
            dataIntoEachTagPath
        )



        this.nTotalTestsAnswered += 1

    }


    // getResidenciaType(test, levelOfSuccess) {
        // const isNew = test.statistics.lastReview != undefined
        // const capitalized = levelOfSuccess.charAt(0).toUpperCase() + levelOfSuccess.slice(1);
        // const type = `${isNew ? 'review' : 'new'}${capitalized}`

        // return type
    // }


    getLevelOfSuccessDescriptor(levelOfSuccess) {
        switch(levelOfSuccess) {
            case 0 : return 'Again';
            case 1 : return 'Hard';
            case 2 : return 'Good';
            case 3 : return 'Easy'
        }
    }


    createDictOfTagPaths(tags, value, includeTotal = true) {
        // value pode ser um subdicionário (e.g., mostrar os descritivos
        // de todos os testes realizados do tema) ou um incremento (e.g., 
        // aumentar o registro do tempo dedicado ao tema).
        let map = {};

        let tagCombinations = tagSequenceToPathS(tags)

        if (includeTotal) {
            tagCombinations.push("Total")
        }

        for (let combination of tagCombinations) {
            map[combination] = value
        }
    
        return map;
    }


    mergeAndSumDictionaries(dict1, dict2) {
        let result = {};
      
        // Adicionando os pares do primeiro dicionário
        for (let key in dict1) {
          result[key] = dict1[key];
        }
      
        // Somando os valores do segundo dicionário
        for (let key in dict2) {
          if (result.hasOwnProperty(key)) {
            result[key] += dict2[key];
          } else {
            result[key] = dict2[key];
          }
        }
      
        return result;
    }
    

    updateNumEachTypeTest(test, newType, levelOfSuccess) {
        // previousType e newType são um de: new, learning, ou lapsed
        // se mudou, precisamos ajustar de acordo no documento que registra essa distribuição
        let data = {}
        if (this.testType === 'Flashcards') {
            const previousType = test.statistics.type
            if (previousType !== newType) {    
                // Não queremos new : -1, não é mesmo? ;)
                if (previousType !== 'new') {
                    data[previousType] = -1
                }
    
                data[newType] = 1
    
            }
        } else {
            // residencia
            data[levelOfSuccess] = 1
        }   

        var dataIntoEachTagPath = this.createDictOfTagPaths(test.tags, data)    
        this.nEachTypeTest = this.mergeAndSumDictionaries(
            this.nEachTypeTest,
            dataIntoEachTagPath
        )        
    }
}

export default UserStatisticsManager
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FlashcardsIcon from './../assets/flashcard.png'
import ResidenciaIcon from './../assets/residencia.png'
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'
import ExtensivoCard from './ExtensivoCard'




const AboutImg = styled.img`
  height: 2.2em;
  margin-right: 0.5em;
`

const AboutImg2 = styled.img`
  height: 1.8em;
  margin-right: 0.5em;
`


const TrackWelcome = styled.p`
  font-size: 1.4em !important;
  margin: 0 0 0.5em 0 !important;
  color: #a10d26;
  font-weight: bold;
  font-family: 'Lora';

  ${props => props.theme.darkMode ? `
  color: rgba(255, 71, 87, 0.8);;
  `: `
  color: #a10d26;
  `}

`

const List = styled.ul`
  margin-top: 0em;
  font-size: 0.8em;

  @media (max-width: 500px) {
    font-size: 1em;
   }
`

const Item = styled.li`
  margin-bottom: 0.5em !important;

  @media (max-width: 500px) {
    margin-bottom: 1em !important;
   }

`


export default function ExtensivoHelp({show}) {


    if (!show) return null

    return (
            <ExtensivoCard>
                <h1>Bem-vindo(a) ao <em>Extensivo</em>!</h1>

                <List>
                    <Item>
                        <b>Deixe o cronograma conosco!</b> Até o final do ano, garantimos que você aprenderá os temas prevalentes nas provas, e organizaremos suas revisões. Além disso, ajudaremos a guiar seus estudos mostrando estatísticas pessoais e dos demais alunos.
                    </Item>

                    <Item>
                        <b>Os temas serão liberados <u>semanalmente</u>.</b> Ainda, faremos uma sugestão de quais temas estudar quais dias, tentando equilibrar o máximo possível.
                    </Item>

                    <Item>
                        <AboutImg src={FlashcardsIcon} /> <strong>Primeiro, faça os flashcards.</strong> Eles irão prover a base teórica para você ter domínio sobre a matéria.
                    </Item>

                    <Item>
                        <AboutImg src={ResidenciaIcon} /><strong>Depois, faça as questões.</strong> São selecionadas a dedo para maximizar seu aprendizado, considerando as instituições mais concorridas e a qualidade das questões, e comentamos com carinho.
                    </Item>

                    <Item>
                        <AboutImg2 src={Notebook} /><b>Favoreça os testes novos.</b> Fala-se cada vez mais de revisão, e isso é muito bom: um bom esquema de revisão tem mais impacto que 160 de QI. Porém, é mais importante ver todos os assuntos pelo menos uma vez, do que manter as revisões perfeitamente em dia.
                    </Item>

                    <Item>
                        <AboutImg2 src={Dumbbell} /><b>Não deixe as revisões acumularem.</b> O melhor modo de manter as revisões em dia é estudar todos os dias, com consistência.
                    </Item>

                    <Item>
                        Os flashcards são importantes para aprender e <u>lembrar</u>. As questões são importantes para mensurar seu desempenho.
                    </Item>

                    <Item>
                        A concorrência é enorme, a vontade de passar é grande, e somos continuamente explorados por publicidades agressivas. Não se deixe levar. Estude todos os dias, e lembre que o mais importante não é passar, mas sim ser feliz e ajudar seus pacientes.
                    </Item>
                </List>

                <p>
                    <strong>Críticas ou sugestões?</strong> Fale com nossa equipe de suporte ao cliente por email (suporte@osler-ensino.com), ou diretamente conosco via DM.
                </p>

                <p>
                    <strong>Esperamos que você goste!</strong> A gente se dedica <u>muito</u> ao conteúdo, e esse ano investiremos ainda mais nele e na plataforma.
                </p>
            </ExtensivoCard>
    );
}
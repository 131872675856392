import React from 'react'
import { auth } from './../firebase/firebase-setup'
import { store } from './../redux/store'
import { logOut } from './../redux/userSlice'

import OslerButton from '../components/OslerButton'

export default function Quit() {

    function signOut() {
        auth.signOut()
        store.dispatch(logOut())

        // Poderíamos enviar o usuário para a tela inicial do site ou para a página
        // de login. Enviar diretamente para o login é complexo, pois podemos ter
        // o state antigo (lembre que o dispatch pode demorar).
        //
        // Como há garantia do listener de app ser chamado, nós só esperamos.
        console.log("signOut(): erased state, going to loading user")
    }


    return (
        <>
        <p>Clique no botão abaixo para fazer logout.</p> 

            <OslerButton
                onClick={signOut}
                text='Sair'
                icon='👋'
                bgColor='gray'
                style = {{marginBottom: '1em'}} />
        </>
    )
}

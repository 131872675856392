import { db } from './../firebase/firebase-setup'
import session  from './Session'
import { shuffleArray, isClozeCard, getFlashcardDeckRoot } from '../utils/Utils';
import getDoc from '../firebase/firebaseDocs';
import { getLastSessionPath } from '../utils/SessionUtils';
import LikedBuriedController from './LikedBuriedController';
    


class SessionBuilder {
    prepare(user, userInfo, testsPerTag) {
        this.userID = user.id;
        this.userReviewsInfo = userInfo.info
        
        this.allInfo = userInfo
        this.testsPerTag = testsPerTag
        
        this.buriedIDs = JSON.parse(JSON.stringify(LikedBuriedController.buried))
       
        // Não são array de IDs, mas um dict da forma { ID : True }
        this.anuladasIDs = userInfo.anuladasIDs
        this.comentadasIDs = userInfo.comentadas
        this.extensivoIDs = userInfo.extensivo
    }


    /*
        AS ÚNICAS SESSÕES QUE DEVEM SER CHAMADAS PUBLICAMENTE SÃO
        start() & simulate().

        Idealmente, colocar o restomo como private.
    */

    async simulate(testType, selectedOption, mode, listIDsOrPaths, filters) {
        // A única diferença em relação a start() é que
        // não inicializa uma sessão, mas retorna uma lista de IDs.
        const listIDs = await this.load(testType, selectedOption, mode, listIDsOrPaths, filters)

        return listIDs
    }


    async start(testType, selectedOption, mode, shouldSaveAsLastSession, listIDsOrPaths, filters = undefined, downloadStatistics = true) {
        /*
            O objetivo deste método é construir uma Session para o usuário, em 
            função dos parâmetros recebidos.

            A criação das Sessions deve ser centralizada aqui, para evitarmos bugs,
            e esse deve ser o único método público.

            selectedOption é o modo como a Session será carregada. As opções são 
            only_reviews, random_subject, last_session, predefined, ou custom.

            mode é test, consult, ou playground

            listIDsOrPaths, como o nome sugere, é uma lista de testIDs (para
            o tipo predefined) ou de tagpaths (para o tipo custom)

            filtros é algo específico ao custom
        */

        const listIDs = await this.load(testType, selectedOption, mode, listIDsOrPaths, filters)

        session.start(this.userID, testType, listIDs, shouldSaveAsLastSession, mode, downloadStatistics)
    }


    /*
        MÉTODOS (QUE DEVERIAM SER) PRIVADOS
    */

    async load(testType, selectedOption, mode, listIDsOrPaths, filters) {
        let listIDs = [];

        // console.log(listIDsOrPaths)

        switch (selectedOption) {
            // Depois de > 10 anos de programaçõ, descubro que sou um idiota, e deveria ter
            // prestado mais atenção no livro de C: não esqueça o break, ou rodará o caso seguinte.
            case 'only_reviews': 
                listIDs = this.buildReviewsOnlySession(testType)
                break;

            case 'random_subject':
                listIDs = this.buildRandomSubjectSession(testType)
                break;

            case 'custom':
                listIDs = await this.buildCustomSession(testType, mode, listIDsOrPaths, filters)
                break;
            
            case 'last_session':
                listIDs = await this.buildLastSession(testType)
                break;

            case 'predefined':
                listIDs = this.buildPredefinedSession(listIDsOrPaths, filters)
                break;

            default : 
                console.log("SessionBuilder - start(): error - " + selectedOption)
        }

        return listIDs
    }


    

    
    buildReviewsOnlySession(testType) {
        // Criamos uma sessão com todas as revisões pendentes do usuário.
        //
        // Não removemos as future reviews porque... isso já foi feito, 
        // em teoria são só as pending.
        let listIDs = this.userReviewsInfo[testType].pendingReviews
        listIDs = this.shuffle(listIDs)
        listIDs = this.removeBuried(testType, listIDs)

        // Eu cogitei remover as anuladas daqui, mas aí o usuário.
        // nunca conseguiria resolver as questões de revisão pendentes
        // se elas incluíssem anuladas, então não parece muito sábio.


        return listIDs
    }



    buildRandomSubjectSession(testType) {
        // O objeto this.testsPerTagForReviewType é um dicionário onde
        // cada chave é um dos tagsPath que possuímos, e o valor correspondente
        // é um array com todos os testes desse tagsPath.
        //
        // Então, basta eu selecionar uma chave específica, puxar esses testes,
        // ver se há testes NUNCA feitos a respeito do tema.
        let paths = shuffleArray( Object.keys(this.testsPerTag[testType]) ) 

        let testIDs = []
        for (let path of paths) {
            if (!this.allInfo.reviewsPerPath[testType][path]) {



                testIDs = this.findTestsForTagPaths(testType, [path])
                
                testIDs = this.removeBuried(testType, testIDs)
                testIDs = this.removeFutureReviews(testType, testIDs)
                
                if (testType === 'Residencia') {
                    testIDs = this.removeAnuladas(testIDs)
                }
        
        
                testIDs = this.sortByID(testIDs)

                if (testType === 'Flashcards') {
                    testIDs = this.detachCousins(testIDs)
                }
        
                return testIDs

            }
        }

        return []
    }



    removeBuried(testType, listIDs) {
        const buriedRemoved = []

        const filtered = listIDs.filter(x => {
            if ( this.buriedIDs[testType].includes(x) ) {
                buriedRemoved.push(x)
                return false
            } 
            else {
                return true
            }
        })

        return filtered
    }


    async buildCustomSession(testType, mode, listOfTagsPaths, filters) {
        // const [listOfTagsPaths, consultMode, filters] = params

        let testIDs = this.findTestsForTagPaths(testType, listOfTagsPaths  )

        if (testType === 'Residencia') {
            if (filters) { 
                testIDs = this.filterResidenciaByYears(testIDs, filters.years)
                testIDs = await this.filterResidenciaByInstitutions(testIDs, filters.institutions)
            }
            
            // Atenção à negativa.
            
            if (! (filters && filters.others && filters.others.includes('include_anuladas')) ) {
                // console.log('SessionBuilder: removendo anuladas...')   
                testIDs = this.removeAnuladas(testIDs)
            }

            if (filters && filters.others && filters.others.includes('only_commented')) {   
                // console.log('SessionBuilder: selecionando só questões comentadas...')  
                testIDs = this.reduceToComentadas(testIDs)
            }

            if (filters && filters.others && filters.others.includes('only_extensivo')) {   
                // console.log('SessionBuilder: selecionando só questões comentadas...')  
                testIDs = this.reduceToExtensivo(testIDs)
            }
        }

        if (!(mode == 'consult-mode') && filters && filters.others && filters.others.length > 0) {
            if (filters && filters.others.includes('only_reviews')) {
                testIDs = this.customToOnlyReviews(testType, testIDs)
            }
            else if (filters && filters.others.includes('only_news')) {
                testIDs = this.customToOnlyNewTests(testType, testIDs)
            }
            else {
                testIDs = this.removeFutureReviews(testType, testIDs)
            }

            if (filters && filters.others.includes('randomize')) {
                testIDs = this.shuffle(testIDs)
            }
        }

        if (testType === 'Flashcards') {
            testIDs = this.sortByID(testIDs)
        }
        else {
            testIDs = this.shuffle(testIDs)
        }

        if (!(mode == 'consult-mode' || mode == 'playground-mode')) {
            testIDs = this.removeBuried(testType, testIDs)

            // De nota, não afeta ser only_reviews ou only_news no filtro
            // acima, pelo contrário, é necessário. Reflita.
            testIDs = this.removeFutureReviews(testType, testIDs)

            // TODO TO DO Não deveríamos fazer no playground?? 
            if (testType === 'Flashcards') {
                testIDs = this.detachCousins(testIDs)
            }
        }

        
        return testIDs
    }



    filterResidenciaByYears(testIDs, selectedYears) {
        if (selectedYears && selectedYears.length > 0) {
            return testIDs.filter(ID => {
                let test_year = ID.split("_")[2]
                return selectedYears.includes(test_year)
            })
        }
        else {
            return testIDs
        }
    }



    async filterResidenciaByInstitutions(testIDs, selectedInstitutions) {
        if (selectedInstitutions && selectedInstitutions.length > 0) {
            // Como envolve um get(), garantimos que é necessário antes.
            const doc_ref = db.doc(`/metadata/Residencia/summary/institutions_IDs`)
            const doc = await getDoc( doc_ref )

            const selectedInstitutionsIDs = selectedInstitutions.map(institution => doc[institution])

            return testIDs.filter(ID => {
                let test_institution = ID.split("_")[1]

                return selectedInstitutionsIDs.includes(test_institution)
            })
        }
        else {
            return testIDs
        }
    }



    removeAnuladas(listIDs) {
        const anuladas = []

        const filtered = listIDs.filter(x => {
            if ( this.anuladasIDs[x] ) {
                anuladas.push(x)
                return false
            } 
            else {
                return true
            }
        })

        return filtered
    }



    reduceToComentadas(listIDs) {
        return listIDs.filter(x => {
            return this.comentadasIDs[x]
        })
    }


    reduceToExtensivo(listIDs) {
        return listIDs.filter(x => {
            return this.extensivoIDs[x]
        })
    }



    customToOnlyReviews(testType, testIDs) {
        return testIDs.filter(ID => {
            return this.userReviewsInfo[testType].pendingReviews.includes(ID)
        })
    }



    customToOnlyNewTests(testType, testIDs) {
        if (testType === 'Residencia') {
            // console.log(this.allInfo.residenciaSolved)
            
            return testIDs.filter(ID => {
                const isSolved = this.allInfo.residenciaSolved[ID]
                const isPendingReview = this.userReviewsInfo[testType].pendingReviews.includes(ID)

                // console.log(`${ID} - ${isSolved} - ${isPendingReview}`)

                return !isSolved && !isPendingReview
            })

        }
        else if (testType === 'Flashcards') {
            return testIDs.filter(ID => {
                return !this.userReviewsInfo[testType].pendingReviews.includes(ID)
            })
        }
        else {
            return []
        }

    }



    findTestsForTagPaths(testType, listOfTagsPaths) {
        const dict = this.testsPerTag[testType];
        let testIDs = []

        for (let path of listOfTagsPaths) {

            let tests_array = []
            tests_array = dict[path]

            if (tests_array) {
                testIDs.push(...tests_array)
            }
        }   
        return testIDs
    }



    removeFutureReviews(testType, listIDs) {
        const futureReviews = []

        const filtered = listIDs.filter(x => {
            if ( this.userReviewsInfo[testType].futureReviews.includes(x) ) {
                futureReviews.push(x)
                return false
            } 
            else {
                return true
            }
        })

        return filtered
    }


    async buildLastSession(testType) {
        const lastSessionDoc = await db.doc(getLastSessionPath(this.userID)).get()

        let lastSessionIDs = []
        if (lastSessionDoc.exists && lastSessionDoc.data()[testType]) {
            lastSessionIDs = lastSessionDoc.data()[testType]
        }

        // As buried, future reviews, e questões anuladas já foram removida durante a 
        // criação da Sessão que foi salva. Então, não precisamos fazer isso novamente.


        // TODO Se for igual a zero, deveríamos excluir a lastSession atual, não?
        return lastSessionIDs
    }



    buildPredefinedSession(listTestIDs, filters = []) {
        if (!filters) {
            return listTestIDs
        }

        if (filters.includes('sort')) {
            listTestIDs = this.sortByID(listTestIDs)
        }

        if (filters.includes('detach_clozes')) {
            // listTestIDs pode ter testes de diferentes decks e a ideai pode ser manter a 
            // ordenação -- inclusive conforme construída acima por sortByID --
            // mostrando um deck de cada vez
            //
            // mas detachCousins separa os clozes ao longo de todos os IDs
            //
            // uma solução é separar deck a deck, e depois concatenar
            const testIDsByDeck = {};

            listTestIDs.forEach(testID => {
                const deckName = getFlashcardDeckRoot(testID)
                if (!testIDsByDeck[deckName]) {
                    testIDsByDeck[deckName] = []
                }
                testIDsByDeck[deckName].push(testID)
            })

            const detachedTestIDs = []

            for (const deckName in testIDsByDeck) {
                if (testIDsByDeck.hasOwnProperty(deckName)) {
                    const deckTestIDs = testIDsByDeck[deckName]
                    const detachedDeckTestIDs = this.detachCousins(deckTestIDs)
                    detachedTestIDs.push(...detachedDeckTestIDs)
                }
            }

            // Atualizar listTestIDs com os testIDs com os clozes separados
            listTestIDs = detachedTestIDs;

            
        }
        
        if (filters.includes('shuffle')) {
            listTestIDs = this.shuffle(listTestIDs)
        }

        return listTestIDs
    }


    
    // Métodos de ordenamento
    shuffle(listIDs) {
        return shuffleArray(listIDs)
    }



    sortByID(listIDs) {
        let sorted = listIDs.sort((t1, t2) => {
            // Precisamos tomar cuidado para ele não ordenar 100 antes do 10,
            // o que ocorre se compararmos strings.
            //      flashcards_cardiopatiasCongenitas_11 ---> queremos extrair o 11
            //
            // Porém, os conteudistas podem colocar uma ou mais letras após o número
            // para criar cards intermediários
            //      flashcards_violênciaSexualAbortoInduzido_00
            //      flashcards_violênciaSexualAbortoInduzido_00b
            //      flashcards_violênciaSexualAbortoInduzido_00c
            //      flashcards_violênciaSexualAbortoInduzido_01
            const testNumber1 = this.extractTestNumberFromID(t1);
            const testNumber2 = this.extractTestNumberFromID(t2);

            // Separar o número e as letras usando uma expressão regular
            const [num1, letters1] = testNumber1.match(/(\d+)(\D*)/).slice(1);
            const [num2, letters2] = testNumber2.match(/(\d+)(\D*)/).slice(1);

            // Comparar primeiro os números
            const n1 = parseInt(num1);
            const n2 = parseInt(num2);

            if (n1 < n2) {
                return -1;
            } else if (n1 > n2) {
                return 1;
            } else {
                // Se os números forem iguais, comparar as letras
                if (letters1 < letters2) {
                    return -1;
                } else if (letters1 > letters2) {
                    return 1;
                } else {
                    // Se os números e as letras forem iguais, comparar o número do cartão cloze
                    const m1 = parseInt(t1.split('_clz')[1]);
                    const m2 = parseInt(t2.split('_clz')[1]);

                    return m1 < m2 ? -1 : 1;
                }
            }
        });

        return sorted;
    }


    extractTestNumberFromID(ID) {
        // Se é cloze, será a penultima coisa antes do último "_"
        // Senão, será a última.
        // Ainda, atenção:
        // alguns cards -- ANTIGOS -- tem um "_" a mais:
        //  e.g., flashcards_glomerulonefritePós-estreptocócica(gnpe)_extensivo_32_clz2
        // mais uma razão para irmos de trás para frente

        const parts = ID.split("_")
        if (isClozeCard(ID)) {
            return parts[parts.length - 2]
        }
        else {
            return parts[parts.length - 1]
        }
    }


    detachCousins(listIDs) {
        /*
            Responder clozes de uma mesma frase em sequência é chato (pois 
            repetitivo) e infeficaz (você se apoia na memória de curtíssima
            duração, pois acabou de ler).
            
            Clozes de uma mesma frase são ditos "cousins" de uma mesma
            "family".

            A estratégia é colocar outros flashcards entre os cousins, reordenando
            os elementos de listIDs antes de baixar os testes.

            Corremos o array sequencialmente. Quando encontramos qualquer cloze,
            se houver cousins (pode ser um cloze único!), eles estarão adjacentes.

            Nós pegamos esses cousins e distribuímos entre os flashcards restantes.

            E de modo "recursivo" seguimos para o resto do array, procurando uma
            nova family.

            Registramos as famílias que já foram processadas para quando reencontrarmos
            o cousin.
        */
       console.log('\tdetaching cousins!!!')
        let listIDs_copy = [...listIDs]

                
        for(let i = 0; i < listIDs_copy.length; i++) {
            const currentCard = listIDs_copy[i]

            // Só há sentido reordenar se é um cloze.
            if ( isClozeCard(currentCard) ) {
                // console.log('\n\n')
                // console.log(`${currentCard} is a cloze card`)

                // Os IDs são da forma "flashcards_descritivo_03_clzX", onde
                // X é um número específico para cada cloze/cousin.
                //
                // Então, a família é indicada por "flashcards_descritivo_03".
                const family = this.getCardFamily( currentCard )
                
                let cousins = []                
                cousins.push(currentCard)

                for (let j = i + 1; j < listIDs_copy.length; j++) {
                    const adjacentCard = listIDs_copy[j]
                    const adjacentFamily = this.getCardFamily( adjacentCard )

                    if (family === adjacentFamily) {
                        cousins.push(adjacentCard)
                    }
                    else {
                        break;
                    }
                } 


                // Reunimos todos os cousins: grupo de cards, incluído o prório,
                // que são clozes de uma mesma frase.
                // 
                // Mas veja que só os reunimos se eles estava madjacentes!!
                //
                // Agora, vamos torná-los distantes entre si. O que... só tem
                // sentido se há mais de um cousin.
                if (cousins.length > 1) {

                    /*
                        Separamos em três partes.
                            1. Flashcards antes de encontrarmos o primeiro cousin. Usaremos
                            como base para adicionar os novos elementos em cima, em nova ordem.

                            2. Cousins da família.

                            3. Flashcards restantes, que serão os separadores. Podemos
                            fazer um slice porque **presumimos que todos os primos estavam
                            um do lado do outro, sem separadores**.
                    */

                    const previousCards = listIDs_copy.slice(0, i)
                    const separators = listIDs_copy.slice(i + cousins.length)

                    
                    // console.log(previousCards)
                    // console.log(cousins)
                    // console.log(separators)

                    /*
                        Temos M separadores e N cousins (excluindo o atual).
                        Seja R = M % N.

                        O intervalo mínimo entre os cards será de I separadores,
                            I = (M - R) / N
                        Mas ainda poderemos aumentar alguns intervalos, de modo a consumir
                        R.
                    */
                    const M = separators.length
                    const N = cousins.length - 1

                    let R = M % N
                    const I = (M - R) / N

                    for (let cousin of cousins) {
                        previousCards.push(cousin)

                        let intervalSize = I;
                        if (R > 0) {
                            R--;
                            intervalSize++;
                        }

                        if (separators.length > 1) {
                            previousCards.push( ...separators.splice(0, intervalSize) )
                        }
                    }

                    // console.log(previousCards)
                    // console.log('\n\n')
                    listIDs_copy = previousCards
                }
            }
        }

        return listIDs_copy
    }


    getCardFamily(ID) {
        const familyEnd = ID.indexOf('_clz')

        if (familyEnd === -1) {
            // Só irá ocorrer se não for um cartão do tipo cloze.
            return undefined
        }
        else {
            return ID.slice(0, familyEnd)
        }
    }


    checkIfCousins(ID1, ID2) {
        return this.getCardFamily(ID1) === this.getCardFamily(ID2)
    }


}


export default new SessionBuilder()
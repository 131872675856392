import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AppContainer from '../app-container/AppContainer';
import { db } from '../firebase/firebase-setup';
import session from './../controllers/Session';
import sessionBuilder from './../controllers/SessionBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from './../redux/loadingSlice';
import { toastMsg } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';
import IsLoading from '../main/IsLoading';
import UserReviewsInfo from '../controllers/UserReviewsInfo';
import CloseIcon from './../assets/x-icon.png';
import QuestionIcon2 from './../assets/question.png';
import QuestionIconDark from './../assets/question dark.png'
import RoadmapIcon from './../assets/roadmap.png';


import { guaranteeSubscription } from '../firebase/firebaseUtils';
import ExtensivoModule from './ExtensivoModule';
import KeyIcon from './../assets/extensivo.png';
import HydrantIcon from './../assets/hydrant.png';
import ScalpelIcon from './../assets/scalpel.png'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles';
import ExtensivoHelp from './ExtensivoHelp';
import ExtensivoReviews from './ExtensivoReviews';
import ExtensivoSchedule from './ExtensivoSchedule';
import { RowCSS } from '../components/BasicComponents';
import ScheduleButton from './ScheduleButton';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    

    ${backgroundWhiteBlack}

    width: 80%;
    min-height: 100vh;
    padding-top: 2em;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        // Parece bobo, mas senão fica muito apertado
        width: 98%;
    }


    
`


// Estar exportando para usar na teal dos embaixadores é uma putaria, deveria ser um componente
// próprio
export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    margin-bottom: 2em;
    box-sizing: border-box;

    @media (max-width: 900px) {
        width: 90%;
    }

    @media (max-width: 500px) {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const Title = styled.div`
    width: 100%;

    background-color: ${props => props.theme.darkMode ? 'rgba(255, 71, 87, 0.8);' : '#a10d26;'};

    color: white;
    padding: 1em;
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`


const Bttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    padding-left: 0em;
    margin-top: 0.5em;
`


const Bttn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // background-color: yellow;

    margin-right: 0.1em;

    height: 2.5em;
    padding: 0 0.75em 0 0.75em;

    cursor: pointer;
    border-radius: 1em;

    &:hover {
        background-color: rgba(230, 230, 230);
    }

    img {
        height: 1.4em;
    }

    @media (max-width: 500px) {

    }   
`



export const Text = styled.p`
    padding: 0;
    margin: 0;
    font-size: 2em;
    text-align: center;
    font-family: Lora;
`

export const Icon = styled.img`
    margin-right: 1em;
    height: 2em;
`





export default function AcademicTrackMain(props) {
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkModeOn)

    const [modules, setModules] = useState(false)
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [selectedSchedule, setSchedule] = useState('extensivo')
    const schedules = useRef({})


    const dispatch = useDispatch()
    let navigate = useNavigate();


    useEffect(() => {
        console.log("AcademicTrackScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])


    useEffect(() => {
        async function start() {
            await loadData()

            // TODO 
            // No futuro, fazer o default do usuário
            changeSchedule('extensivo')
        }

        start()
    }, [])


    async function loadData() {
        if (!UserReviewsInfo.isReady()) {
            console.log("AcademicTrackMain(): exiting to /app because data not ready")
            navigate('/app')
        }
        else {
            // Só navegar não adianta, não garante interrupção da execução da função
            let promises = [
                db.collection('cronogramas').doc('extensivo').get(),
                db.collection('cronogramas').doc('semi_extensivo').get(),
                db.collection('cronogramas').doc('intensivao').get()
            ]
    
            let documents = await Promise.all(promises)
    
            // Invertemos a ordem, porque queremos o mais recente 
            // (último do cronograma) no topo.
            schedules.current['extensivo']      = documents[0].data()['modules'].reverse()
            schedules.current['semi_extensivo'] = documents[1].data()['modules'].reverse()
            schedules.current['intensivao'] = documents[2].data()['modules'].reverse()
    
            for (let schedule in schedules.current) {
                for (let module of schedules.current[schedule]) {
                    for (let step of module.steps) {
                        for (let activity of step.activities) {
                            await fetchActivityTests(activity, 'Flashcards')
                            await fetchActivityTests(activity, 'Residencia')
                        }
                    }
                }
            }
            
    
            setModules(schedules.current[selectedSchedule])
            setLoadedInfo(true)

        }
    }



    async function fetchActivityTests(activity, type) {
        const tagpaths = activity[type.toLowerCase() + '_tagpaths'];

        if (tagpaths && tagpaths.length > 0) {
            const newTests = await fetchTests(tagpaths, type, { others: ['only_news'] })
            const reviewTests = await fetchTests(tagpaths, type, { others: ['only_reviews'] })
            activity['new' + type] = newTests
            activity['review' + type] = reviewTests
        }
    }


    async function fetchTests(tagpaths, type, filters) {
        if (type == 'Residencia') {
            filters['others'].push('only_extensivo')
        }

        return await sessionBuilder.simulate(
            type,
            'custom',
            'test-mode',
            tagpaths,
            filters
        );
    }


    function changeSchedule(which_schedule) {
        setModules(schedules.current[which_schedule])
        setSchedule(which_schedule)
    }



    async function startTestModeSession(testType, testIDs, ordered) {
        dispatch(setIsLoading(true))

        const filter = (ordered ? ['sort', 'detach_clozes'] : ['shuffle'])

        await sessionBuilder.start(
            testType,
            'predefined',
            'test-mode',
            true,
            testIDs,
            filter
        )

        if (session.sessionSize > 0) {
            session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    async function startViewModeSession(testType, tagpaths, whichMode) {
        await sessionBuilder.start(
            testType,
            'custom',
            whichMode,
            false,
            tagpaths,
            
        )


        if (session.sessionSize > 0) {
            session.addTemporaryListener(whichMode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/question")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }


    return (
        <AppContainer>
            <Container>
                {!loadedInfo && <IsLoading />}
                {loadedInfo && (
                    <>
                        <Header>
                            <Title>
                                <Icon src={KeyIcon} />
                                <Text>CRONOGRAMA</Text>
                            </Title>
                            
                            <Bttns> 
                                <Bttn onClick={() => setShowHelp(!showHelp)}>
                                    <img src={showHelp ? CloseIcon : (darkMode ? QuestionIconDark : QuestionIcon2)} />
                                </Bttn>

                                <Bttn onClick={() => setShowSchedule(!showSchedule)}>
                                    <img src={showSchedule ? CloseIcon : RoadmapIcon} />
                                </Bttn>

                                <ScheduleButton
                                    icon = {KeyIcon}
                                    text = {'Extensivo'}
                                    buttonAction = {() => changeSchedule('extensivo')}
                                    clicked = { selectedSchedule == 'extensivo' } />

                                <ScheduleButton
                                    icon = {HydrantIcon}
                                    text = {'Semi-Extensivo'}
                                    buttonAction = {() => changeSchedule('semi_extensivo')}
                                    clicked = { selectedSchedule == 'semi_extensivo' } />


                                <ScheduleButton
                                    icon = {ScalpelIcon}
                                    text = {'Intensivão'}
                                    buttonAction = {() => changeSchedule('intensivao')}
                                    clicked = { selectedSchedule == 'intensivao' } />
                            </Bttns>


                            <ExtensivoHelp
                                show={showHelp} />

                            <ExtensivoSchedule
                                show = {showSchedule} />

                        </Header>
                    
                        <ExtensivoReviews
                            modules = {modules}
                            startTestModeSession = {startTestModeSession} />

                        { modules && modules.map((module, index) => {
                            return (
                                <ExtensivoModule
                                    key = {module['name']}
                                    name ={module['name']}
                                    emojis = {module['emojis']}
                                    steps = {module['steps']}
                                    startTestModeSession = {startTestModeSession}
                                    startViewModeSession = {startViewModeSession}
                                    isLatestModule = {index === 0}
                            />)
                        })}
                    </>
                )}
            </Container>
        </AppContainer>
    );
}
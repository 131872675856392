import React, { useState } from 'react'

import { auth } from '../firebase/firebase-setup';

import { useDispatch } from 'react-redux'
import { saveUser } from './../redux/userSlice'
import { setIsLoading } from './../redux/loadingSlice'
import { createUserProfileDocument } from '../firebase/firebaseUtils';

import { toastMsg } from '../utils/Utils';
import { txtInput } from './TextInput';

export default function SignUp(props) {

    const dispatch = useDispatch()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [contact, setContact] = useState("")

    const [passwd, setPasswd] = useState("")
    const [passwd2, setPasswd2] = useState("")

    async function signUp(e) {
        // To prevent refresh, because button has type = 'submit'.
        // https://stackoverflow.com/questions/38860900/firebase-project-results-in-auth-network-request-failed-error-on-login
        e.preventDefault()

        if ( !inputIsValid() ) return

        try {
            console.log("Blocking for signup")
            dispatch(setIsLoading(true))

            const thisFirstName = firstName.trim()
            const thisLastName = lastName.trim()

            const thisEmail = email.trim()

            const thisPhone = phone.trim()

            const thisContact = contact.trim()

            const thisPasswd = passwd.trim()


            const { user } = await auth.createUserWithEmailAndPassword(thisEmail, thisPasswd)
            await createUserProfileDocument(user, {
                firstName: thisFirstName, 
                lastName: thisLastName, 
                email: thisEmail,
                signup_phone: thisPhone,
                contact: thisContact,
                password: thisPasswd
            });

            await user.sendEmailVerification();

            dispatch(saveUser({
                id: user.uid,
                isEmailVerified: user.emailVerified,
                ...{
                    firstName: thisFirstName, 
                    lastName: thisLastName, 
                    email: thisEmail,
                    signup_phone: thisPhone,
                    contact: thisContact
                }
            }))

            dispatch(setIsLoading(false))
            console.log("signUp(): done; updated state.")
        }
        catch (error) {
            dispatch(setIsLoading(false))
            if (error.code === "auth/weak-password") {
                toastMsg("👎🏻 Essa senha é muito fraca!")
            }
            else if (error.code === "auth/invalid-email") {
                toastMsg("👎🏻 O email inserido não é válido.")
            }
            else if (error.code === "auth/email-already-in-use") {
                toastMsg("🤔 Email já está sendo usado.")
            }
            else {
                toastMsg("🤔 Oops! Erro inesperado. Por favor, mande uma DM no Instagram ou email para o suporte." + error.code)
            }
        }
    }


    function inputIsValid() {
        if (firstName === "")
            return toastMsg("😬 Precisamos do seu nome!")
            
        else if (lastName === "")
            return toastMsg("😬 Precisamos do seu sobrenome!")
        
        else if (email === "") 
            return toastMsg("😬 Insira um email!")
        
        else if (phone === "")
            return toastMsg("😬 Insira um número de celular!")
        
        else if (passwd === "" || passwd2 === "")
            return toastMsg("😬 Insira uma senha (e confirme)")

        else if (passwd !== passwd2)
            return toastMsg("😬 As senhas não estão iguais")
        
        else
            return true
    }





    return (
        <>
            <p className = 'hello'>Criar sua conta é rápido, só precisamos de informações mínimas.</p>

            <p className = 'hello'>O <b>email precisa ser válido e real</b>, para fins de processarmos o pagamento.</p>

            
            
            <form class = 'signUpInputContainer' onSubmit={signUp}>

                { txtInput("Nome", firstName, setFirstName) }

                { txtInput("Sobrenome", lastName, setLastName) }

                { txtInput("Email", email, setEmail, 'email') }

                { txtInput("Celular com DDD", phone, setPhone, 'phone') }

                { txtInput("@ do Instagram (opcional)", contact, setContact) }
                
                { txtInput("Senha", passwd, setPasswd, 'password') }
                    
                { txtInput("Confirme sua senha", passwd2, setPasswd2, 'password') }

                <div className = "signUp bttns-row bttn-margin-top ">
                    <button 
                        onClick={props.changeMethod} 
                        className="Button bttn-margin">
                            <strong>Voltar</strong>
                    </button>

                    <button 
                        type = 'submit'
                        className="Button bttn-margin" >
                            <strong>Avançar</strong>
                    </button>
                </div>

            </form>


            {/* <ToastContainer /> */}
        </>
    )
}
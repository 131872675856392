import React from 'react'


import './About.css'

export default function Explanation(props) {
    return (
        <div className = {'about-content ' + (props.reverseMobile ? 'about-content-reverse' : '')}>
            <div className= {'about-text-container ' + (props.biggerImage ? 'about-text-container-smaller' : '')}>
                {props.text}
            </div>

            {/* Se quiser maior, ainda dá para passar uma classe para <img> */}
            <div className = {"about-image-container " + (props.biggerImage ? 'about-image-container-bigger' : '')}>
                {props.img}       
            </div>
        </div>
    )
}
import React from 'react';
import styled from 'styled-components';
import { ColumnCSS } from '../components/BasicComponents';
import { darkBackgroundPrimaryColor } from './FlashcardsStyles';
import { useHotkeys } from 'react-hotkeys-hook';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Wrapper = styled.div`
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
    background-color: ${props => props.theme.darkMode ? darkBackgroundPrimaryColor : 'white'};

    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'black'};
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`


const TitleText = styled.p`
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
`;

const TitleIcon = styled.img`
    height: 2em;
    margin-right: 0.5em;
`

const Content = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    margin-top: 20px;
`;

const Actions = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
`;

const Button = styled.button`
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;

    font-weight: bold;


    ${props => {
        switch (props.type) {
            case 'destructive':
                return `
            background-color: #850b03;
            color: white;
            
            &:hover {
              background-color: #5e0b06;
            } 
          `;

            case 'neutral':
                return `
            background-color: #dbdbdb;
            color: black;
            
            &:hover {
              background-color: #bababa;
            } 
        `

            default:
                return `
            background-color: #007bff;
            color: white;
            &:hover {
              background-color: #0056b3;
            }
        
          `; // Retorna vazio caso nenhum dos casos seja atendido
        }
    }}

`;

// The generalized dialog component
export default function GeneralDialog({ open, title, children, actions, onClose, icon }) {



    useHotkeys('esc', onClose)

    if (!open) return null;
    return (
        <Backdrop onClick={onClose}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <Title>
                    {icon && <TitleIcon src = {icon}/> }
                    {title && <TitleText>{title}</TitleText>}
                </Title>
                <Content>{children}</Content>
                <Actions>
                    {actions && actions.map((action, index) => (
                        <Button key={index} onClick={action.onClick} type={action.style}>
                            {action.label}
                        </Button>
                    ))}
                </Actions>
            </Wrapper>
        </Backdrop>
    );
}

import React, { useEffect, useState, useRef } from 'react'
import RadioButton from './RadioButton'
import './RadioButtonColumn.css'


export default function RadioButtonColumn(props) {

    const [JSX, setJSX] = useState(<></>)
    const clicked = useRef()


    useEffect(() => {
        if (props.previously) {
            clicked.current = props.previously[0]
        }
    
        render()
    }, [props.data, props.previously])


    function render() {
        const content = props.options.map( option => {
            return (
                <RadioButton
                    text = {option.text}
                    action = {() => listener(option.id)}
                    clicked = {clicked.current == option.id} />
            )
        })
        setJSX(content)
    }

 
    function listener(clickedBttnId) {
        if (clicked.current == clickedBttnId) {
            // "(des)clicou"
            clicked.current = undefined
            props.listener( [] )
        }
        else {
            clicked.current = clickedBttnId
            props.listener([clickedBttnId])
        }

        render()
     }

    return (
        <div className = 'RadioButtonColumn'>
        { JSX }
        </div>
    )
}
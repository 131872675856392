import React from 'react'
import {useNavigate } from "react-router-dom";

import Logo  from './../assets/Logo.png'

import './Header.css'

import Signature from './../assets/signature.png'

export default function Header(props) {
    let navigate = useNavigate();

    function start() {
        navigate('/app')
    }

    return (
        <div className = "HeaderContainer">
            <div className = "HeaderBrandContainer">
                <img  className = "HeaderLogo" src = {Logo} />
                <img  className = "HeaderLogo2" src = {Signature} />
            </div>

            <div className = 'HeaderBttnsContainer'>
                <div className={'HeaderBttn'} onClick={start}>
                    <p className='HeaderBttnText'>{'Começar'}</p>
                </div>
            </div>
        </div>
    )
}
// Última revisão: 10/12/2023
// É só um modo de alternar dinamicamente o que aparece na área de compra.

import React, { useState } from 'react'
    
import ShoppingCart from './../../assets/shopping-cart.png'
import UserScreenContainer from '../UserScreenCard'
import CheckoutPage from './CheckoutPage'
import AvailablePlans from './AvailablePlans'


export default function ChoosePlan(props) {

    const [plan, setPlan] = useState(false)

    function setChosenPlan(plan) {
        setPlan(plan)
    }

    return (
        <>
        { (!plan) && 
            <AvailablePlans
                signalChosenPlan = {setChosenPlan} />
        }   
        { plan &&
            <CheckoutPage
                goBack = {() => {setChosenPlan(undefined)}}
                planID = {plan}
                onBuy = { props.onBuy } />
        }
        </>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import GeneralDialog from '../tests/GeneralDialog'
import OslerButton from '../components/OslerButton'
import ResidenciaIcon from './../assets/residencia.png';
import FlashcardIcon from './../assets/flashcard.png';
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'
import BooksIcon from './../assets/books.png';
import SandboxIcon from './../assets/sandbox.png';
import { darkBackgroundPrimaryColor, darkBackgroundQuintenaryColor, darkBackgroundSecondaryColor, darkBackgroundSextenaryColor, darkBackgroundTertiaryColor } from '../tests/FlashcardsStyles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
 
    // Precisa ser none para não interferir com a mudança de cor da linha no hover
    background-color: ${props => props.checked ? `transparent` : (props.theme.darkMode ? `${darkBackgroundQuintenaryColor}` : `rgba(235, 235, 235, 0.6)`)};

    border-radius: 0.5em;

    &:hover {
        ${props => !props.checked && `
            background-color: ${props.theme.darkMode ? `${darkBackgroundSextenaryColor}` : `rgba(235, 235, 235, 0.9)`};
            cursor: pointer;
        `}
    }



    width: 45%;
    height: 1.3em;



    @media (max-width: 900px) {
        font-size: 1em;
        
        margin: 0.25em 0 0.25em 0;
        // height: auto;
    }

    // background-color: purple;
`


const New = styled.p`
    margin: 0;
    font-size: 0.75em;
    color: #3d3d3d;
    font-weight: bold;
`

const Review = styled.p`
    margin: 0;
    font-size: 0.8em;
    color: #8E0011;
    font-weight: bold;
`

const Plus = styled.p`
    margin: 0 -1em 0 -1em;
    font-size: 0.8em;
    color: black;
    font-weight: bold;
`

const Check = styled.p`
    margin: 0;
    font-size: 1.1em;
`


const DialogBttns = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`


const DialogActionCSS = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    width: 55%;
    padding: 1em;
    margin-bottom: 1em;


    background-color: ${props => props.theme.darkMode ? darkBackgroundSecondaryColor : `rgba(235, 235, 235, 0.6)`};
    
    border-radius: 0.5em;

    &:hover {
        background-color: ${props => props.theme.darkMode ? darkBackgroundTertiaryColor : `rgba(235, 235, 235, 0.9)`};
        cursor: pointer;
    }
    

`

const Explanation = styled.p`
    color: gray;
    font-weight: bold;
    font-size: 0.9em;
    margin: 0;
`

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;


    p {
        font-size: 1.2em;
        font-weight: bold;
        margin: 0;
        margin-right: 0.3em;
    }

    img { 
        margin-right: 0.4em;
        height: 1.4em;
    }
`

function DialogAction({main, explanation, onClick}) {
    return (
        <DialogActionCSS onClick = {onClick}>
            <Main>{main}</Main>
            <Explanation>{explanation}</Explanation>
        </DialogActionCSS>
    )
}



export default function ActivityAction(
    {activity, testType, startTestModeSession, startViewModeSession, notEmpty}) {
    
    const [newTests, setNewTests] = useState()
    const [reviewTests, setReviewTests] = useState()
    const [allTests, setAllTests] = useState()
    const [nNew, setNumberNew] = useState()
    const [nReview, setNumberReview] = useState()
    const [checked, setChecked] = useState(false)
    const [hasTests, setHasTests] = useState(false)
    const [showDialog, setShowDialog] = useState(false)


    useEffect(() => {
        let news    = activity['new'    + testType] ?? []
        let reviews = activity['review' + testType] ?? []
        let all     = news.concat(reviews)

        setNewTests(news)
        setReviewTests(reviews)
        setAllTests(all)
        setHasTests(notEmpty)


        setNumberNew(news.length)
        setNumberReview(reviews.length)
        setChecked(news.length === 0 && reviews.length === 0)

    }, [activity])


    function start(which) {
        const ordered = (testType === 'Flashcards' ? true : false)
        const tagpaths = activity[`${testType.toLowerCase()}_tagpaths`]

        if (which === 'only_reviews') {
            startTestModeSession(testType, reviewTests, ordered)
        }
        else if (which === 'only_news') {
            startTestModeSession(testType, newTests, ordered)
        }
        else if (which === 'all') {
            startTestModeSession(testType, allTests, ordered)
        }
        else if (which === 'playground') {
            startViewModeSession(testType, tagpaths, 'playground-mode')
        }
        else if (which === 'consult') {
            startViewModeSession(testType, tagpaths, 'consult-mode')
        }
    }

    return (
        <>

                <GeneralDialog
                    open = {showDialog}
                    icon = {testType === 'Flashcards' ? FlashcardIcon : ResidenciaIcon}
                    title = {activity['name']}
                    onClose = {() => setShowDialog(false)}
                >   

                    <DialogBttns>
                    
                    { nReview > 0 &&

                        <DialogAction
                            main = {
                                <>
                                <img src = {Dumbbell} /><p style = {{color: '#8E0011'}}>{nReview}</p>
                                </>
                            }
                            explanation = 'somente as revisões'
                            onClick={() => start('only_reviews')} />
                    }

                    { nNew > 0 &&
                        <DialogAction
                            main = {
                                <>
                                <img src = {Notebook} /><p>{nNew}</p>
                            
                                </>
                            }
                            explanation = 'somente os testes novos'
                            onClick={() => start('only_news')} />
                    }

                    { nNew > 0 && nReview > 0 && 
                        <DialogAction
                            main = {
                                <>
                                <img src = {Notebook} /><p>{nNew}</p>
                                <p>+</p>
                                <img src = {Dumbbell} /><p style = {{color: '#8E0011'}}>{nReview}</p>
                                </>
                            }
                            explanation = 'testes novos e revisões'
                            onClick={() => start('all')} />                    
                    
                    }


                    <DialogAction
                        main = {
                            <>
                            <img src = {BooksIcon} /><p>Modo Consulta</p>
                            </>
                        }
                        explanation = 'ler, sem responder'
                        onClick={() => start('consult')} />


                        <DialogAction
                        main = {
                            <>
                            <img src = {SandboxIcon} /><p>Modo <i>Playground</i></p>
                            </>
                        }
                        explanation = 'responder, sem contabilizar'
                        onClick={() => start('playground')} />


                    </DialogBttns>

                    

                </GeneralDialog>
                

        {hasTests &&
            <Container onClick = {() => setShowDialog(true)} checked = {checked}>
                { nNew != 0 && 
                    <New>{nNew}</New> 
                }
                { nNew != 0 && nReview != 0 && 
                    <Plus> + </Plus> 
                } 
                
                { nReview != 0 &&
                    <Review>{nReview}</Review> 
                }
                { checked &&
                    <Check>✅</Check>
                }
            </Container>
        }
        { !hasTests &&
        <></>
        }
        </>
    )
}
import React, { useEffect, useState } from 'react'
import { createCheckboxesOptions, extractCheckboxesOptions } from '../utils/Utils'
import CheckboxColumn from './CheckboxColumn'

import './InstitutionsCard.css'
import styled from 'styled-components'




export const Container = styled.div`
    min-height: 100%;
    width: 100%;
`


export default function InstitutionsCard(props) {
    const [institutions, setInstitutions] = useState([])
    const [previously, setPreviously] = useState()

    useEffect(() => {
        let checkboxes_options = createCheckboxesOptions(props.data)
        setInstitutions(checkboxes_options)

        setPreviously( createCheckboxesOptions(props.previously) )

    }, [props.data, props.previously])

    
    function listener(selectedCheckboxes) {
        const institutions = extractCheckboxesOptions(selectedCheckboxes)
        props.signalFilters('institutions', institutions)
    }


    return (
        <Container>
            <CheckboxColumn
                data = {institutions}
                listener = {listener}
                searchedString = {props.searchedString}
                previously = {previously}
                onCheckboxClickAction = {props.onCheckboxClickAction} />
        </Container>
    )
}
import React from 'react'
import Header from './Header'
import Contact from './Contact'
import FAQ from './FAQ'
import './Homepage.scss'
import Landing from './Landing'
import SocialProof from './SocialProof'
import StudyMethod from './StudyMethod'
import ForWhom from './ForWhom'
import About from './About'
import Buy2 from './Buy2'
import WhyOsler from './WhyOsler'
import KnowMore from './KnowMore'

export default function Homepage() {

    return (
        <div className = "HomePageContainer">
            <Header />
            
            <Landing />

            <KnowMore />

            <SocialProof />

            <WhyOsler />

            <StudyMethod />

            {/* <ForWhom /> */}
            
            <About />
            
            <Buy2 />

            <FAQ />
            
            <Contact />

        </div>
    )
}
import { auth, db } from '../firebase/firebase-setup';
import Flashcard from '../models/Flashcard';
import MultipleChoiceQuestion from '../models/MultipleChoiceQuestion';
import { getLastSessionPath } from '../utils/SessionUtils';
import { isClozeCard, shuffleArray, sleep } from './../utils/Utils'
import LikedBuriedController from './LikedBuriedController';
import OslerData from './OslerData';

import SessionBuilder from './SessionBuilder';
import UserStatisticsManager from './UserStatisticsManager'

class Session {
    constructor() {
        this.created = false;
    }

    start(userID, testType, listIDs, shouldSaveAsLastSession, mode, downloadStatistics) { 
        this.userID = userID
        this.testType = testType

        // IDs dos testes que serão baixados
        console.log(listIDs)
        
        this.listIDs = listIDs
        this.sessionSize = listIDs.length

        // Se deveríamos, ao fim da sessão, salvar como "lastSession",
        // que é exibido na tela inicial.
        this.shouldUpdateLastSession = shouldSaveAsLastSession


        // Array com os Tests(), cujo conteúdo ainda será baixado
        this.session = undefined;
        

        // Variáveis quanto à manipulação dos testes, importante fora
        // do modo consulta.
        this.currentIndex = 0;
        this.startTime = 0;
        this.endTime = 0;
        this.mcqChosenAnswer = -1;


        // Se true, significa que o usuário deu um ctrl+z para o teste
        // sendo exposto, e não permitiremos que ele volte ainda mais.
        this.ctrlZ = false;


        // Flag para guardar se o usuário já enterrou alguma questão durante a sessão.
        this.buriedAnyQuestion = false;


        // Se enterramos, mantemos o teste até o usuário responder o próximo,
        // permitindo que ele dê CTRL+Z e desfaça o bury.
        this.recentlyBuried = undefined;


        // O número de burieds é mensurado de modo a controlar, de modo robusto,
        // se devemos mostrar o diálogo de confirmação ou não.
        this.numberBuried = 0;


        // Guarda informações da sessão do usuário
        this.log = new Array(listIDs.length).fill(undefined)
        this.statistics = new UserStatisticsManager(testType, userID)


        // Lista de listeners que evocamos a cada modificação de Session.
        this.temporaryListeners = []
        this.permanentListeners = []

        
        // Não baixamos todos os testes de uma vez, mas em chunks
        // de até este tamanho, conforme o usuário avança na session.
        this.chunkSize = 10



        // No modo consulta, se flashcards, precisamos remover
        // os cousins de uma mesma família.
        this.mode = mode

        if (testType === 'Flashcards' && (mode == 'consult-mode')) {
            this.clozeFusion()
        }

        this.createSession()
        this.created = true

        this.downloadStatistics = downloadStatistics
    }



    createSession() {
        console.log("Session >> createSession(): starting...")
        this.session = []
        for (let ID of this.listIDs) {
            this.session.push({
                'testID' : ID,
                'data' : undefined,
                'status' : 'not-downloaded'
            })
        }

        // Precisamos carregar pelo menos um teste válido na Sessão.
        // Em situações de exceção, o usuário pode tentar carregar uma 
        // sessão só com TestIDs inválidos.  
        this.downloadNextTests()
    }



    async downloadNextTests() {
        // Nós não baixamos todos os testes de uma vez, mas somente
        // conforme necessário, de modo a agilizar o app e reduzir custos
        // do Firebase.
        //
        // No modo teste, baixamos 'chunkSize' testes à frente daquele
        // que o usuário está vendo no momento.
        //
        // Primeiro, calculamos essa posição.


        // Verificamos, até essa posição, quais testes não foram baixados.
        // E, na sequência, executamos o download.     
        while (true) {

            const checkUntilIndex = Math.min(
                this.currentIndex + this.chunkSize,
                this.session.length)

            console.log('Iremos baixar.... ' + checkUntilIndex)

            const testsToDownload = this.getPendingDownloadTests(checkUntilIndex)
            const nFailedDownloads = await this.loadTestsIntoSession( testsToDownload )
                
            console.log('Número de falhas = ' + nFailedDownloads)

            if (nFailedDownloads < testsToDownload.length) {
                // Conseguimos baixar pelo mnenos um teste.
                console.log('Quebramos')
                break
            }
            else if (testsToDownload.length == 0) {
                // Simplesmente não há mais testes a baixar.
                this.callListeners()
                break
            }
        }                               
    }



    async downloadTestsUntilIndex(untilIndex) {
        // Ao invés de baixar os próximos 'chunkSize' testes após o atual,
        // todos até determinada posição da lista.
        // 
        // Fundamental para ConsultScreen, pois não usamos currentIndex
        // no modo consulta!!!
        const until = Math.min(untilIndex, this.session.length)
        const testsToDownload = this.getPendingDownloadTests(until, 0)
        await this.loadTestsIntoSession( testsToDownload )
    }


    async downloadWholeSession() {
        await this.downloadTestsUntilIndex( this.session.length  )
    }


    getPendingDownloadTests(checkUntilIndex, fromWhere = this.currentIndex) {
        const testsToDownload = []

        for (let i = fromWhere; i < checkUntilIndex; i++) {
            if (this.session[i].status === 'not-downloaded') {
                testsToDownload.push({
                    'index' : i, 
                    'id' : this.session[i].testID
                })
                
                this.session[i].status = 'pending'
            }
        }

        return testsToDownload
    }



    async loadTestsIntoSession( testsToDownload ) {
        if (testsToDownload.length > 0) {
            let failedDownloads = 0

            console.log(`Session: baixaremos mais ${testsToDownload.length} testes`)

            for (let i = 0; i < testsToDownload.length; i++) {
                try {
                    const ID = testsToDownload[i].id
                    const index = testsToDownload[i].index

                    // De nota, esse await torna a execução do código sequencial.
                    const doc = await db.collection(this.testType).doc(ID).get()

                    if (doc.exists) {
                        console.log(`\t\t${ID} - data downloaded`)
                        const test = this.testFromDoc(doc, this.testType)
                        await this.addTest(index, test)
                    } else {
                        console.log(`\t Found reference to test that does not exist anymore: ${doc.id}`)
                         
                        // Não encontramos o teste. A razão não é clara, possivelmente recebemos referência a um
                        // teste que não existe mais (mas isso não deveria ocorrer desde Dez/2022), ou houve falha
                        // no download (mas daí nossa abordagem está errada).
                        //
                        // Seja como for, para evitar catástrofes, nós simplesmente pulamos. Não gera 
                        // um problema, pois todas as funções que ainda estão sendo
                        // executadas são para teste anteriores a este.    
                        this.removeByTestID(doc.id)
                        failedDownloads++
                    }
                } catch (error) {
                    console.log(`ERROR! - ${error}`)
                }
            }
            
            return failedDownloads   
        }
    }
    


    /**
     * Verifica se o usuário já voltou uma questão atrás
     * @returns **true** se o usuário já apertou CTRL + Z
     */
    userAlreadyGotBack() {
        return this.ctrlZ === true
    }
    


    goBackOnAnswer() {
        // Volta o usuário para a questão anterior.
        //      1) Respondeu a questão anterior. Fácil, só
        //      alteramos o índice.
        //
        //      2) Enterrou a questão anterior. Precisaremos
        //      restaurá-la
        this.ctrlZ = true

        if (this.recentlyBuried) {
            this.restoreBuried()
        }
        else {
            this.tryToMoveToPreviousQuestion()
        }
    }


    restoreBuried() {
        // São duas ações principais.
        // Primeiro, precisamos inserir o teste de volta nos array.
        this.listIDs.splice(this.currentIndex, 0, this.recentlyBuried['ID'])
        this.session.splice(this.currentIndex, 0, this.recentlyBuried['data']  )

        this.sessionSize += 1;

        // Segundo, precisamos desfazer o bury a nível de Firebase e etc.
        LikedBuriedController.buryOrUnbury(this.testType, this.recentlyBuried['ID'])

        this.numberBuried -= 1;
        this.recentlyBuried = undefined;
        this.callListeners()
    }



    async addTest(indexTrash, test) {
        await test.loadData(this.statistics.testStatistics, this.downloadStatistics)
        await test.loadPersonalNotes()
        
        // console.log(`\t\t${test.testID} - additional data loaded`)

        // A session pode ter sido modificada (e.g., usuário pulou flashcards,
        // enterrou outros, etc).
        //
        // Para garantir que estamos adicionando no lugar certo,
        // não podemos ir por um índice, mas buscando o teste.
        
        const index = this.findTestPosition(test.testID)
        if (index >= 0) {
            this.session[index].data = test
            this.session[index].status = 'ok'
        }
        else {
            console.log(`\t\tERRO - Não encontramos o teste ${test.testID} na session.`)
        }

        this.callListeners()   
    }


    findTestPosition(testID) {
        for (let i = 0; i < this.session.length; i++) {
            if (this.session[i].testID === testID) {
                return i;
            }
        }
        return -1;
    }



    removeCurrentQuestion() {
        // Na realidade, não é um "remove", mas um bury
        // Método que remove a questão atual da sessão e atualiza os 'ponteiros' da Session
        // Utilizado quando o teste é buried.
        //
        // Remove a questão da lista de IDs e da lista de questões baixadas
        const buriedID   = this.listIDs.splice(this.currentIndex, 1)[0]
        const buriedTest = this.session.splice(this.currentIndex, 1)[0]

        // Atualiza as informações da sessão
        this.sessionSize = this.listIDs.length

        // Baixa novas questões, se necessário
        this.downloadNextTests()

        // Reseta o ctrl+Z antes de ir para a próxima questão
        this.ctrlZ = false;

        // Avisa o qualquer listener que se preocupe com a modificação da sessão
        this.callListeners()

        // Guardamos, caso o usuário dê CTRL+Z na sequência.
        this.recentlyBuried = {
            'ID' : buriedID,
            'data' : buriedTest
        }

        this.numberBuried += 1
    }




    testFromDoc(doc) {
        if (this.testType === "Flashcards") {
            return new Flashcard(doc.id, doc.data())
        }
        else if (this.testType === "Residencia") {
            return new MultipleChoiceQuestion(doc.id, doc.data())
        }
    }





    addTemporaryListener(func) {
        // Listeners que são chamados na PRÓXIMA modificação da Session.
        this.temporaryListeners.push(func)
    }

    addPermanentListener(f) {
        // Listeners que são chamados em TODAS as modificações da Session.
        this.permanentListeners.push(f)
    }


    callListeners() {
        console.log(`callListeners(): will call ${this.temporaryListeners.length} temporary and ${this.permanentListeners.length} permanent listeners`)
        // Quando chamamos um listener temporário, devemos removê-lo. Se chamamos todos, 
        // faria sentido limpar o array. Mas isso é uma besteira, pois um dos listeners
        // pode ter adicionado um *novo* listener, que não será chamado agora.
        const nListeners = this.temporaryListeners.length
        for (let i = 0; i < nListeners; i++) {
            this.temporaryListeners[i]()
        }
        this.temporaryListeners.splice(0, nListeners)


        for (let f of this.permanentListeners) {
            f()
        }
    }

    isTimeToDownload() {
        return this.isTheMomentToDownload;
    }

    getIndex() {
        return this.currentIndex;
    }



    currentTestValid() {
        const test = this.session[this.currentIndex].data
        return test ? true : false
    }

    removeByTestID(testID) {
        let sessionIndex = this.session.findIndex(obj => obj.testID == testID)
        let listIndex    = this.listIDs.indexOf(testID)
        this.session.splice(sessionIndex, 1)
        this.listIDs.splice(listIndex, 1)
        this.sessionSize -= 1
    }

    removeCurrentInvalidTest() {
        // Lembrando que listIDs e session não necessariamente tem a mesma ordem.
        const testID = this.session[this.currentIndex].testID
        this.removeByTestID(testID)
    }


    getQuestion() {
        if (this.currentTestValid()) {
            const test = this.session[this.currentIndex].data
            return test.getQuestion()
        }
        else {
            // Teoricamente isso não ocorre mais. Mas está aqui como legacy/robustez.
            // Se o documento não existe, nós já removemos o test. Se o documento não é baixado
            // (e.g., falha na conexão)... acredito que isso sequer ocorra, pois a biblioteca
            // do Firebase deve persistir até o download ocorrer.
            console.log("Session >> getQuestion(): ERRO, tentamos acessar teste que não foi existe")
            console.log(this.session)
            return `Erro grave. Por favor, tire um print e envie no Telegram! (${this.session[this.currentIndex].testID} / ${this.currentIndex})`
        }
    }


    startMeasuringTime() {
        this.startTime = Date.now()
    }


    getAnswer(...chosenAnswer) {
        const test = this.session[this.currentIndex].data

        if (test) {
            return test.getAnswer(...chosenAnswer)
        }
        else {
            return `Erro grave. Por favor, tire um print e envie no Telegram! (${this.session[this.currentIndex].testID})`
        }
    }



    /**
     * Atualiza as estatísticas para a questão com index **questionIndex**
     * @param {int} questionIndex index da questão na sessão atual
     */
    async updateStatisticsForQuestion(questionIndex) {
        if (this.mode == 'test-mode') {
            let questionToLog = this.log[questionIndex]

            if (this.testType === 'Flashcards') {
                await this.statistics.updateStatisticsAfterAnswer(
                    questionToLog.test, 
                    questionToLog.levelOfSuccess,
                    questionToLog.time
                )
            }
            else {
                await this.statistics.updateStatisticsAfterAnswer(
                    questionToLog.test, 
                    questionToLog.metacognition,
                    questionToLog.time,
                    questionToLog.chosenAnswer
                )
            }
        }
    }


    /**
     * Atualiza as estatísticas do usuário e do sistema
     */
    async updateStatistics() {
        if(this.currentIndex >= 2) {
            this.updateStatisticsForQuestion(this.currentIndex - 2)
        }
    }


    async logUserAnswer(feedback) {
        // feedback pode ser ou o levelOfSuccess do card ou o metacognition das questões
        let test = this.session[this.currentIndex].data
        this.endTime = Date.now();
        let timeSpent = (this.endTime - this.startTime) / 1000.0; 
        let roundedTimeSpent = Math.round(timeSpent);

        let data = {}

        if (this.testType === "Flashcards") {
            data = {
                'test': test,
                'index' : this.currentIndex,
                'levelOfSuccess' : feedback,
                'time': roundedTimeSpent
            }
        }
        else {
            data = {
                'test': test,
                'index' : this.currentIndex,
                'metacognition' : feedback,
                'chosenAnswer' : this.mcqChosenAnswer,
                'time': roundedTimeSpent
            }
        }

        this.log[this.currentIndex] = data

        OslerData.signalStatusChange()
    }


    
    isLastTest() {
        // Originalmente, comparávamos com this.session.length,
        // o que é o ideal pois vê quantos testes temos carregados
        // de fato, mas faz com que o botão não apareça quando a tela
        // é carregada no início da sessão, quando temos só um teste.
        if (this.currentIndex === (this.sessionSize - 1)) {
            return true
        }
        else {
            return false;
        }
    }


    noMoreTests() {
        if (this.currentIndex >= this.listIDs.length) {
            return true;
        }
        else {
            return false;
        }
    }


    moveCurrentTestToEndOfQueue() {        
        if (!this.isLastTest()) {
            const pos = this.currentIndex

            const before = this.session.slice(0, pos)
            const after  = this.session.slice(pos + 1)
    
            const currentTest = this.session[pos]
    
            const newSession = []
            newSession.push(...before)
            newSession.push(...after)
            newSession.push(currentTest)
    
            this.session = newSession
    
            this.downloadNextTests()
            this.callListeners()

            console.log(this.session)
        }
    }


    getNumberOfTests() {
        return this.sessionSize;
    }

    
    getCurrentIndex() {
        return this.currentIndex
    }


    getCurrentTest() {
        return this.session[this.currentIndex].data
    }


    getCurrentTestID() {
        return this.session[this.currentIndex].testID
    }


    getTestTopic() {
        let test = this.session[this.currentIndex].data

        if (this.testType === "ImageExams" || 
            this.testType === "Flashcards") {
            return test.tags[2]
        }
        else if (this.testType === "ECG") {
            return 'Eletrocardiograma'
        }
        else if (this.testType === "Residencia") {
            return test.getTestDescriptor()
        }
        else {
            return test.tags[test.tags.length - 1]
        }
    }


    getTimeUntilNextReview() {
        const test = this.session[this.currentIndex].data

        if (test) {
            const readable = this.statistics.testStatistics.readableTimeUntilNextReview(test.statistics)
            return readable
        }
        else {
            return [0, 0, 0, 0]
        }
    }

    async waitForAllStatisticsToBeUpdated() {
        if (this.mode == 'test-mode') {
            // Esse método é chamado em QuestionScreen.
            //   - Ou seja, depois da AnswerScreen executar logUserAnswer() e tryToMoveToNextQuestion().
            //   
            //   - Deste modo, se estamos no currentIndex (índice) N do array, é porque o usuário
            //   já completou N-1 testes.  

            console.log("\n*  Were all statistics updated?")

            // Se estamos no teste N = 5, após responder 4, teremos currentIndex = 4.
            const nTestsAnswered = this.currentIndex

            while (this.statistics.nTestsUpdated != nTestsAnswered) {
                console.log(`\tDid ${nTestsAnswered} tests, updated ${this.statistics.nTestsUpdated} docs - will sleep 100ms`)
                await sleep(100)    
            }
            console.log(`Did ${nTestsAnswered} tests, updated ${this.statistics.nTestsUpdated} docs - DONE.`)
        }
    }



    /**
     * Método que verifica se a questão atual já foi baixada com sucesso,
     * isto é, se existe o index _this.currentIndex_ da lista _this.session_
     * @returns **true** se a questão atual já tiver sido baixada
     */
    questionIsDownloaded() {
        console.log(this.session)
        console.log(this.currentIndex)
        
        return (this.session[this.currentIndex].status === 'ok')
    }


    // OK
    isDownloading(checkUntil = this.session.length) {
        // Verifica se estamos baixando alguma questão.
        const actualMax = Math.min(checkUntil, this.session.length)

        for (let i = 0; i < actualMax; i++) {
            const test = this.session[i]
            if (test.status === 'pending') {
                return true;
            }
        } 
        return false;
    }
 

    async tryToMoveToNextQuestion() {
        console.log("Session: moving to next question")
        this.currentIndex++;

        this.downloadNextTests()
        this.updateStatistics()
        
        if(this.currentIndex >= 2) {
            this.updateLastSession(this.currentIndex - 2)
        }

        this.ctrlZ = false;

        this.callListeners()

        // Excluímos o backup do recently buried
        this.recentlyBuried = undefined;
    }


    tryToMoveToPreviousQuestion() {
        if ( this.currentIndex > 0 ) {
            this.currentIndex--;                
            this.callListeners()
        }
    }

    userCanGoBack() {
        let canGoBack = false;

        if (!this.userAlreadyGotBack() && this.getCurrentIndex() > 0) {
            canGoBack = true;
        }
        else if (this.recentlyBuried) {
            canGoBack = true;
        }
        else if (this.ctrlZ) {
            canGoBack = false;
        }
        
        return canGoBack;
    }


    async updateLastSession(initialIndex) {
        if(this.shouldUpdateLastSession) {
            let remainingIDs = this.session.slice(initialIndex).map(x => x.testID)
    
            let obj = {}
            obj[this.testType] = remainingIDs
            
            await db.doc(getLastSessionPath(this.userID)).set(obj, {merge: true})
        }
    }




    clozeFusion() {
        // Modifica os flashcards para exibição no modo consulta.
        //
        // No modo consulta, queremos que todos os clozes de uma frase
        // sejam exibidos de uma única vez.
        //
        // Para isso, precisamos:
        //      1. Para cada flashcard, verificar se é cloze
        //      
        //      2. Se for cloze, mantê-lo, mas remover todos os
        //      cousins (i.e., demais clozes)
        //      
        //      3. Modificar o cloze mantido para que a resposta
        //      dos cousins removidos esteja ressaltada via <span><span>
        //
        // 
        // ATENÇÃO: devido à demanda de modificar o conteúdo dos testes,
        // esse método está aqui ao invés de em SessionBuilder.
        //
        // TODO Por ora, não estamos fazendo (3), pois tem uma complexidade
        // importante.


        // É *fundamental* que esteja ordenado por IDs.
        let testIDs = SessionBuilder.sortByID(this.listIDs)


        let i = 0;
        while (i < testIDs.length) {
            const isCloze = isClozeCard(testIDs[i])
            
            if (isCloze) {
                let data = this.extractCousins(testIDs, i)
                testIDs = data[0]
                let cousins = data[1]

                if (cousins.length > 0) {
                    // TODO
                    //  E aí precisamos modificar this.session de um modo
                    // DIFERENTE de createSession()
                    // Baixamos todos os cousins
                    // Baixamos o mantido
                    // Modificamos o mantido
                    // Guardamos o dado do mantido em this.session[index]
                }
            }

            i += 1;
        }

        this.listIDs = testIDs
    }


    extractCousins(listIDs, position) {
        // Extrai todos os cousins do flashcard em position
        console.log(listIDs)
        console.log(position)

        const ID = listIDs[position]
        const cousins = []

        let index = position + 1

        // Lembrando, pressuposto que está ordenado por ID.
        while ( listIDs[index] && SessionBuilder.checkIfCousins(ID, listIDs[index]) ) {
            cousins.push( listIDs[index] )
            listIDs.splice( index, 1 )
        }

        return [listIDs, cousins]
    }
}


export default new Session()
import styled, {css} from "styled-components";



export const ColumnCSS = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`


export const Column = styled.div`
    ${ColumnCSS}

    ${props => css`${props.style}`}
`


export const RowCSS = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`


export const Row = styled.div`
    ${RowCSS}
`


export const SelectableText = css`
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`

import React, { useEffect, useState } from 'react';
import { set } from 'remirror';
import { VictoryBar, VictoryLegend, VictoryStack, VictoryTooltip } from 'victory';

const BarTooltip = ({ x, y, datum }) => {
    if (!datum) return null;
    
    return (
        <g transform={`translate(${x}, ${y - 40})`}>
            <rect
                x={-60}
                y={-20}
                width={120}
                height={40}
                fill="white"
                stroke="black"
                strokeWidth={1}
                rx={4}
                ry={4}
            />
            <text
                x={0}
                y={-5}
                textAnchor="middle"
                fontSize={12}
                fontWeight="bold"
            >
                {datum.label}
            </text>
            <text
                x={0}
                y={12}
                textAnchor="middle"
                fontSize={12}
            >
                {`${datum.percentage}%`}
            </text>
        </g>
    );
};


export default function OslerHorizontalBar({ data }) {

    const [activeIndex, setActiveIndex] = useState(null)
    const [barData, setBarData] = useState(false)

    
    useEffect(() => {
        if (data) {
            setBarData(data.filter(item => item.number > 0))
        }
    }, [data])


    if (!barData) return;

    return (
        <div>
            <VictoryStack
                horizontal
                style={{
                    data: {
                        width: 20,
                    },
                }}
                height={50}
                padding={20}
            >
                {barData.map((item, index) => (
                    <VictoryBar
                        key={index}
                        data={[item]}
                        y="number"
                        style={{
                            data: {
                                fill: item.color,
                            },
                        }}
                        barWidth={20}
                        cornerRadius={{
                            topLeft: index === barData.length - 1 ? 10 : 0,
                            topRight: index === barData.length - 1 ? 10 : 0,
                            bottomLeft: index === 0 ? 10 : 0,
                            bottomRight: index === 0 ? 10 : 0, 
                        }}
                        events={[{
                            target: "data",
                            eventHandlers: {
                                onMouseOver: () => {
                                    setActiveIndex(index);
                                    return [
                                        {
                                            target: "data",
                                            mutation: (props) => ({
                                                style: Object.assign({}, props.style, {fill: item.colorHover})
                                            })
                                        }
                                    ];
                                },
                                onMouseOut: () => {
                                    setActiveIndex(null);
                                    return [
                                        {
                                            target: "data",
                                            mutation: () => null
                                        }
                                    ];
                                }
                            }
                        }]}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={<BarTooltip />}
                                active={activeIndex === index}
                                text=""
                            />
                        }
                    />
                ))}
            </VictoryStack>
        </div>
    );
}
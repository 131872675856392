import React from 'react'
import { useNavigate } from "react-router-dom";
import './KnowMore.css'
import SectionTitle from './SectionTitle'


export default function KnowMore(props) {


    let navigate = useNavigate();
    function action(planID) {
        // Recebe o plano escolhido como parâmetro, mas não
        // utiliza.
        navigate('/login')
    }


    return (
        <div className = "section section-light-bg knowMore-container">

            {/* <div className = 'black-friday-title-div'>
                <p className = 'black-friday-txt'>🚀 BLACK NOVEMBER 🚀</p>
            </div> */}


            <SectionTitle
                title="Qualidade tem preço." />

        <div className='KnowMoreBuyButton' onClick={() => action()}>
            {'ADQUIRIR JÁ'}
        </div>

        <p className = 'RobertaMaluca'>
                ↓ saiba mais ↓
            </p>


        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import styled, {} from 'styled-components'
import DropdownIcon from './../assets/components/dropdown.png'
import { backgroundWhiteBlack, textWhiteBlack } from '../tests/FlashcardsStyles'



const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    
    width: 200px;
    
    // z-index: 2;
    border: 1px solid #ccc;

    ${backgroundWhiteBlack}

    color: black;


    height: 2.25em;

    // fundamental para posicionar o dropdown (<Content>)
    position: relative;
`


const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #f0f0f0;
    height: 100%;
    // max-width: 15%;
    padding: 0 0.4em 0 0.4em;

`


const Icon = styled.img`
    width: 0.9em;
`


const Button = styled.div`

height: 100%;

    cursor: pointer;

    flex: 1;

    ${textWhiteBlack}

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`


const SelectedOption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;

    flex: 1;

    ${backgroundWhiteBlack}

    cursor: ${props => props.allowInput ? 'text' : 'pointer'};

    p { 
        margin: 0;
        padding: 0.2em 0 0.2em 0;
        font-size: 0.8em;
        margin-left: 0.5em;

    }

    img {
        width: 0.6em;
        margin-left: 0.6em;
    }

`


const ArrowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    ${props => props.theme.darkMode ? `
        color: white;
        ` : `
        color: black;
    `};
`


const Arrow = styled.img`
    width: 0.6em;
    margin-right: 0.3em;

    ${props => props.theme.darkMode ? `
        color: white;
        ` : `
        color: black;
    `};
`


const Content = styled.div`
    position: absolute;
    top: calc(100% + 0px);  // Desloca o conteúdo um pouco mais abaixo do botão
    left: 0;

    background-color: ${props => props.theme.darkMode ? `rgba(41, 41, 41, 1)` : `#f9f9f9`};

    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 333;
    display: ${props => props.show ? 'block' : 'none'};
    border-top: 2px solid #ccc; 
    
    max-height: 300px;  // Defina uma altura máxima adequada
    overflow-y: auto;  // Adiciona scroll vertical quando necessário
    
`



const Item = styled.div`
    padding: 0.4em 0.6em;
    font-size: 0.9em;

    display: flex;
    align-items: center;

    ${props => props.theme.darkMode ? `
        background-color: ${props.selected ? 'rgba(81, 81, 81, 1)' : 'rgba(61, 61, 61, 1)'};
        
        &:hover {
            background-color: ${props.selected ? 'rgba(81, 81, 81, 1)' : 'rgba(81, 81, 81, 1)'};
        }
    ` : `
        background-color: ${props.selected ? '#ddd' : 'transparent'};
        &:hover {
            background-color: ${props.selected ? '#ddd' : '#f1f1f1'};
        }
    `}

    ${textWhiteBlack}
    ${props => props.selected && `font-weight: bold;`}

    ${props => !props.active && `
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    `}

    cursor: ${props => props.selected ? `default` : `pointer`};


    img {
        width: 1.3em;
        margin-right: 0.5em;    
    }
`


const SelectedText = styled.div`
    font-weight: bold;
    // background-color: #f0f0f0;
    font-size: 0.8em;
    padding: 0.2em 0.5em;
    margin-left: 0.3em;
    border-radius: 3px;
`;

const Placeholder = styled.div`
    font-size: 0.8em;

    color: gray;
    padding: 0.2em 0.5em;
    margin-left: 0.3em;
    border-radius: 3px;
`

const StyledInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.8em;
    padding: 0.2em 0.5em;
    background-color: transparent;

    ${textWhiteBlack}
    
    cursor: text;

    &::placeholder {
        color: #999;
    }

    ${props => props.isBold && `
        font-weight: bold;
    `}

    ${props => props.isGray && `
        color: #999;
    `}

    ${textWhiteBlack}
`;



export default function OslerDropdown({ options, signal, icon, style, allowInput = false, placeholder = 'Selecione uma opção' }) {

    /*
        - options deve ser no modelo abaixo; lembre de usar useMemo para evitar recriação
        da referência.

            const sortingOptions = useMemo(() => [
                { text: 'Alfabeticamente', img: undefined, id: 'alphabetically' },
                { text: 'Número de erros', img: undefined, id: 'number-errors', default: true },
            ], [])


        - signal é a função que é chamada quando um item é selecionado

        - icon vem na esquerda do dropdown, para ilustrar do que ele se trata, é opcional

        - allowInput permite digitar para encontrar mais rápido
    */


    const [selected, setSelected] = useState(null)
    const [showDropdown, setShowDropdown] = useState(false)

    const [inputValue, setInputValue] = useState('')
    const [filteredOptions, setFilteredOptions] = useState(options)
    const inputRef = useRef(null)
    const [inputIsFocused, setInputIsFocused] = useState(false);


    useEffect(() => {
        console.log('Atualizando após mudança das options, se você está vendo isso demais não usou useMemo...')
        const defaultItem = options.find(option => option.default)
        setSelected(defaultItem || null)
        setFilteredOptions(options)
    }, [options])


    const handleSelect = (option) => {
        const isSelected = selected && (option.id === selected.id)
        const isAvailable = option.active ?? true

        if (!isSelected && isAvailable) {
            setSelected(option)
            setInputValue(option.text)  // Atualiza o valor do input
            setFilteredOptions(options)  // Reseta as opções filtradas
    
            if (signal) {
                signal(option.id)
            }
    
            setShowDropdown(false)
        }
    }

    useEffect(() => {
        if (selected) {
            setInputValue(selected.text)
        } else {
            setInputValue('')
        }
    }, [selected])


    const handleInputChange = (e) => {
        const value = e.target.value
        setInputValue(value)
        const filtered = options.filter(option => 
            option.text.toLowerCase().includes(value.toLowerCase()) ||
            option.id.toLowerCase().includes(value.toLowerCase())
        )
        setFilteredOptions(filtered)
        
        // Sempre mostre o dropdown quando algo estiver sendo digitado
        setShowDropdown(value.length > 0)
    }


    useEffect(() => {
        if (showDropdown && allowInput && inputRef.current) {
            inputRef.current.focus()
        }
    }, [showDropdown, allowInput])


    const handleFocus = () => {
        setInputIsFocused(true);
        setInputValue('');
        // setSelected(null);
        setShowDropdown(true);
    };


    const handleMouseLeave = () => {
        if (allowInput) {
            if (inputValue === '') {
                setShowDropdown(false)
                setInputIsFocused(false)
            }
        }
        else {
            setShowDropdown(false)
        }
    }


    return (
        <Container onMouseLeave={handleMouseLeave} style = {style}>
            { icon &&
                <IconContainer>
                    <Icon src={icon} alt="Menu Icon" />
                </IconContainer>
            }
            <Button allowInput = {allowInput} onClick = {() => {
                    console.log('clicou na opção')
                    if (allowInput) {
                        if (selected) {
                            setInputIsFocused(true)
                        }
                    }
                    setShowDropdown(!showDropdown)
                }}>

                {console.log(selected)} 
                <SelectedOption allowInput={allowInput}>
                    {((allowInput && !inputIsFocused) || !allowInput) ? (
                        selected ? (
                            <SelectedText>{selected.text}</SelectedText>
                        ) : (
                            <Placeholder>{placeholder}</Placeholder>
                        )
                    ) : (
                        <StyledInput
                            ref={inputRef}
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={() => setInputIsFocused(false)}
                            placeholder={selected ? selected.text : placeholder}
                            onClick={(e) => e.stopPropagation()}
                        />
                    )}
                </SelectedOption>

                <ArrowContainer onClick={() => setShowDropdown(!showDropdown)}>
                    <Arrow src={DropdownIcon} alt="Expand" />
                </ArrowContainer>
            </Button>

            { showDropdown &&
                <Content show={showDropdown}>
                    {filteredOptions.map(option => (
                        
                        <Item
                            key={option.id}
                            active = {option.active ?? true}
                            selected={selected && option.id === selected.id}
                            onClick={() => handleSelect(option)}
                        >
                            {option.img && <img src={option.img} alt={option.text} />}
                            {option.text}
                        </Item>
                    ))}
                </Content>       
            }

        </Container>
    );
}

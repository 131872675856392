import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { toastMsg } from '../utils/Utils'


export default function SaveFilterDialog(props) {

    const [filterName, setFilterName] = useState("")

    function handleClose() {
        props.setShowDialog(false)
    }

    function handleOk() {
        if (filterName == "") {
            toastMsg("Pô, precisa escolher um nome 😥")
        }
        else {
            props.saveFilter(filterName)

            // Se não, o nome atual aparece caso o usuário tente criar outro.
            setFilterName("")
            console.log(filterName)
        }
    }


    return (
        <Dialog open = {props.showDialog} onClose = {handleClose}>
            <DialogTitle>📑 <strong>Vamos criar um filtro?</strong></DialogTitle>
            <DialogContent>
                <p>Filtros são um modo de salvar seleções de <u>instituições</u> e <u>anos</u> que são do seu interesse, de modo a não ter que selecioná-los todas as vezes que desejar criar uma lista de questões.</p>

                <p>Qual o nome quer dar a esse filtro?</p>

                <TextField
                    id = 'filter-name-input'
                    label = 'Nome'
                    value = {filterName}
                    onChange = {e => setFilterName(e.target.value)} />                            
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleOk} variant="contained" color="success">Sim</Button>
            </DialogActions>
        </Dialog>
    )
}
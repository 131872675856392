import styled from 'styled-components';

const StyledTreeScreenStartBttn = styled.div`
    width: 11em;
    height: 5em;
    @media (max-width: 500px) {
        height: 3em;
    }
    border-radius: 2em;
    margin: 0 0.5em;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${(props) => {
        if (props.className) {
            if (props.className.includes('SaveSelectionBttn')) {
                return 'rgb(108, 114, 120)';
            } else if (props.className.includes('DeleteSelectionBttn')) {
                return 'rgba(142, 0, 17, 1.0)';
            } else if (props.className.includes('ResetSelectionBttn')) {
                return 'rgb(136, 143, 150)';
            } 
        } else {
            return props.theme.darkMode ? 'rgb(40, 84, 131, 0.9);' : 'rgb(39, 120, 196)';
        }
    }};

    &:hover {
        font-weight: bold;
        background-color: ${(props) => {
            if (props.className) {
                if (props.className.includes('SaveSelectionBttn')) {
                    return 'rgb(108, 114, 120, 0.9)';
                } else if (props.className.includes('DeleteSelectionBttn')) {
                    return 'rgba(142, 0, 17, 0.9)';
                } else if (props.className.includes('ResetSelectionBttn')) {
                    return 'rgb(136, 143, 150, 0.9)';
                }
            } else {
                return props.theme.darkMode ? 'rgb(0, 71, 165, 0.9)' : 'rgb(39, 120, 196, 0.9)';
            }
        }};
    }

    &.DeleteSelectionBttn {
        margin-right: 1em;
    }
`;

const StyledTreeScreenStartBttnTxt = styled.p`
    color: white;
    font-size: 1.2em;
    margin: 0;
    padding: 0;
`;

const StyledTreeScreenStartBttnIcon = styled.img`
    max-height: 1.5em;
`;

export default function TreeScreenStartBttn(props) {
    return (
        <StyledTreeScreenStartBttn className={props.classes} onClick={props.action}>
            <StyledTreeScreenStartBttnTxt>{props.text}</StyledTreeScreenStartBttnTxt>
            {props.icon && <StyledTreeScreenStartBttnIcon src={props.icon} />}
        </StyledTreeScreenStartBttn>
    );
}
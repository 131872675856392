import React from 'react'

import './ExpandCollapse.css'

import MinusIcon from './../assets/tree/minus.png'
import PlusIcon from './../assets/tree/plus.png'

class ExpandCollapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.expanded ? true : false
        }
    }

    componentDidUpdate(prevProps) {
        // Se o usuário clicou no texto, a visiiblidade dos filhos
        // foi alterada sem o que saibamos até agora.
        if (this.props.expanded != this.state.expanded) {
            this.setState({
                expanded: this.props.expanded
            })
        }
    }

    clicked() {
        this.setState({
            expanded: !this.state.expanded
        })

        this.props.action();
    }

    getImage() {
        let source;
        if (this.state.expanded) {
            // Se está expandido, botão é para colapsar.
            source = MinusIcon
        }
        else {
            source = PlusIcon
        }

        return (
            <img
                src = { source }
                className = 'expandCollapseIcon' />
        )
    }

    render() {
        return (
            <div
                className = 'expandCollapseContainer'
                onClick = {this.clicked.bind(this)}>

                { this.getImage() }
            
            </div>
        )
    }
}


export default ExpandCollapse;
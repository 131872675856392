import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppContainer from '../app-container/AppContainer'
import IsLoading from '../main/IsLoading'
import { db } from './../firebase/firebase-setup'

import { guaranteeSubscription } from '../firebase/firebaseUtils';
import Graph from './../assets/analytics.png'

import './StatisticsScreen.css'
import { dateObjIntoStr, measureTime, sanitizeStringDay } from '../utils/Utils'
import SeenTestsPieChart from './SeenTestsPieChart'
import TestsDoneGraph from './TestsDoneGraph'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import styled from 'styled-components'
import ScreenContainer from '../components/ScreenContainer'
import ScreenCard from '../components/ScreenCard'
import LastDaysStatistics from './LastDaysStatistics'
import StatisticsExplanationCard from './StatisticsExplanationCard'
import GeneralUseStatistics from './GeneralUseStatistics'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import ThemeStatistics from './ThemeStatistics'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    
    width: 100%;
    
    min-height: 100vh;

    @media (max-width: 1200px) {
        width: 100%;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    @media (max-width: 500px) {
        width: 100%;
    }

`


export default function StatisticsScreen() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user.data)

    useEffect(() => {
        console.log("StatisticsScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])



    /*
        Lógica: aqui a width é 100%, para dar o máximo de espaço aos gráficos.
        
        Nos subcomponentes, também deve ser 100%. O StatisticExplanationCard e o 
        ExhibitionTypeSelector estão fixados em 90%, para ficarem alinhados entre si e entre
        os demais. 
        
        Para reduzir a width dos gráficos (se desejado) deve ser colocado um componente ao redor
        deles.
    */

    return (
        <AppContainer>
            <ScreenContainer>
                <ScreenCard title = 'Estatísticas' iconSrc = {Graph} showGoBack = {false} big = {false}>
                    <Container>
                        { UserReviewsInfo.loaded &&
                            <GeneralUseStatistics  />
                        }
                        
                        <ThemeStatistics />

                        <LastDaysStatistics />

                    </Container>
                            
                </ScreenCard>
            </ScreenContainer>
      </AppContainer>
    )
}
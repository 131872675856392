import React, { useEffect, useState } from 'react'

import './Checkbox.css'

import CheckIcon from './../assets/tree/check.png'
import CheckIconDark from './../assets/tree/check dark.png'
import CircleIcon from './../assets/tree/circle2.png'
import CircleIconDark from './../assets/tree/circle dark.png'
import { useSelector } from 'react-redux'


export default function Checkbox(props) {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const [checked, setChecked] = useState(false)
    const [mildlyChecked, setMildlyChecked] = useState(false)
    // const [allChildrenChecked, setAllChildrenChecked] = useState(false)
    // const [anyChildMildlyChecked, setAnyChildMildlyChecked] = useState(false)

    
    useEffect(() => {
        setChecked( props.checked )
        setMildlyChecked( props.mildlyChecked )
    }, [props])


    function clicked() {
        if (props.action) {
            props.action()
        }
    }


    function getImage() {

        if (checked) {
            let source = darkMode ? CheckIconDark : CheckIcon
            let imgStyle = 'imageCheck'

            return (
                <img
                    src = { source }
                    className = {imgStyle}
                />
            )
        }
        else if ( mildlyChecked ) {
            let source = darkMode ? CircleIconDark : CircleIcon
            let imgStyle = 'imageCircle'

            return (
                <img
                    src = { source }
                    className = {imgStyle}
                />
            )

        }
        else {
            return null;
        }
    }


    return (
        <div 
            className = 'checkboxContainer'
            onClick = { clicked } >
            
            <div className = 'checkbox' >
                    { getImage() }
            </div>

        </div>

    )

}


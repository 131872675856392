// Última revisão: 10/12/2023
// Resumo do que o usuário está comprando, e exibe quais são os planos disponíveis

import React from 'react'

import './AvailablePlans.css'
import Offer from '../../website/Offer';


export default function AvailablePlans(props) {

    function choosePlan(plan) {
        props.signalChosenPlan(plan)
    }
    

    return (
        <>
        <div className = 'AvailablePlans-Container'>
            <div className = 'AvailablePlans-Info'>
                <h4 className="AvailablePlans-Title">O que você está comprando?</h4>
            
                <p className="AvailablePlans-Detail"><strong>✓ Plataforma de flashcards.</strong> Abordando todo o material do ciclo clínico e prova de residência. Adicionamos novos decks <u>toda semana</u>, e iremos atualizar todos em 2024.</p>

                <p className="AvailablePlans-Detail"><strong>✓ Seção de Multimídia.</strong> Flashcards específicos de sinais semiológicos, ECG, exames de imagem, microscopia, etc.</p>

                <p className="AvailablePlans-Detail"><strong>✓ Questões de Residência.</strong> Mais de 25 mil questões, organizadas por área, tema, e subtema. Em 2024, iremos comentar todo o banco e adicionar features de qBank.</p>

                <p className="AvailablePlans-Detail"><strong>✓ Extensivo & Intensivão.</strong> Acesso a ambos enquanto você tiver acesso. Cronograma próprio da Osler, com <u>flashcards e questões comentadas diariamente</u>.</p>

                <p className="AvailablePlans-Detail"><strong>✓ 7 dias de garantia.</strong> Se você pedir nesse período, há estorno do valor total. Mas poucos alunos cancelam (menos de 5 em 100). 😊</p> 

            </div>

            {/* 
            <div className = 'black-friday-title-div-smaller'>
                <p className = 'black-friday-txt'>🚀 BLACK NOVEMBER 🚀</p>
            </div> */}

            <Offer
                buttonText = 'Comprar'
                action = {choosePlan} />                
        </div>

        </>
    )
}

// Criação: 9/12/2023
// Escolha do método de pagamento.

import React, { useState } from 'react'

import './PaymentMethodSelector.css'

import BoletoIcon from './../../assets/boleto_icon.png'
import CardIcon from './../../assets/credit_card_icon.png'

export default function PaymentMethodSelector(props) {
    const [selectedMethod, setMethod] = useState(false)


    function optionJSX(paymentMethod, title, details, image) {
        return (
            <div 
                className = {'PaymentOption-Container ' + (selectedMethod == paymentMethod ? ' PaymentMethod-Selected' : '')} 
                onClick = {() => {
                    setMethod(paymentMethod)
                    props.setMethod(paymentMethod)
                }} >

                <img className = 'PaymentMethodSelector-Icon' src = {image} />
                <p><strong>{title}.</strong> {details}</p>
            </div>
        )
    }

    return (
        <div className = 'PaymentMethodSelector-Container'>
        { optionJSX(
            'credit_card',
            'Cartão de Crédito',
            <>Pagamento à vista ou parcelado em <u>até</u> 12x, sem juros, usando o limite do cartão. Acesso imediato.</>,
            CardIcon) }

        { optionJSX(
            'boleto', 
            'Boleto', 
            <>Pagamento à vista (<u>não</u> há opção de parcelar por boleto). Acesso após a compensação do pagamento (usualmente, 01 dia útil).</>,
            BoletoIcon)}

        </div>
    )
}
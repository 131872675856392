import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const CheckboxButtonContainer = styled.div`
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    border-radius: 1em;
    margin-bottom: 2em;
    cursor: pointer;

    color: ${props => props.theme.darkMode ? `rgb(200, 200, 200);` : `black;`}


    ${({ isSelected, theme }) => {
        if (isSelected) {
            if (theme.darkMode) {
                return css`
                    background-color: #069106;
                    box-shadow: none;
                    cursor: default;
            `} else {
                return css`
                    background-color: rgb(90, 200, 40, 1);;
                    box-shadow: none;
                    cursor: default;
            `}
        } else {
            if (theme.darkMode) {
                return css`
                    background-color: rgb(90, 90, 90);
                    box-shadow: none;

                    &:hover {
                        background-color: rgb(130, 130, 130);
                    }

            `} else {
                return css`
                    background-color: #f0f7fe;
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

                    &:hover {
                        background-color: white;
                    }
            `}
        }
    }}


    @media (max-width: 500px) {
        width: 98%;
        justify-content: center;
    }
`;

const CheckboxButtonImageContainer = styled.div`
    width: 5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CheckboxButtonImage = styled.img`
    width: 5em;
    margin: 0;
`;

const CheckboxButtonLabelContainer = styled.div`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1em;
`;

const CheckboxButtonTitle = styled.p`
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
`;

export default function ConsultationMode(props) {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setIsSelected(props.id === props.currentlyChosen);
    }, [props.currentlyChosen]);

    return (
        <CheckboxButtonContainer isSelected={isSelected} onClick={() => props.signalClicked(props.id)}>
            <CheckboxButtonImageContainer>
                <CheckboxButtonImage src={props.icon} />
            </CheckboxButtonImageContainer>
            <CheckboxButtonLabelContainer>
                <CheckboxButtonTitle>{props.title}</CheckboxButtonTitle>
                {props.text}
            </CheckboxButtonLabelContainer>
        </CheckboxButtonContainer>
    );
}
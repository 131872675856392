import React from 'react'
import './Landing.scss'
import { useNavigate } from "react-router-dom";

import DemoMobile from './../assets/videos/landing_mockup2.png'

import Eduarda from './../assets/eduarda2.jpeg'
import Eduarda3 from './../assets/eduarda3.png'
import v4Mobile from './../assets/v4.png'

export default function Landing() {
    let navigate = useNavigate();

    return (
        <>
        <div className = "section section-light-bg landing-container">

            <div className="landing-text-container">
                <h1 className = "landing-title">Fazer um bom cursinho não é o suficiente.</h1>

                <p className = "landing-subtitle">A vaga fica com quem estuda de modo mais inteligente. Junte-se aos nossos milhares de alunos.</p>
            </div>

            <div className="landing-demo">
                <img className="landing-demo-img" src={Eduarda3} />


            </div>
        
        </div>
        </>
    )
}
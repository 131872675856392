import React from 'react'
import DemoMobile from './../assets/videos/landing_mockup2.png'

import './WhyOsler.css'

import DemografiaImage from './../assets/demografia.png'
import Explanation from './Explanation'
import SectionTitle from './SectionTitle'

export default function WhyOsler() {

    return (
        <div className = "section section-light-bg section-full-height about-container">

            <Explanation
                text = {<>
                <h1>O cenário no Brasil só piora</h1>

                <p><strong>3x mais faculdades.</strong> Fomos de menos de 100 para atuais 389. <u>A proibição contra novos cursos foi derrubada</u>, e +180 serão criados.</p>

                <p><strong>Explosão de médicos recém-formados.</strong> E vai piorar porque muitas das turmas criadas não se formaram e há faculdades aumentando números de alunos.</p>

                <p><strong>O número de vagas de residência não aumentou.</strong> Para complicar, todos querem as mesmas poucas instituições de excelência.</p>

                <p><strong>Você não compete só com quem está no 6º ano.</strong> O pessoal que não passou nos anos anteriores está prestando de novo — e estudando em tempo integral.</p>

                <p><strong>Não fazer residência é loucura.</strong> Trabalhar como generalista já está difícil: muitos médicos, poucos plantões, condições insatisfatórias, CRM em risco, fadado a receber R$100/hora em jornadas de 12h. Tudo bem por uns anos, mas não para o resto da vida.</p>                
                </>}

                img = {<><img className="about-img" src={DemografiaImage} /></>} 
                
                
                reverseMobile = {true}
                
                />
                        


            <Explanation
                text = {<><h1>A solução em 6 passos</h1>

                <p><strong>1. Ter feito medicina não significa que sua vida está resolvida.</strong> Lamento.</p>

                <p><strong>2. Sua faculdade e seu QI não definem seu futuro.</strong> Assuma responsabilidade.</p>

                <p><strong>3. A solução é estar entre os melhores.</strong> Competição é uma 💩, mas não adianta maquiar a verdade.</p>

                <p><strong>4. Só cursinho não resolve</strong> — todo mundo faz, geralmente por mais de um ano, e mais de um. Assistir 4 horas de aula de "Bacia Obstétrica" em um auditório é anacrônico, e até os cursinhos "modernos" já viraram o mínimo.</p>

                <p><strong>5. Aproveite o internato.</strong> Antigamente, valia a mentalidade do "sacrificar o internato para garantir a aprovação". Hoje, quem sacrifica o internato não é aprovado.</p>

                <p><strong>6. Como passar na frente da concorrência?</strong> Ter um método de estudos melhor. Usar um material mais aprofundado. E sentar a bunda na cadeira.</p></>}


                img = {<><img className="about-img" src={DemoMobile} /></>} 
                

                reverseMobile = {true}
                
                />
        </div>
    )
}
import { db } from './firebase-setup'


export default async function getDoc( doc_ref ) {
    try {
        const doc = await doc_ref.get()
        return doc.data()
    }
    catch (error) {
        console.log("getDoc(): error downloading document: " + doc_ref.id)
        console.log(error)
        return -1
    }
}



export default class TreeNode {
    constructor(tag, info, parent, tagSequence) {
        this.parent   = parent;
        this.children = undefined;

        this.isChecked  = false;
        this.isMildlyChecked = false;
        this.isExpanded = false; 

        this.title = tag

        this.tagSequence = tagSequence 
        this.depth = tagSequence.length - 1


        this.visible  = false;
        this.searched = false;

        // Foi "filtered out" por satisfyFilteredCondition, não será exibido
        // (visible é false), mas, mais que isso, não deve ser considerado
        this.filtered = false;

        this.info = info
    }


    setChildren(children) {
        this.children = children
    }


    setBeingVisible(visible) {
        this.visible = visible
    }


    setBeingSearched(searched) {
        this.searched = searched
    }

    setBeingFiltered(filtered) {
        this.filtered = filtered
    }

    
    resetAll() {
        // Isso era utilizado quando queríamos, durante variações na string buscada, queríamos
        // resetar todos os checked. Mas não fazemos mais isso.
        this.isChecked  = false;
        this.isMildlyChecked = false;
        this.isExpanded = false; 
        this.visible  = false;
        this.searched = false;
        this.filtered = false;
    }



    changeIsExpanded() {
        this.isExpanded = !this.isExpanded
        if (!this.isExpanded) {
            this.collapseAllChildren()
        }
    }


    expandParents() {
        console.log('Avaliando para ' + this.title)
        if (!this.isExpanded) {
            console.log('Não está expandido')
            this.isExpanded = true

            if (this.parent) {
                console.log('Vamos chamar o pai')
                this.parent.expandParents()
            }
        }
    }


    collapseAllChildren() {
        if (this.children) {
            for (let child of this.children) {
                child.isExpanded = false;
                child.collapseAllChildren()
            }
        }
    }


    changeIsChecked(calledByParent = false) {
        this.modifyIsChecked( !this.isChecked )

        if (this.parent && !calledByParent) {
            this.parent.verifyIfChildrenChecked()
        }

        // Se é clicado na busca, após o termo buscado ser eliminado, a árvore colaba, e os pais
        // não estão expanded. Precisamos corrigir isso.
        if (this.parent) {
            this.parent.expandParents()
        }
    }

    
    modifyIsChecked(toWhat) {
        this.isChecked = toWhat;
        this.isMildlyChecked = false;

        if (this.children) {
            for (let child of this.children) {
                if (!child.filtered) {
                    child.modifyIsChecked(toWhat)
                }
            }
        }
    }


    verifyIfChildrenChecked() {
        let anyChecked = false;
        let anyUnchecked = false;
        let anyMildlyChecked = false;

        if (this.children) {
            for (let child of this.children) {
                if (child.isChecked) {
                    anyChecked = true;
                }
                else if (child.isMildlyChecked) {
                    anyMildlyChecked = true;
                    anyUnchecked = true;
                }
                else {
                    anyUnchecked = true;
                }
            }
        }

        const allChildrenChecked = !anyUnchecked;

        if ( allChildrenChecked ) {
            this.isChecked = true;
        }
        else if ( anyChecked || anyMildlyChecked) {
            this.isChecked = false;
            this.isMildlyChecked = true;
        }
        else {
            this.isChecked = false;
            this.isMildlyChecked = false;
        }

        // Propagamos para cima
        if (this.parent) {
            this.parent.verifyIfChildrenChecked()
        }
    }
}
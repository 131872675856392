import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Checkbox from './Checkbox';
import ExpandCollapse from './ExpandCollapse';
import FullStatusRow from './FullStatusRow.js';
import { TreeFilters } from './Tree.js';


const StyledTreeNodeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;
    box-sizing: border-box;
    height: ${({ depth }) => (depth === 0 ? '5em' : '1.5em')};
    padding-left: ${({ depth }) => `${depth * 2}em`};

    @media (max-width: 600px) {
        width: 96%;
        margin: 0 2% 0.5em 2%;
        padding-left: ${({ depth }) => `${depth}em`};
    }


    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200)
    `:`
        color: black;
    `}
`



const SearchedNode = css`
    margin: 0;
    margin-left: 0.5em;
    border-radius: 0.5em;
    background-color: rgb(39, 120, 196, 0.3);
    padding: 0.4em 0.8em;
    cursor: default;    
`

const StyledTreeNodeFull = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 0.6em;
    z-index: 1;
    cursor: pointer;

    ${props => props.searched && SearchedNode}

    @media (max-width: 600px) {
        padding-left: 0.2em;
    }

`



const StyledTreeNodeShort = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0.5em;
`

const StyledTreeNodeShortClickable = styled.div`
    cursor: pointer;

    ${props => props.searched && SearchedNode}
    
`

const StyledTagTitle = styled.p`
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    padding: 0;

    @media (max-width: 600px) {
        font-size: 0.7em;
    }
`

const StyledTreeNodeShortInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`



export default function TreeNodeJSX(props) {
    const [mode, setMode] = useState(false)
    const [clickable, setClickable] = useState(false)
    
    const [newTests, setNewTests] = useState()
    const [pendingReviews, setPendingReviews] = useState()
    const [futureReviews, setFutureReviews] = useState()
    const [solved, setSolved] = useState()

    useEffect(() => {
        setMode(props.mode)

        const hasTests = props.node.info.availableTests > 0 || props.node.info.pendingReviews > 0
        const notTestMode = props.mode === 'playground-mode' || props.mode === 'consult-mode' || props.mode === 'clear-history'
        setClickable(hasTests || notTestMode)
        
        setNewTests(props.node.info.availableTests)
        setPendingReviews(props.node.info.pendingReviews)
        setFutureReviews(props.node.info.futureReviews)

        if (props.testType === 'Residencia') {
            setSolved(props.node.info.solved)
        }
        else {
            setSolved(0)
        }
    }, [])



    function checkboxClicked() {
        props.node.changeIsChecked()
        props.triggerNewRender()
    }


    function collapseExpandClicked() {
        props.node.changeIsExpanded()
        props.triggerNewRender()
    }

    
    function textAreaClicked() {
        // Se o usuário clica sobre o texto, nós expandimos/colapsamos -- desde que não haja 
        // pesquisa ativa e existam filhos.
        //
        // Do contrário, se não há filhos (i.e., é último nó) ou existe pesquisa ativa,
        // clicar sobre o título ativa o checkbox.
        if (!props.userIsSearching && props.node.children) {
            collapseExpandClicked()
        } 
        else {
            if ( clickable ) {
                checkboxClicked()
            }
        }
    }


    function getInformation() {
        if (props.node.depth === 0) {
            return getExpandedInfo()
        }
        else {
            return getShortenedInfo()
        }
    }

    function getExpandedInfo() {
        return (
            <StyledTreeNodeFull searched = {props.node.searched} onClick = {textAreaClicked} >
                <StyledTagTitle>{props.node.title}</StyledTagTitle>
                {getFullStatusRow('')}
            </StyledTreeNodeFull>
        )
    }

    function getShortenedInfo() {
        return (
            <StyledTreeNodeShort>
                <StyledTreeNodeShortClickable
                    searched = {props.node.searched}
                    onClick={textAreaClicked}
                >
                    <StyledTagTitle>{props.node.title}</StyledTagTitle>
                </StyledTreeNodeShortClickable>
                {getFullStatusRow('shortened')}
            </StyledTreeNodeShort>
        )
    }


    function getFullStatusRow(givenStyle) {

        if (mode === 'consult-mode' || mode === 'playground-mode') {
            return (
                <FullStatusRow
                    testType = {props.testType}
                    depth={props.node.depth}
                    mode={mode}
                    nTests={newTests + pendingReviews + futureReviews} />
            )
        }
        else if (mode === 'clear-history') {
            return (
                <FullStatusRow
                    testType = {props.testType}
                    depth={props.node.depth}
                    mode={mode}
                    nTests={pendingReviews + futureReviews + solved} />
            )
        }
        else if (pendingReviews === 0 && newTests === 0) {
            return <FullStatusRow testType = {props.testType} depth={props.node.depth} mode="allDone" />;
        }
        else {
            if (givenStyle == 'shortened') {
                return (<StyledTreeNodeShortInfo className={givenStyle}>
                    {!(props.filter == TreeFilters.NEW_TESTS_ONLY) && pendingReviews > 0 && (
                        <FullStatusRow
                            testType = {props.testType}
                            filter = {props.filter}
                            depth={props.node.depth}
                            rowType="review"
                            nTests={pendingReviews}
                        />
                    )}
                    {!(props.filter == TreeFilters.PENDING_REVIEWS_ONLY) && newTests > 0 && (
                        <FullStatusRow
                            testType = {props.testType}
                            depth={props.node.depth}
                            rowType="new"
                            nTests={newTests}
                        />
                    )}
                </StyledTreeNodeShortInfo>
                )
            }
            else {
                return (
                    <StyledTreeNodeFull className={givenStyle}>
                        {!(props.filter == TreeFilters.NEW_TESTS_ONLY) && pendingReviews > 0 && (
                            <FullStatusRow
                                testType = {props.testType}
                                filter = {props.filter}
                                depth={props.node.depth}
                                rowType="review"
                                nTests={pendingReviews}
                            />
                        )}
                        {!(props.filter == TreeFilters.PENDING_REVIEWS_ONLY) && newTests > 0 && (
                            <FullStatusRow
                                testType = {props.testType}                            
                                depth={props.node.depth}
                                rowType="new"
                                nTests={newTests}
                            />
                        )}
                    </StyledTreeNodeFull>
                )
            }

        }
    }


    return (
        <>
            {props.node && (
                <StyledTreeNodeContainer depth = {props.node.depth} >
                    { clickable && (
                        <Checkbox
                            checked={props.node.isChecked}
                            mildlyChecked={props.node.isMildlyChecked}
                            action={checkboxClicked}
                        />
                    )}
                    {props.node.children && !props.userIsSearching && (
                        <ExpandCollapse
                            expanded={props.node.isExpanded}
                            action={collapseExpandClicked}
                        />
                    )}
                    {getInformation()}
                </StyledTreeNodeContainer>
            )}
        </>
    )
}
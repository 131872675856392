import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { toastMsg } from '../utils/Utils'


export default function DeleteFilterDialog(props) {


    function handleClose() {
        props.setShowDialog(false)
    }
    
    function handleOk() {
        props.deleteFilters()
    }

    return (
        <Dialog open = {props.showDialog} onClose = {handleClose}>
            <DialogTitle>⚠️ <strong>Deletar filtro</strong></DialogTitle>
            <DialogContent>
                <p>Iremos deletar excluir o(s) filtro(s) selecionado(s).</p>

                <p>Essa ação é irreversível.</p>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleOk} variant="contained" color="error">Deletar</Button>
            </DialogActions>
        </Dialog>
    )
}
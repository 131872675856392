import React from 'react'
import styled from 'styled-components'


const NonSelected = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 90%;


    padding: 0.6em;
    border-radius: 0.75em;
    margin-top: 1.2em;


    ${props => props.theme.darkMode ? `
        background-color: rgb(90, 90, 90);
        color: rgb(200, 200, 200);
    ` : `
        background-color: white;
        color: black;
        border: solid 1px rgba(226, 226, 226, 1);
    `}
`;


const Selected = styled(NonSelected)`
    ${props => props.theme.darkMode ? `
        background-color: rgb(130, 130, 130);
    ` : `
        background-color: rgba(226, 226, 226, 0.9);
    `}
`;


const Icon = styled.img`
    width: 40px;
`

const Text = styled.p`
    font-weight: bold;
    font-size: 1em;
    margin: 0 0 0 0.6em;

    @media (max-width: 450px) {
        font-size: 0.85em;
    }
`


export default function MobileBarButton({selected, action, text, icon, expanded}) {
    const Container = selected ? Selected : NonSelected
  
    return (
            <Container onClick={action} key={text}>
                <Icon src={icon} />
                <Text>{text}</Text>
            </Container>
    )
  }


import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Balloons from './../assets/tree/balloons.png'
import ResidenciaIcon from './../assets/residencia.png';
import FlashcardIcon from './../assets/flashcard.png';
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'
import { darkBackgroundQuarternaryColor, darkBackgroundQuintenaryColor, darkBackgroundSextenaryColor, darkBackgroundTertiaryColor, darkModeGreen } from '../tests/FlashcardsStyles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    // Fundamental para alinhar no desktop!!!
    width: 100%;

    ${props => props.checked ? 
    `
        justify-content: flex-end;
    `:`
        justify-content: flex-start;
    `}

    height: 2.5em;

    @media (max-width: 900px) {
        margin-bottom: 0.25em;
        margin-top: 0.25em;
    }

    @media (max-width: 500px) {
        height: auto;
        margin-bottom: 0.25em;
        margin-top: 0.25em;
    }
    
`


const TypeIconDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    margin-right: 0.25em;

    @media (max-width: 500px) {
        justify-content: center;
        width: 20%;
        margin: 0;
    }
`

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 0.5em;

    background-color: ${props => props.checked ? `none` : (props.theme.darkMode ? `${darkBackgroundQuintenaryColor}` : `rgba(235, 235, 235, 0.6)`)};


    &:hover {
        ${props => !props.checked && `
            background-color: ${props.theme.darkMode ? `${darkBackgroundSextenaryColor}` : `rgba(235, 235, 235, 0.9)`};
            cursor: pointer;
        `}
    }



    width: 70%;
    justify-content: space-evenly;

    padding: 0.2em 0.4em 0.2em 0.4em;

    height: 100%;

    @media (max-width: 900px) {
    }

    @media (max-width: 500px) {
        width: 80%;
    }

`


const Check = styled.p`
    margin: 0;
    font-size: 1.1em;
`


const Icon = styled.img`
    margin: 0;
    height: 1em;
`




const CenteredIcons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;

    background-color: ${props => props.theme.darkMode ? darkModeGreen : `rgba(87, 191, 65, 0.9)`};
    border-radius: 0.5em;
            
    height: 100%;

    @media (max-width: 500px) {
    }
`

const SmallerIcon = styled.img`
    height: 2.0em;
    margin-left: 0.25em;
    margin-right: 0.25em;
`

const TypeIcon = styled.img`
    height: 1.5em;
    // align-self: flex-end;
`


const GroupContainer = styled.div`
    display: flex;
    align-items: center;
`


const GroupText = styled.p`
    margin: 0;
    font-size: 0.85em;

    font-weight: bold;
    color: ${props => props.color};
    margin-left: 0.35em;

    @media (max-width: 900px) {
        font-size: 0.75em;
    }

    @media (max-width: 500px) {
        font-size: 0.75em;
    }
    // color: red;
`


const Plus = styled.p`
    margin: 0;
    color: black;
`



/*
    Muito similar a ActivityIcon, mas aqui inicia o step, i.e., todas as atividades de um mesmo
    dia.

    Para dar sentido à tela, o botão aqui é maior e tem ícones. Ademais, consultar lá.
*/

function Group({ icon, text, color }) {
    return (
        <GroupContainer>
            <Icon src={icon} />
            <GroupText color={color}>{text}</GroupText>
        </GroupContainer>
    )
}

export default function ModuleAction({ activitities, testType, startTestModeSession }) {
    const [newTests, setNewTests] = useState(0)
    const [reviewTests, setReviewTests] = useState(0)
    const [hasTests, setHasTests] = useState(false)
    const [checked, setChecked] = useState(false)

    const testIDs = useRef([])

    useEffect(() => {
        let nTagpaths = 0
        let news = 0
        let reviews = 0
        let IDs = []

        activitities.forEach(activity => {
            const newTests = activity['new' + testType] ?? []
            const reviewTests = activity['review' + testType] ?? []

            news += newTests.length
            reviews += reviewTests.length

            nTagpaths += activity[`${testType.toLowerCase()}_tagpaths`]?.length ?? 0
            IDs = IDs.concat(newTests).concat(reviewTests)
        })


        setNewTests(news)
        setReviewTests(reviews)
        setHasTests(nTagpaths > 0)
        setChecked(news === 0 && reviews === 0)
        testIDs.current = IDs



    }, [activitities, testType])


    function start() {
        const ordered = (testType === 'Flashcards' ? true : false)
        startTestModeSession(testType, testIDs.current, ordered)
    }


    
    return (
        <Container checked = {checked}>
            {hasTests && !checked &&
            <>
                    <TypeIconDiv>
                        <TypeIcon src={testType === 'Flashcards' ? FlashcardIcon : ResidenciaIcon} />
                    </TypeIconDiv>

                    
                     
                    <ActionContainer checked = {reviewTests == 0 && newTests == 0} onClick={start}>
                        {newTests !== 0 && 
                            <Group icon={Notebook} text={newTests} color="#3d3d3d" />
                        }
                        { newTests !== 0 && reviewTests !== 0 &&
                            <Plus>+</Plus>
                        }
                        {reviewTests !== 0 &&
                            <Group icon={Dumbbell} text={reviewTests} color="#8E0011" />
                        }
                        {reviewTests === 0 && newTests === 0 &&
                            <Check>✅</Check>
                        }
                    </ActionContainer>
            </>
            }
            { hasTests && checked &&
                    <CenteredIcons>
                        <SmallerIcon src = {testType === 'Flashcards' ? FlashcardIcon : ResidenciaIcon} />
                        <SmallerIcon src = {Balloons} />
                    </CenteredIcons>
            }

        </Container>
    )
}

import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components';
import UserReviewsInfo from '../controllers/UserReviewsInfo';
import OslerData, { KEYS } from '../controllers/OslerData';
import { getFlashcardDeckRoot, pathToTagSequence, tagSequenceToPathS, toastMsg } from '../utils/Utils';
import TileGrid from './TileGrid';
import TreeJSX from '../custom/TreeJSX';
import Tree, { TreeFilters } from '../custom/Tree';
import AppContainer from '../app-container/AppContainer';
import ScreenContainer from '../components/ScreenContainer';
import ScreenCard from '../components/ScreenCard';
import { useNavigate } from 'react-router-dom';
import DangerIcon from '../assets/danger.png'
import OslerButton from '../components/OslerButton';
import GeneralDialog from '../tests/GeneralDialog';
import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import { getTestRoot, hashTestID, hashTestIDToSolved } from '../controllers/HashTestID';
import LikedBuriedController from '../controllers/LikedBuriedController';
import { setIsLoading } from '../redux/loadingSlice';
import { db } from '../firebase/firebase-setup'
import firebase from 'firebase/compat/app'
import { loadAppData } from '../services/LoadAppData';
import { blue } from '../tests/FlashcardsStyles';
import { darkBackgroundTertiaryColor, darkTextSecondaryColor } from '../tests/FlashcardsStyles';
import MistakesJournalController from '../controllers/MistakesJournalController';
import PersonalNotes from '../controllers/PersonalNotes';




const ConsentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5em;

    font-size: 1.1em;
    text-align: justify;
`

const ConsentTitle = styled.p`
    font-size: 1.5em;
    color: ${blue};
    text-align: center;
    font-weight: bold;
`


const ConsentBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 3em;
    width: 60%;
    align-self: center;

    @media (max-width: 900px) {
        padding: 1em;
        width: 100%;
    }
`

const TestTypeBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    padding: 0em 2em 2em 2em;

    @media (max-width: 500px) {
        padding: 0;
        justify-content: space-between;
        margin-bottom: 2em;
    }
`



const Alert = styled.p`
    font-size: 1.2em;
    font-weight: bold;
`

const ClearHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 100%;
    padding: 2em 2em 0 2em;

    text-align: justify;

    @media (max-width: 500px) {
        padding: 0.5em;
    }
`


const TreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    width: 100%;
    margin-top: 2em;
    margin-bottom: 0.5em;
    padding: 0em 2em 0em 2em;

    text-align: justify;

    border-radius: 1em;
    
    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundTertiaryColor}
    ` : `
        background-color: #f5f5f5;
    `


    }
`

const TreeDeletionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    width: 90%;

    @media (max-width: 500px) {
        width: 100%;    
    }
`

const TotalDeletionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    width: 100%;

    margin-top: 3em;
    margin-bottom: 1em;

    text-align: justify;

    border-radius: 1em;
`

const FromZero = styled.p`
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;

    ${props => props.theme.darkMode ? `
        color: ${darkTextSecondaryColor};
    `:`
        color: black;
    `}
`

const SelectedTestType = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    margin-top: 1.5em;
    margin-bottom: 0.5em;
`

const SelectedColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 2em;
`


const SelectedTxt = styled.p`
    margin: -0.3em 0 0 0;
    font-size: 1.1em;
`
const SelectedSpan = styled.p`
    font-size: 0.6em;
    margin: 0;
    color: gray;
`

const SelectedImg = styled.img`
    width: 2em;
`

const Helper = styled.p`
    font-size: 0.9em;
    font-weight: bold;
    color: gray;
    font-style: italic;
`

const FinalSuccess = styled.p`
    font-size: 1.2em;
    text-align: center;
    color: green;
    font-weight: bold;
`

const FinalFailure = styled.p`
    font-size: 1.2em;
    text-align: center;
    color: red;
    font-weight: bold;
`

export default function ClearHistory(props) {
    const navigate = useNavigate()

    const [erasableDecks, setErasableDecks] = useState(undefined)
    const [consent, setConsent] = useState(false)
    const [testType, setTestType] = useState(false)
    const [showDeleteThemesDialog, setShowDeleteThemesDialog] = useState(false)
    const [showDeleteAllDialog, setShowDeleteAllDialog] = useState(false)

    const [hasDeletableStuff, setHasDeletableStuff] = useState(false)

    const [finalResult, setFinalResult] = useState(false)

    const dispatch = useDispatch()

    const [dataReady, setDataReady] = useState(false)

    const user = useSelector(state => state.user.data)

    // useEffect(() => {
    //     const reviews = UserReviewsInfo.allReviews
    //     const decksToRemove = {}

    //     Object.keys(reviews['Flashcards']).forEach(testID => {
    //         const root = getFlashcardDeckRoot(testID)
    //         const tagpath = OslerData.data[KEYS.FLASHCARDS][KEYS.TAGPATH_PER_ID][root]

    //         if (decksToRemove[root] === undefined) {
    //             decksToRemove[root] = {
    //                 count: 1,
    //                 tagpath: tagpath,
    //                 reviews: [testID]
    //             }
    //         }
    //         else {
    //             decksToRemove[root].count += 1
    //             decksToRemove[root].reviews.push(testID)
    //         }

    //     setErasableDecks(decksToRemove)
    //     })
    // }, [])


    useEffect(() => {
        // SE NÃO A ÁRVORE NÃO CARREGA
        
        console.log(UserReviewsInfo.loaded)
        setDataReady(UserReviewsInfo.loaded)
    }, [])


    function goBack() {
        if (!consent) {
            navigate('/user')
        }
        else if (!finalResult) {
            setTestType(false)
            setConsent(false)
        }
        else if (finalResult) {
            setTestType(false)
            setFinalResult(false)
        }
    }




    function changeTestType(newTestType) {
        
        const dict =  UserReviewsInfo.info[newTestType]

        console.log(UserReviewsInfo.info)
        console.log(newTestType)

        console.log(UserReviewsInfo.info[newTestType])

        let nDeletableTests = (dict.pendingReviews?.length ?? 0) + (dict.futureReviews?.length ?? 0);

        if (newTestType === 'Residencia') {
            nDeletableTests += (dict.solved?.length ?? 0);
        }

        console.log(nDeletableTests)
        
        setTestType(newTestType)
        setHasDeletableStuff(nDeletableTests > 0)
    }


    async function deleteThemes() {
        dispatch(setIsLoading(true))
        
        if (testType === 'Flashcards') {
            await deleteFlashcardsDecks()
        }
        else {
            await deleteResidenciaThemes()
        }

        setShowDeleteThemesDialog(false)
        dispatch(setIsLoading(false))
    }


    async function deleteFlashcardsDecks() {
        // const checkedTagpaths = Tree.extractCheckedNodes()

        const checkedTagpaths = Tree.extractCheckedLeafNodes()

        /* 
            Cada tagpath pode representar um deck ou um conjunto de tags.
                Pediatria/Infectologia/Infecções Respiratórias -> conjunto de decks
                Pediatria/Puericultura/Hebiatria/Alterações da Puberdade -> deck específico

            Nós só lidaremos com decks específicos. Mas, como a árvore já está filtrada,
            os decks específicos de cada conjunto já estão listadas aqui.
        */

        const tagpathToRoot = {}
        for (const key in OslerData.data[KEYS.FLASHCARDS][KEYS.TAGPATH_PER_ID]) {
            const value = OslerData.data[KEYS.FLASHCARDS][KEYS.TAGPATH_PER_ID][key];
            tagpathToRoot[value] = key;
        }

        const decksToRemove = []
        for (const checked_path of checkedTagpaths) {
            if (tagpathToRoot[checked_path] !== undefined) {
                const root = tagpathToRoot[checked_path]

                // Não adianta eu ver quantos testes tem no deck. Eu preciso ver quantos
                // testes do deck o aluno já fez, e saber quais são.
                const deckTests = OslerData.data[KEYS.FLASHCARDS][KEYS.TESTS_PER_TAGPATH][checked_path]

                const deckReviews = []

                for (const testID of deckTests) {
                    if (OslerData.data[KEYS.FLASHCARDS][KEYS.REVIEWS_INDEXED][testID]) {
                        deckReviews.push(testID)
                    }
                }

                if (decksToRemove[root] === undefined) {
                    decksToRemove.push({
                        root: root,
                        tagpath: checked_path,
                        reviewsIDs: deckReviews
                    })
                }
            }
        }


        const operations = []

        // Documentos de estatísticas
        // isso aqui já não é checked.????
        // const tagpaths = decksToRemove.map(deck => deck['tagpath'])
        
        const overallStats = `users/${user.id}/${testType}/statistics/assorted/overallStatistics`
        await decrementStatisticsDoc(overallStats, checkedTagpaths, operations)


        const nEachType = `users/${user.id}/${testType}/statistics/assorted/nEachTypeTest`
        await decrementStatisticsDoc(nEachType, checkedTagpaths, operations)
    


        // Documentos de revisões
        const testsIDs = decksToRemove.flatMap(deck => deck['reviewsIDs'])

        const reviewsCollection = `users/${user.id}/${testType}/tests/reviewed tests`
        deleteTestIDsReview(reviewsCollection, testsIDs, operations)
 
 
        const msPerIdCollection = `users/${user.id}/${testType}/tests/reviews_indexed` 
        deleteTestsFromMsPerId(msPerIdCollection, testsIDs, operations)


        // Liked and Buried
        const likedTestsDoc = `users/${user.id}/personal/${testType.toLowerCase()}-liked`
        deleteLikedBuried(likedTestsDoc, testsIDs, operations, 'liked')

        const buriedTestsDoc = `users/${user.id}/personal/${testType.toLowerCase()}-buried`
        deleteLikedBuried(buriedTestsDoc, testsIDs, operations, 'buried')
        
        // Listas
        const deckListsCollection = `users/${user.id}/${testType}/lists/deck_lists`
        deleteDeckLists(deckListsCollection, decksToRemove, operations)


        deletePostIts(testsIDs, operations)


        // Escrevemos todas as operações.
        await batchOperationsAndReload(operations)
    }


    async function deleteResidenciaThemes() {
        // const checkedTagpaths = Tree.extractCheckedNodes()

        const checkedTagpaths = Tree.extractCheckedLeafNodes()

        // Tudo isso para obter, dentre um tema, quais são os testes com revisões pendentes, mas
        // a própria árvore já não tem isso?! enfim... além disso, tudo bem que são coisas rápidas
        // e etc, mas realmente acho que isso já deveria estar compilado em OslerData
        const reviewsIDs = []
        const solvedIDs = []

        checkedTagpaths.forEach(tagpath => {
            const allIDs = OslerData.data[KEYS.RESIDENCIA][KEYS.TESTS_PER_TAGPATH][tagpath]
            
            if (allIDs) {
                allIDs.forEach(testID => {
                    if (OslerData.data[KEYS.RESIDENCIA][KEYS.REVIEWS_INDEXED][testID]) {
                        reviewsIDs.push(testID)
                    }
                    else if (OslerData.data[KEYS.RESIDENCIA][KEYS.SOLVED][testID]) {
                        solvedIDs.push(testID)
                    }
                })
            }
        })
        

        const allIDs = [...reviewsIDs, ...solvedIDs]


        // Todas as etapas
        const operations = []


        const overallStats = `users/${user.id}/${testType}/statistics/assorted/overallStatistics`
        await decrementStatisticsDoc(overallStats, checkedTagpaths, operations)

        // const nEachType = `users/${user.id}/${testType}/statistics/assorted/nEachTypeTest`
        // await decrementStatisticsDoc(nEachType, checkedTagpaths, operations)

        console.log('Prosseguindo após testes...')

        const reviewsCollection = `users/${user.id}/${testType}/tests/reviewed tests`
        deleteTestIDsReview(reviewsCollection, allIDs, operations)
 
 
        const msPerIdCollection = `users/${user.id}/${testType}/tests/reviews_indexed` 
        deleteTestsFromMsPerId(msPerIdCollection, reviewsIDs, operations)

        const solvedPerIdCollection = `users/${user.id}/Residencia/tests/solved_questions` 
        deleteQuestionsFromSolvedPerId(solvedPerIdCollection, solvedIDs, operations)

        const likedTestsDoc = `users/${user.id}/personal/${testType.toLowerCase()}-liked`
        deleteLikedBuried(likedTestsDoc, allIDs, operations, 'liked')

        const buriedTestsDoc = `users/${user.id}/personal/${testType.toLowerCase()}-buried`
        deleteLikedBuried(buriedTestsDoc, allIDs, operations, 'buried')
        
        deleteResidenciaLists(allIDs, operations)

        deleteMistakesJournal(allIDs, operations)

        deletePostIts(allIDs, operations)

        // return 
        await batchOperationsAndReload(operations)
    }


    async function deleteResidenciaLists(testsIDs, operations) {

        const groupedByExam = {}
        const groupedByInstitution = {}
        const groupedByTagpath = {}
        const groupedByTagpathExtensivo = {}

        const institutionIDtoName = {}
        Object.entries(OslerData.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS_IDS]).forEach(([key, value]) => {
            institutionIDtoName[value] = key;
        })


        testsIDs.forEach(testID => {
            // Lembrando que é do tipo "residencia_EDMUNDO_2019_R1_Q98"
            // Derivamos todos os dados necessários
            const parts = testID.split('_')
            const institutionID = parts[1]
            const year = parts[2]

            const institutionName = institutionIDtoName[institutionID]
            const exam = `${institutionName}_${year}`

            // É meio tolo, mas ele corta o RESIDENCIA_ de antes...
            const adjstedID = testID.replace("residencia_", "")

            console.log(testID)
            console.log(OslerData.data[KEYS.RESIDENCIA][KEYS.TAGPATH_PER_ID])


            const tagpath = OslerData.data[KEYS.RESIDENCIA][KEYS.TAGPATH_PER_ID][adjstedID]

            console.log(tagpath)

            const ajustedTagpath = tagpath.replaceAll('/', '|')
            const isExtensivo = OslerData.data[KEYS.RESIDENCIA][KEYS.EXTENSIVO][KEYS.ALL_IDS][testID]

            console.log(ajustedTagpath)
            console.log(isExtensivo)

            // Muito similar ao que é feito em updateResidenciaTestLists()
            addToDict(groupedByExam, exam, testID)
            addToDict(groupedByInstitution, institutionName, testID)
            addToDict(groupedByTagpath, ajustedTagpath, testID)

            if (isExtensivo) {
                addToDict(groupedByTagpathExtensivo, ajustedTagpath, testID)
            }
        })

        removeFromResidenciaList(`exams_lists`, groupedByExam, operations)
        removeFromResidenciaList(`institutions_lists`, groupedByInstitution, operations)
        removeFromResidenciaList(`tagpaths_lists`, groupedByTagpath, operations)
        removeFromResidenciaList(`extensivo_tagpaths_lists`, groupedByTagpathExtensivo, operations)
    }


    function addToDict(dict, key, value) {
        // TODO Deveria estar em Utils, parece claro
        if (!dict[key]) {
            dict[key] = []
        }
        dict[key].push(value)
    }


    function removeFromResidenciaList(docName, groupedTests, operations) {
        for (let document in groupedTests) {
            const update = {}
            const docRef = `users/${user.id}/Residencia/lists/${docName}/${document}`

            groupedTests[document].forEach(testID => {
                update[testID] = firebase.firestore.FieldValue.delete()
            })

            operations.push({
                type: 'update',
                path: docRef,
                data: update
            })
        }
    }
    


    async function deleteAll() {    
        let documents = [
            `users/${user.id}/${testType}/statistics/assorted/nEachTypeTest`,
            `users/${user.id}/${testType}/statistics/assorted/overallStatistics`,
            `users/${user.id}/personal/${testType.toLowerCase()}-liked`,
            `users/${user.id}/personal/${testType.toLowerCase()}-buried`,
        ]

        let collections = [
            `users/${user.id}/${testType}/tests/reviewed tests`,
            `users/${user.id}/${testType}/tests/reviews_indexed`,
            `users/${user.id}/${testType}/statistics/daily`,

            // Não adiantar apagar o documento que contém coleções -> precisa apagar as coleções
            // contidas nele.
            `users/${user.id}/${testType}/post_its/metadata`,
            `users/${user.id}/${testType}/post_its/tests_post_its`
        ]


        if (testType === 'Flashcards') {
            collections.push(`users/${user.id}/${testType}/lists/deck_lists`)
        }
        else {
            collections.push(`users/${user.id}/${testType}/lists/exams_lists`)
            collections.push(`users/${user.id}/${testType}/lists/extensivo_tagpaths_lists`)
            collections.push(`users/${user.id}/${testType}/lists/institutions_lists`)
            collections.push(`users/${user.id}/${testType}/lists/tagpaths_lists`)
            collections.push(`users/${user.id}/${testType}/tests/solved_questions`)

            documents.push(`users/${user.id}/${testType}/mistakes_journal`)
        }

        const operations = []

        dispatch(setIsLoading(true))

        await deleteTestType(collections, documents, operations)

        await batchOperationsAndReload(operations)

        setShowDeleteAllDialog(false)
        dispatch(setIsLoading(false))
    }   


    async function deleteTestType(collections, documents, operations) {
        for (const collRef of collections) {
            const querySnapshot = await db.collection(collRef).get()
            
            // console.log(collRef)

            querySnapshot.forEach(doc => {
                // console.log(doc.ref.path)
                operations.push({
                    type: 'delete',
                    data: doc.data(),
                    path: doc.ref.path
                })
            })
        }
    
        documents.forEach(docRef => {
            // console.log(docRef)
            operations.push({
                type: 'delete',
                path: docRef
            })
        })
    }


    function estimateDocumentSize(data, path) {
        // Converte os dados do documento para uma string JSON
        if (data) {
            const jsonString = JSON.stringify(data)
     
            // Calcula o tamanho em bytes (assumindo UTF-8)
            const dataSize = new TextEncoder().encode(jsonString).length
            
            // Adiciona o tamanho do caminho do documento
            const pathSize = path.length
            
            // Adiciona um pequeno overhead para metadados (ajuste conforme necessário)
            const overhead = 32
            
            return dataSize + pathSize + overhead;
        }
        else {
            return 1 * 1024 * 1024
        }
    }



    async function batchOperationsAndReload(operations) {
        const batches = []
        let currentBatch = db.batch()
     

        const maxBatchOps = 490

        // Ficava dando erro de limite
        // Eu acho um exagero fazer 3MiB ao invés de 10MiB, considera-se que estamos errando
        // o tamanho dos docs em 3x, mas foi a única coisa que fez parar de dar erro.
        const maxBatchSize = 3 * 1024 * 1024 

        let currentBatchOperations = 0
        let currentBatchSize = 0

        for (const op of operations) {
            const opSize = estimateDocumentSize(op.data, op.path)
                
            console.log(`${op.path} - ${opSize}`)

            const exceededNum  = (currentBatchOperations + 1 > maxBatchOps)
            const exceededSize = (currentBatchSize + opSize > maxBatchSize)

            console.log(`\t${currentBatchSize + opSize} vs ${maxBatchSize}`)
            console.log(`\t${currentBatchOperations + 1} vs ${maxBatchOps}`)

            if (exceededNum || exceededSize) {
                console.log(`Quebramos com ${currentBatchOperations} operações e ${currentBatchSize} tamanho`)

                batches.push(currentBatch)
                currentBatch = db.batch()
                currentBatchOperations = 0
                currentBatchSize = 0
            }
            
            
            const docRef = db.doc(op.path)

            if (op.type === 'delete') {
                currentBatch.delete(docRef)
            } 
            else if (op.type === 'update') {
                currentBatch.update(docRef, op.data)
            } 
            else if (op.type === 'merge') {
                currentBatch.set(docRef, op.data, { merge: true })
            }

            currentBatchOperations += 1
            currentBatchSize += opSize
        }
        


        // Adiciona o último batch se ele contiver operações
        if (currentBatchOperations > 0) {
            batches.push(currentBatch);
        }


        console.log(batches.length)
        // return

        try {
            // Cria um array de promessas de commit para cada batch e adiciona as promessas de exclusão recursiva
            const allPromises = batches.map(batch => batch.commit())
        
            // Aguarda a resolução de todas as promessas simultaneamente
            await Promise.all(allPromises);
            console.log('Todos os batches e exclusões recursivas foram concluídos com sucesso!');
            setFinalResult('success');
        } catch (error) {
            console.error('Erro ao processar batches e exclusões recursivas:', error);
            setFinalResult('failure');
        }


        await loadAppData(user, true)
    }


    async function decrementStatisticsDoc(doc_ref, checkedNodesTagpaths, operations) {
        console.log('\n\n\ncomeçando para ' + doc_ref)
        const doc = await db.doc(doc_ref).get()
        const data = doc.data()

        const update = {}

        // Objeto para armazenar os incrementos acumulados, porque não dá para acumular
        // no FieldValue.increment -- e.g., quando vamos deletar vários decks de uma mesma tag_3
        const increments = {
            'Total' : {}
        }


        // Nós recebemos todas as tagpatahs dos leaf-nodes da árvore, ou seja, temas
        // que não possuem filhos. Precisamos gerar todas as tagpaths para subtrair os
        // dados nos níveos de todos os pais.

        for (let tagpath of checkedNodesTagpaths) {
            // Nós recebemos da árvore quais são os lead-nodes clicados, ou seja, quais são
            // os temas/tagpaths selecionados que *não* possuem filhos.
            // 
            // Os dados das folhas são deletados.
            // Calculamos quais todos os pais da folha, para subtrair deles também
            // E somamos só os dados *das folhas* para saber o quanto precisa ser tirado do total

            const tagSequence = pathToTagSequence(tagpath)
            const tagpaths = tagSequenceToPathS(tagSequence)

            const fieldsToUpdate = data[tagpath] ? Object.keys(data[tagpath]) : []
            for (let path of tagpaths) {                
                if (!increments[path]) {
                    increments[path] = {}
                }
                
                if (path == tagpath) {
                    // É a própria folha. Deletamos, sem guardar os números para incrementar,
                    // aqui é deletar, entõa não precisamos guardar os numeros para incrementar
                    update[path] = firebase.firestore.FieldValue.delete()

                    fieldsToUpdate.forEach(field => {
                        if (data[tagpath] && data[tagpath][field]) {
                            const decrementValue = data[tagpath][field]
                            increments['Total'][field] = (increments['Total'][field] || 0) - decrementValue
                        }
                    })
                }
                else {
                    fieldsToUpdate.forEach(field => {
                        if (data[tagpath] && data[tagpath][field]) {
                            const decrementValue = data[tagpath][field]
                            increments[path][field] = (increments[path][field] || 0) - decrementValue
                        }
                    })
                }
            }
        }


        console.log(increments)

        for (const path in increments) {
            if (!update[path]) {
                update[path] = {};
            }
            
            for (const field in increments[path]) {
                update[path][field] = firebase.firestore.FieldValue.increment(increments[path][field]);
            }
        }
    
        // É uma pira desgraçada, se faz update ele não aceita / no nome da chave
        // e ela é o tagpath
        operations.push({
            type: 'merge',
            path: doc_ref,
            data: update
        })
        // batch.update(db.document(doc_ref), update)
    }


    function deleteTestIDsReview(coll_ref, testsIDs, operations) {
        console.log(coll_ref)
        console.log(testsIDs)

        testsIDs.forEach(testID => {
            console.log(`Deletando... ${testID}`)
            operations.push({
                type: 'delete',
                path: `${coll_ref}/${testID}`
            })
        })
    }


    function deleteTestsFromMsPerId(coll_ref, testsIDs, operations) {
        const docsUpdates = {}

        testsIDs.forEach(reviewID => {
            const whichDocName = hashTestID(reviewID)

            if (!(whichDocName in docsUpdates)) {
                docsUpdates[whichDocName] = {}
            }
        
            docsUpdates[whichDocName][reviewID] = firebase.firestore.FieldValue.delete()    
        })

        for (let docName in docsUpdates) {
            operations.push({
                type: 'update',
                path: `${coll_ref}/${docName}`,
                data: docsUpdates[docName]
            })
        }
    }


    function deleteQuestionsFromSolvedPerId(coll_ref, testsIDs, operations) {
        const docsUpdates = {}

        testsIDs.forEach(reviewID => {
            const whichDocName = hashTestIDToSolved(reviewID)

            if (!(whichDocName in docsUpdates)) {
                docsUpdates[whichDocName] = {}
            }
        
            docsUpdates[whichDocName][reviewID] = firebase.firestore.FieldValue.delete()    
        })

        for (let docName in docsUpdates) {
            console.log(docsUpdates[docName])

            console.log( `${coll_ref}/${docName}`)

            operations.push({
                type: 'update',
                path: `${coll_ref}/${docName}`,
                data: docsUpdates[docName]
            })
        }
    }


    function deleteDeckLists(coll_ref, decksToRemove, operations) {
        decksToRemove.forEach(deck => {
            operations.push({
                type: 'delete',
                path: `${coll_ref}/${deck['root']}`
            })
        })
    }


    function deleteLikedBuried(doc_ref, testsIDs, operations, type) {
        // Vamos supor que o usuário quer deletar 5 mil revisões, e ele tem só 10 testes curtidos
        // -- o quanto essa operação será lenta e custosa no Firebase? Não sei, e lá há dificuldades
        // de debug, então já seleciono aqui.
        const testsToRemove = []
        const arr = type === 'liked' ? LikedBuriedController.liked[testType] : LikedBuriedController.buried[testType]

        testsIDs.forEach(testID => {
            if (arr.includes(testID)) {
                testsToRemove.push(testID)
            }
        })

        if (testsToRemove.length > 0) {
            const update = {
                'data' : []
            }
    
            update['data'] = firebase.firestore.FieldValue.arrayRemove(...testsToRemove)
    
            operations.push({
                type: 'update',
                path: doc_ref,
                data: update
            })
        }
    }


    function deleteMistakesJournal(testIDs, operations) {        
        MistakesJournalController.mistakesJournal.needsReview = MistakesJournalController.mistakesJournal.needsReview.filter(
            testID => !testIDs.includes(testID)
        )
    
        MistakesJournalController.mistakesJournal.reviewedTests = MistakesJournalController.mistakesJournal.reviewedTests.filter(
            testID => !testIDs.includes(testID)
        )
        
        const docRef = db.doc(`users/${user.id}/Residencia/mistakes_journal`)

        console.log(MistakesJournalController.mistakesJournal)

        operations.push({
            type: 'merge',
            path: `users/${user.id}/Residencia/mistakes_journal`,
            data: MistakesJournalController.mistakesJournal 
        })
    }


    function deletePostIts(testIDs, operations) {
        const testsWithPostItsToDelete = [];

        PersonalNotes.testsWithPostIts[testType] = PersonalNotes.testsWithPostIts[testType].filter(testID => {
            const hasPostIt = testIDs.includes(testID);
            if (hasPostIt) {
                testsWithPostItsToDelete.push(testID);
            }
            return !hasPostIt;
        })

        operations.push({
            type: 'set',
            path: `users/${user.id}/${testType}/post_its/metadata/tests_with_post_its`,
            data: PersonalNotes.testsWithPostIts[testType]
        })

        testsWithPostItsToDelete.forEach(testID => {
            const docPath = `users/${user.id}/${testType}/post_its/tests_post_its/${testID}`

            operations.push({
                type: 'delete',
                path: docPath
            })
        })
    }



    return (
        <AppContainer>

            <GeneralDialog
                open={showDeleteThemesDialog}
                title = "⚠️ Deletar temas"
                onClose={() => setShowDeleteThemesDialog(false)}
                actions={[
                    { label: 'Voltar', onClick: () => setShowDeleteThemesDialog(false), style: 'neutral'},
                    { label: 'Certeza', onClick: () => deleteThemes(), style: 'destructive' },
                ]} >
                    <p>Iremos apagar os <u>temas selecionados</u>. Certeza?</p>
            </GeneralDialog>


            <GeneralDialog
                open={showDeleteAllDialog}
                title="💀 Deletar tudo"
                onClose={() => setShowDeleteAllDialog(false)}
                actions={[
                    { label: 'Voltar', onClick: () => setShowDeleteAllDialog(false), style: 'neutral'},
                    { label: 'Certeza', onClick: () => deleteAll(), style: 'destructive' },
                ]} >
                    <p>Iremos apagar <u><b>TUDO</b></u>.</p>
                    
                    <p><b>Pode demorar um pouco</b> e é fundamental <u>NÃO FECHAR A JANELA</u>.</p>
                    
                    <p>Certeza?</p>
            </GeneralDialog>

            <ScreenContainer>
                <ScreenCard 
                    title = 'Resetar o Histórico' iconSrc = {DangerIcon} 
                    showGoBack = {true} goBackAction={goBack}>
                    
                    { !dataReady &&
                        <>
                        <p>Oops, parece que você não está com o app totalmente carregado.</p>

                        <p>Convém dar uma passadinha na tela inicial.</p>
                        </>
                    }
                    { dataReady && !consent && !finalResult && 
                        <ConsentContainer>
                            <ConsentTitle>Termo de Consentimento Livre e Esclarecido</ConsentTitle>
                            <p>Nesta tela, você poderá resetar o seu histórico dos flashcards das questões de residência, tanto de temas específicos quanto em sua totalidade.</p>

                            <Alert>As decisões tomadas aqui são <u>irreversíveis</u>.</Alert>

                            <p>Logo, não utilize essa tela se estiver:</p>

                            <ul>
                                <li>Sob uso de álcool, Ritalina, ou Venvanse</li>
                                <li>Deprimido ou em (hipo)mania</li>
                                <li>De coração partido</li>
                                <li>Desanimado com os estudos pontualmente</li>
                            </ul>

                            <ConsentBttns>
                                <OslerButton
                                    text = 'Sair daqui'
                                    bgColor = 'gray'
                                    icon = '🔙'
                                    onClick = {() => navigate('/user')} />

                                <OslerButton
                                    text = 'Aceito a responsa'
                                    bgColor = 'blue'
                                    icon = '📝'
                                    onClick = {() => setConsent(true)} />
                            </ConsentBttns>
                                
                        </ConsentContainer>
                    }
                    { dataReady && consent && !finalResult &&  
                        <ClearHistoryContainer>

                            {!testType &&
                                <Helper>Escolha uma área...</Helper>
                            }


                            <TestTypeBttns>
                                <OslerButton
                                    text = 'Flashcards'
                                    bgColor = 'gray'
                                    // size = 'small'
                                    img = {Flashcards}
                                    selected = {testType === 'Flashcards'}
                                    onClick = {() => changeTestType('Flashcards')} />

                                <OslerButton
                                    text = 'Residência'
                                    bgColor = 'gray'
                                    // size = 'small'
                                    img = {Residencia}
                                    selected = {testType === 'Residencia'}
                                    onClick = {() => changeTestType('Residencia')} />
                            </TestTypeBttns>

                            { testType && !hasDeletableStuff && 
                                <p>Ops, parece que você ainda não tem o que deletar.</p>
                            }
                            {testType && hasDeletableStuff &&
                                <>

    
                                <p>Abaixo, você pode selecionar seu histórico (revisões pendentes e futuras, testes salvos e enterrados) de temas específicos.</p>

                                <p><b>Atenção: pode demorar um pouco.</b> Especialmente quando forem muitos temas. Enquanto estiver rodando, <u>NÃO</u> feche a tela, em hipótese alguma. Então, o ideal é fazer no computador de casa.</p>

                                <TreeDeletionContainer>
                                    <TreeContainer>
                                        <SelectedTestType>
                                            <SelectedColumn>
                                                <SelectedSpan>Você selecionou</SelectedSpan>
                                                <SelectedTxt>{testType === 'Flashcards' ? 'Flashcards' : 'Residência'}</SelectedTxt>
                                            </SelectedColumn>
                                            <SelectedImg src={testType === 'Flashcards' ? Flashcards : Residencia} />
                                        </SelectedTestType>

                                        <TreeJSX
                                            searchedString = {''}
                                            mode = {'clear-history'}
                                            // mode = {'test-mode'}
                                            testType = {testType}
                                            filters = {[TreeFilters.SEEN_TESTS_ONLY]} />
                                    </TreeContainer>

                                    <OslerButton
                                        text = 'Deletar temas'
                                        bgColor = 'red'
                                        icon = '⚠️'
                                        // size = {'small'}
                                        onClick = {() => {
                                            const checked = Tree.extractCheckedNodes()
                                            if (checked.length === 0) {
                                                toastMsg('Você precisa escolher algum tema! 👻');
                                            }
                                            else {
                                                setShowDeleteThemesDialog(true)
                                            }   
                                            
                                        }}
                                        style = {{alignSelf: 'flex-end'}} />

                                </TreeDeletionContainer>

                                
                        
                                <TotalDeletionContainer>
                                    <FromZero>Quer começar do zero?</FromZero>

                                    <p>Você pode deletar seu histórico para todos os decks. Ideal para quando você quer começar do zero.</p>

                                    <OslerButton
                                        text = 'Deletar tudo'
                                        bgColor = 'red'
                                        icon = '💀'
                                        // img = {DangerIcon}
                                        // size = {'small'}
                                        onClick = {() => setShowDeleteAllDialog(true)}
                                        style = {{alignSelf: 'center', marginTop: '2em'}} />
                                </TotalDeletionContainer>
                                </>
                            }

                        </ClearHistoryContainer> 
                    }
                    { dataReady && consent && finalResult && 
                        <ConsentContainer>
                            { finalResult === 'success' &&
                                <FinalSuccess>Deu tudo certo. Bons estudos. ✅ 🙏</FinalSuccess>
                            }
                            { finalResult === 'failure' &&
                                <FinalFailure>Algo deu errado... Não sei o que, não era esperado. Me perdoe. E fale como o Suporte.</FinalFailure>
                            }
                        </ConsentContainer>
                    }

                </ScreenCard>
            </ScreenContainer>
        </AppContainer>
    )
}
import React from 'react';

import './Contact.scss'

import InstagramLogo from './../assets/instagram.png';
import SectionTitle from './SectionTitle';


export default function Contact() {

    return (
        <div className = "section section-light-bg contact-container">
            <SectionTitle subtitle="Fale conosco" title="Dúvidas ou problemas?" />
        
            <div className="contact-data">
                <p>
                    <span className='rowSpan' role="img" aria-label="email" >📧</span> 
                    <strong>Precisa de ajuda?</strong> Envie um e-mail para <strong>suporte@osler-ensino.com</strong> e nossa equipe técnica especializada entrará em contato.
                </p>


                <p>
                    <span className='rowSpan' role="img" aria-label="instagram" >
                        <img alt="Instagram" style={{width: 18}} src={InstagramLogo} />
                    </span>    
                    <strong>Quer bater um papo com os sócios?</strong> Fale diretamente com eles na DM do Instagram, <a href="https://www.instagram.com/oslermedicina/">@oslermedicina</a>.
                </p>            
            </div>
    </div>
    )
}
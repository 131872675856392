import { useEffect, useRef, useState } from "react";
import { htmlToReact } from "../utils/HtmlToReact";
import Separator from "./Separator";
import TestContainer from './TestContainer'
import styled from "styled-components";
import { clozeStyle, contentStyle, flashcardStyle, seenContentStyle } from "./FlashcardsStyles";
import PersonalNoteJSX from "./PersonalNoteJSX";
import { SelectableText } from "../components/BasicComponents";



const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    
`

const SeenFlashcardQuestion = styled.div`
    ${seenContentStyle}
    text-align: center;

    ${SelectableText}
`;


const FlashcardAnswer = styled.div`
    ${flashcardStyle}
    text-align: justify;
        ${SelectableText}
`


export default function FlashcardAnswerJSX(props) {
    const [JSX, setJSX] = useState()
    const test = useRef();


    useEffect(() => {
        if (props.test) {
            test.current = props.test
            console.log(test.current)
            getAnswer()
        }
    }, [props.test])



    function getAnswer() {
        // Ou é 'classic' (pergunta aberta), ou é
        // cloze.        
        if (test.current.type === 'classic') {
            setJSX( classicCardAnswer() )
        }
        else if (test.current.type === 'cloze') {
            setJSX( clozeCardAnswer() )
        }
        else {
            console.log("FlashcardJSX: ERROR, unkown type")
            console.log(props)
        }


    }

        
    function classicCardAnswer() {
        let question = htmlToReact ( test.current.getQuestion() )
        let answer   = htmlToReact ( test.current.getAnswer() )

        const separator = <Separator />

        let content = (
            <>    
                <SeenFlashcardQuestion>
                    {question}
                </SeenFlashcardQuestion>
                    
                {separator} 
                
                <FlashcardAnswer styleClass = {props.extraClass}>
                    {answer}
                </FlashcardAnswer>
            </>
        )

        return content
    }


    function clozeCardAnswer() {
        let answer   = htmlToReact ( test.current.getAnswer() )

        let content = (
            <>         
                <FlashcardAnswer style = {props.extraClass}>
                    {answer}
                </FlashcardAnswer>
            </>
        )

        return content
    }


    return (
        <Container> 
        
        {
            test.current && 
                <TestContainer
                    style = {props.style}
                    jsx = { JSX }
                    styleClass = {props.containerExtraClass} /> 
        }

        </Container>
    )

}
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import { useNavigate } from 'react-router-dom'
import AppContainer from '../app-container/AppContainer'
import CardList from '../components/CardList'
import { sleep, sortIDsByTagpath, toastMsg } from '../utils/Utils'
import FlashcardIcon from '../assets/flashcard.png'
import MCQIcon from '../assets/residencia.png'
import './SavedTestsScreen.css'
import styled from 'styled-components'
import ScreenContainer from '../components/ScreenContainer'
import OslerButton from '../components/OslerButton'
import ScreenCard from '../components/ScreenCard'
import TileGrid, { prepareDataForTileData } from '../user/TileGrid'
import GeneralDialog from './GeneralDialog'
import { Column, ColumnCSS, Row, RowCSS } from '../components/BasicComponents'
import SandboxIcon from './../assets/sandbox.png'


import BackArrow from './../assets/left-arrow-black.png'
import SessionBuilder from '../controllers/SessionBuilder'
import Session from '../controllers/Session'

const TabBttns = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`


const NoDataMsg = styled.p`
    font-size: 1.1em;
    align-self: center;
    margin-top: 2em;
    color: gray;
    font-style: italic;
    font-weight: bold;
    text-align: center;
`


const TestsColumn = styled.div`
    ${ColumnCSS}

    max-width: 900px;

    margin-top: 2em;
`

const TopBttns = styled.div`
    ${RowCSS}
    width: 90%;
    justify-content: space-between;
`


export default function SavedTestsScreen({selectedTests, updateLikedScreen, eraseTests,
                                            eraseMsg, title, icon, flashcardsMsg, residenciaMsg,
                                            offerPlayground = false, bttnCall}) {

    let navigate = useNavigate()
    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = useState('Flashcards')
    const isLoading = useSelector(state => state.loading.isLoading)
    const [themeSelected, setThemeSelected] = useState(false)
    const [themeSelectedTestsIDs, setThemeSelectedTestsIDs] = useState([])
    const [tileData, setTileData] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const [idsByTagPath, setIdsByTagPath] = useState([])



    useEffect(() => {
        window.scrollTo(0,0); // Scrolla a tela até em cima
        loadData()
        // changeSelectedContent('Flashcards')
    }, [selectedTests])


    async function loadData() {
        // selectedTests é um dicionário que contém os tests_ids
        // dos flashcards e questões de residência que iremos exibir.
        //
        // O primeiro passo é organizar esses IDs por temas.
        console.log('Iremos carregar tudo do zero.')
        cleanScreen()

        const flashcardsIDsByTagpath = sortByTagpath('Flashcards')
        const residenciaIDsByTagpath = sortByTagpath('Residencia')


        setIdsByTagPath({
            'Flashcards' : flashcardsIDsByTagpath,
            'Residencia' : residenciaIDsByTagpath,
        })

        // setLoading(false)
    }


    function sortByTagpath(testType) {
        // Agrupamos os IDs por tagpath, para facilitar a visualização.
        return sortIDsByTagpath(testType, selectedTests[testType])
    }


    
    async function changeSelectedContent(selectedTestType) {
        setSelectedTab(selectedTestType)
        setThemeSelected(false)
    }



    

    function loadCardList(idsByTagPath, tagpath) {
        const testsIDs = idsByTagPath[tagpath]

        setThemeSelectedTestsIDs(testsIDs)
        setThemeSelected(true)
    }





    useEffect(() => { 
        if (idsByTagPath && idsByTagPath[selectedTab] ) {
            console.log('Iremos criar novo tileData!')
            
            const data = prepareDataForTileData(
                idsByTagPath[selectedTab],
                (idsByTagPath, tagpath) => {
                    console.log(idsByTagPath)
                    console.log(tagpath)
                    loadCardList(idsByTagPath, tagpath)    
                })
                
            data.sort((a, b) => a.title > b.title ? 1 : -1)

            setTileData(data)
        }
        
    }, [idsByTagPath, selectedTab, selectedTests])


    function goBack() {
        if (themeSelected) {
            // Precisa resetar porque é usado para a deleçõa.
            cleanScreen()
            updateLikedScreen()
        }
        else {
            navigate('/user')
        }
    }


    function cleanScreen() {
        setThemeSelected(false)
        setShowDialog(false)
    }

    function eraseSelected() {
        if (themeSelected) {
            eraseTests(selectedTab, themeSelectedTestsIDs)
        }
        else {
            const combinedArray = Object.values(idsByTagPath[selectedTab]).flat();
            eraseTests(selectedTab, combinedArray)

            // eraseTests(selectedTab, idsByTagPath[selectedTab])
        }
        // eraseTests(selectedTab, themeSelectedTestsIDs)
        cleanScreen()
    }


    async function startSession() {
        dispatch(setIsLoading(true))
        await SessionBuilder.start(
            selectedTab,
            'predefined',
            'playground-mode',
            false,
            themeSelectedTestsIDs
        )

        if (Session.sessionSize > 0) {
            Session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/question")
        dispatch(setIsLoading(false))
    }
    
    return (

        <AppContainer>
            <GeneralDialog
                open = {showDialog}
                title = '⚠️ Pense bem!'
                onClose = {() => setShowDialog(false)}
                actions={[
                    { label: 'Cancelar', onClick: () => setShowDialog(false), style: 'neutral'},
                    { label: 'Prosseguir', onClick: () => eraseSelected(), style: 'destructive' },
                ]}>
                    {eraseMsg}
            </GeneralDialog>


            <ScreenContainer>

                { !isLoading && !themeSelected &&
                    <ScreenCard title = {title} iconSrc = {icon} goBackAction={goBack}>
                        <>
                            <Column>
                                <TabBttns>
                                    <OslerButton
                                        bgColor = 'grey'
                                        selected = {selectedTab === 'Flashcards'}
                                        img = {FlashcardIcon}
                                        text = 'Flashcards'
                                        onClick={() => changeSelectedContent('Flashcards')} />

                                    <OslerButton
                                        bgColor = 'grey'
                                        selected = {selectedTab === 'Residencia'}
                                        img = {MCQIcon}
                                        text = 'Residência'
                                        onClick={() => changeSelectedContent('Residencia')} />
                                </TabBttns>

                                <OslerButton
                                    bgColor =  'red'
                                    icon = '⚠️'
                                    text = {bttnCall}
                                    size = {'small'}
                                    style = {{alignSelf: 'flex-end', marginTop: '2em'}}
                                    onClick={() => setShowDialog(true)} />
                            </Column>
                            

                            { (!tileData || tileData.length === 0) &&
                                <NoDataMsg>{selectedTab === 'Flashcards' ? flashcardsMsg : residenciaMsg}</NoDataMsg>
                            }
                            { tileData.length > 0 &&
                                <TileGrid
                                    options = {tileData} />                        
                            }

                        </>                    
                    </ScreenCard>
                }


                { themeSelected && 
                        <TestsColumn>

                            { !isLoading && 
                                                        <TopBttns>
                                <OslerButton
                                        bgColor =  'grey'
                                        img = {BackArrow}
                                        text = 'Voltar'
                                        onClick={goBack} />
                                        
                                <Row>
                  

                                    <OslerButton
                                        bgColor =  'blue'
                                        img = {SandboxIcon}
                                        text = 'Modo Playground'
                                        // size = {'small'}
                                        style = {{marginRight: '2em'}}
                                        onClick={() => startSession('playground-mode')} />  

                                    <OslerButton
                                        bgColor =  'red'
                                        icon = '⚠️'
                                        text = {bttnCall}
                                        onClick={() => setShowDialog(true)} />
                                </Row>
                            </TopBttns>
                            }


                            <CardList
                                style = {{marginTop: '2em'}}
                                testType={selectedTab}
                                testsIDs={themeSelectedTestsIDs}
                                showLike = {true}
                                showBury = {true}
                                showFeedback = {true}
                            />
                            
                        </TestsColumn>

                    }
            </ScreenContainer>

        </AppContainer>
    )
}
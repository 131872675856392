import React, { useState, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

const Container = styled.div`
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:80%;
`

const Cue = styled.p`
    font-size: 1em;
    color: gray;
    font-weight: bold;
    text-style: italic;
    margin: 0 0 1em 0;
    text-align: center;
`

const BttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 0.3em;

    @media (max-width: 500px) {
        gap: 10px;
        flex-direction: row;
        margin-bottom: 40px;
        margin-top: 20px;


    }
`

const McqBttn = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 1em;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${props => props.hoverColor};
    }

    @media (max-width: 500px) {
        width: 100%;
        height: 50px;
        align-items: center;

        p {
            font-size: 1em
        }
    }
`


const Description = styled.p`
    font-size: 0.8em;
    color: #333;
    margin: 0.5em 0 0 0;
`



const Title = styled.p`
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
`


export default function McqFeedbackButtons({ gaveRightAnswer, logFeedback}) {

    const feedbackOptions = gaveRightAnswer ? [
        { title: 'Acertei por acaso', bgColor: '#1e88e5', hoverColor: '#1565c0', feedbackType: 'rightGuess' },
        { title: 'Sabia o conceito', bgColor: '#43a047', hoverColor: '#2e7d32', feedbackType: 'rightConfident' },
    ] : [
        { title: 'Não sabia', bgColor: '#e53935', hoverColor: '#c62828', feedbackType: 'wrongConcept' },
        { title: 'Distração', bgColor: '#ffb300', hoverColor: '#ffa000', feedbackType: 'wrongDistraction' },
    ]




    useHotkeys('1', () => logFeedback(feedbackOptions[0].feedbackType))
    useHotkeys('2', () => logFeedback(feedbackOptions[1].feedbackType))

    return (
        <Container>
            <Cue>{gaveRightAnswer ? `Você acertou, mas...` : `Por que você errou?`} </Cue>
            <BttnsContainer>
                { feedbackOptions.map((option, index) => (
                        <McqBttn 
                            key={index} 
                            bgColor={option.bgColor}
                            hoverColor={option.hoverColor}
                            onClick={() => logFeedback(option.feedbackType)}
                        >
                            <Title>{option.title}</Title>
                            <Description>{option.description}</Description>
                        </McqBttn>
                    ))
                }

            </BttnsContainer>
        </Container>
    )
}

import React, { useEffect, useState } from 'react';
import OslerButton from '../components/OslerButton';
import GeneralDialog from '../tests/GeneralDialog';
import AmbassadorMonthly from './AmbassadorMonthly';
import styled from 'styled-components'
import { OslerInput } from '../tests/FlashcardsStyles';
import { checkCouponForError } from '../utils/Utils';
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'
import AmbassadorsController from './AmbassadorsController';




const buttonStyle = {
    width: '10em',
    marginTop: '2em',
    marginBottom: '4em',
    alignSelf: 'flex-end',
};

export default function AmbassadorAdminRemoveInvoice({removeInvoice, selectedMonth, selectedMonthData}) {
    const [confirm, setConfirm] = useState(null)

    return (
        <>
        { selectedMonth === AmbassadorsController.monthRange[AmbassadorsController.monthRange.length - 2]
                && !selectedMonthData['paymentReceived'] && selectedMonthData['downloadURL'] &&
            <>
            { !confirm && 

                <OslerButton
                    style = {buttonStyle}
                    bgColor='gray'
                    icon = '🧾'
                    text='Reverter Decisão'
                    onClick={() => setConfirm(true)} />
            }

            { confirm &&
                <OslerButton
                    style={buttonStyle}
                    bgColor='red'
                    icon = '🧾'
                    text='Confirmar reversão'
                    onClick={() => removeInvoice() }
                />
            }
            </>
        }
        </>
    )
}
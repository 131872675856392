import React, { useEffect, useState } from 'react'
import TestScreenHeader from './TestScreenHeader'
import './TestScreen.css'
import '../scss/Main.scss'
import TestScreenNavBar from './TestScreenNavBar'
import styled from 'styled-components'
import AppContainer from '../app-container/AppContainer'
import { useSelector, useDispatch } from 'react-redux'
import Session from '../controllers/Session'


const Content = styled.div`
    width: 100%;

    /* Estamos subtraindo a altura do header, que é fixa. */
    min-height: 95vh;

    padding: 0;
    margin: 0;

    // Para garantir que a sombra do header apareça.
    z-index: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    ${props => {
        return props.theme.darkMode
            ? `     
                background-color: rgba(41, 41, 41, 1);  
            `
            : `
                background-color: #FBFBFB;

                @media (max-width: 500px) {
                    background-color: white;
                }
            `;
    }}
`


const TestContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: ${props => props.showingNotebook ? `90%` : `60%`} ;

    @media (max-width: 1200px) {
        width: ${props => props.showingNotebook ? `90%` : `80%`};
    }

    @media (max-width: 900px) {
        width: ${props => props.showingNotebook ? `90%` : `90%`};
    }
    
    @media (max-width: 500px) {
        width: ${props => props.showingNotebook ? `90%` : `100%`};
        min-height: 95vh;
    }
`


export default function TestScreen({testJSX = <></>, allowGoBack, goBackAction}) {

    const showingNotebook = useSelector(state => state.notebook.showNotebook)

    return (
        <AppContainer showSideBar = {false}>
                <TestScreenHeader />
                <Content playgroundMode = {Session.mode == 'playground-mode'}>
                    <TestContent showingNotebook = {showingNotebook}>
                        <TestScreenNavBar
                            allowGoBack = {allowGoBack}
                            onGoBackAction = {goBackAction} />

                        { testJSX }

                    </TestContent>
                </Content>         
        </AppContainer>
    )
}
import React, { useEffect, useRef, useState } from 'react'

import IconColor from '../assets/highlighter_color.png'
import IconBW from '../assets/highlighter_bw.png'
import IconEraser from '../assets/eraser.png'
import { htmlToReact } from '../utils/HtmlToReact'
import session        from '../controllers/Session'
import Separator from './Separator'
import { getAlphabetLetters, toastMsg } from '../utils/Utils'

import DeleteColored from '../assets/delete_alternative_color.png'
import DeleteBW from '../assets/delete_alternative_bw.png'


import DeleteColoredDarkMode from '../assets/delete_alternative_color_darkMode.png'
import DeleteBWDarkMode from '../assets/delete_alternative_bw_darkMode.png'



import { useDispatch, useSelector } from 'react-redux'
// import { clearMcqAlternatives, setMcqAlternative } from '../redux/mcqAlternativeSlice'

import Transfer from './../assets/transfer.png'
import Refresh from './../assets/refresh.png'
import Question from '../assets/question.png'
import { isMobile } from '../utils/BootstrapUtils'
import TestScreenButton from './TestScreenButton'

import ShortcutsDialog from './ShortcutsDialog'
import { useHotkeys } from 'react-hotkeys-hook'
import MoveForwardBttn from './MoveForwardBttn'
import styled from 'styled-components'
import { McqMetadata, TestArea, contentStyle, primaryColor } from './FlashcardsStyles'
import TestContainer from './TestContainer'
import RadioButton from '../custom/RadioButton'
import QuestionScreenHelperBttns from './QuestionScreenHelperBttns'

import HighlightableHTML from './HighlightableHTML'
import { SelectableText } from '../components/BasicComponents'



const McqQuestionText = styled.div`
    ${contentStyle}
    text-align: justify;

    ${SelectableText}
`


const McqAlternativeText = styled.p`
    ${contentStyle}
    margin: 0;
    padding: 0;


    ${SelectableText}
`

export default function McqQuestionJSX(props) {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const pureQuestion = useRef("")
    const [question, setQuestion] = useState("")

    const [metadata, setMetadata] = useState("")

    const [isAlternativeCrossed, setIsDel] = useState(new Array(session.getCurrentTest().alternatives.length).fill(false));
    const [chosenAlternative, setChosenAlternative] = useState(-1)
    const [crossedAlternatives, setCrossedAlternatives] = useState([])

    const [showDialog, setShowDialog] = useState(false)


    const highlightableRef = useRef()
    const [hasHighlights, setHasHighlights] = useState(false)
    const [highlightIsActive, setHighlightIsActive] = useState(false)


    useEffect(() => {
        // dispatcher(clearMcqAlternatives())
        update()
    }, [])


    // Força renderização ao passar um ID qualquer
    useEffect(() => {
        update()
    }, [props.id])



    function changeCrossedStatusForAlternative(index) {
        // Se a alternativa estava disponível, marca ela como 
        // uma das eliminadas. Do contrário, refaz ela como disponível.
        const newIsDel = Array.from(isAlternativeCrossed)
        
        if (newIsDel[index]) {
            newIsDel[index] = false;
        }
        else {
            newIsDel[index] = true;
            if (chosenAlternative === index) {
                // console.log("Changing to -1")
                setChosenAlternative(-1)
            }
        }

        setIsDel(newIsDel)

        console.log(newIsDel)
    }

    
    function changeChosenAlternative(newAlternative) {
        if (newAlternative < session.getCurrentTest().alternatives.length) {
            // console.log(isAlternativeCrossed)
            if ( isAlternativeCrossed[newAlternative]) {
                toastMsg("Você já excluiu essa alternativa! 🤔")
            }
            else {
                if (chosenAlternative === newAlternative) {
                    // console.log(`Changing from ${chosenAlternative} to -1`)
                    setChosenAlternative(-1)
                }
                else {
                    // console.log(`Changing from ${chosenAlternative} to ${newAlternative}`)
                    setChosenAlternative(newAlternative)
                }    
            }
        }
        else {
            console.log("Invalid request")
        }
    }


    function moveEndQueue() {
        session.moveCurrentTestToEndOfQueue()
        update()
    }


    function update() {
        const test = session.getCurrentTest()
        const question_tmp = test.getQuestion()
        pureQuestion.current = question;

        // Como o conteúdo envolve markdown, precisamos passar para html.        
        // setQuestion( htmlToReact(question) )

        console.log(question_tmp)

        setQuestion(question_tmp)
        setMetadata(`${test.institution} (${test.year})`)
        setChosenAlternative(-1)
    }



    function moveForward() {
        // console.log(chosenAlternative)
        if (chosenAlternative === -1) {
            toastMsg("Mas não vai escolher uma alternativa? 🙃")
        }
        else {
            const y = chosenAlternative+1
            session.mcqChosenAnswer = y

            props.moveForward()
        }
    }


    // Setamos os atalhos.
    // https://www.npmjs.com/package/react-hotkeys-hook
    const shortcutsDescription = [
        {
            'keys': "CTRL + Z",
            'description' : 'Volta para a questão anterior.'
        },
        {
            'keys': "SHIFT + P",
            'description' : 'Pula para o próximo teste, enviando esse para o fim da fila.'
        },
        {
            'keys': "SHIFT + G",
            'description' : 'Habilita ou desabilita a função grifar.'
        },
        {
            'keys': "Espaço ou Enter",
            'description' : 'Envia sua resposta e mostra o gabarito.'
        },
        {
            'keys': "Números 1 a 5",
            'description' : 'Seleciona, respectivamente, da 1ª à 5ª alternativa.'
        }
    ]


    // Igual aos cards.
    useHotkeys('ctrl+z', props.goToPreviousQuestion)
    useHotkeys('shift+p', moveEndQueue)
    useHotkeys('enter', moveForward, [chosenAlternative])
    useHotkeys('space', moveForward, [chosenAlternative])

    // Específico
    useHotkeys('shift+g', () => setHighlightIsActive(!highlightIsActive), [highlightIsActive])

    // https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/setting-callback-dependencies
    useHotkeys('1', () => changeChosenAlternative(0), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('2', () => changeChosenAlternative(1), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('3', () => changeChosenAlternative(2), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('4', () => changeChosenAlternative(3), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('5', () => changeChosenAlternative(4), [chosenAlternative, isAlternativeCrossed])


    function clearHighlights() {
        if (highlightableRef.current) {
            highlightableRef.current.removeAllHighlights();
        }
    }

    function handleHighlightChange(newHasHighlights) {
        setHasHighlights(newHasHighlights)
    }


    return (
        <>

        <TestArea>
            <TestContainer
                jsx = {(
                    <>
                    <McqMetadata>
                        <strong>{ metadata }</strong>
                    </McqMetadata>

                    { question && highlightIsActive &&
                        <HighlightableHTML
                            ref = {highlightableRef}
                            html = {question}
                            onHighlightChange={handleHighlightChange} />
                    }
                    {
                        question && !highlightIsActive &&
                        <McqQuestionText>
                            { htmlToReact(question) }
                        </McqQuestionText>
                    }
                        
                    <Separator />

                    <div className='MCQ-Alternatives'>
                        {
                            session.getCurrentTest().alternatives.map((alternative, index) => {

                                const alternativeJSX = (<>{getAlphabetLetters(index+1)[index]}) {htmlToReact(alternative)}</>)

                                return (
                                    <div className='MCQ-Single-Alternative'>
                                        <div className='MCQ-CancelAlternativeBttn' onClick={() => changeCrossedStatusForAlternative(index)}>
                                            <img className='btn-img' src={isAlternativeCrossed[index] ? (darkMode ? DeleteColoredDarkMode : DeleteColored) : (darkMode ? DeleteBWDarkMode : DeleteBW)} />
                                        </div>
                                        
                                        <RadioButton
                                            text = {
                                                <McqAlternativeText>
                                                    { isAlternativeCrossed[index] ? <del>{alternativeJSX}</del> : <>{alternativeJSX}</> }
                                                </McqAlternativeText>
                                            }

                                            action = {() => changeChosenAlternative(index)}
                                        
                                            clicked = {!isAlternativeCrossed[index] && chosenAlternative === index}

                                            disabled = {isAlternativeCrossed[index]}
                                            />

                                    </div>
                                )
                            })
                        }
                    </div>
                    </>
                )} />
            
            
                <QuestionScreenHelperBttns
                    goToPreviousQuestion = {props.goToPreviousQuestion}
                    moveEndQueue = {moveEndQueue}
                    testType = {'Residencia'}
                    otherBttns = {
                        <>
                        { !highlightIsActive &&
                            <TestScreenButton
                                text = {'Grifar'}
                                action = {() => setHighlightIsActive(true)}
                                icon = {IconColor} />
                        }
                        { highlightIsActive && !hasHighlights &&
                            <TestScreenButton
                                text = {'Desativar grifos'}
                                action = {() => setHighlightIsActive(false)}
                                icon = {IconBW} />                        
                        }
                        { highlightIsActive && hasHighlights &&
                            <TestScreenButton
                                text = {'Limpar grifos'}
                                action = {clearHighlights}
                                icon = {IconEraser} />                
                        }
                        </>
                    }
                    />

                
                <MoveForwardBttn
                    moveForward = {moveForward} />

        </TestArea>
        </>
    )
}
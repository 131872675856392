
import LikedBuriedController from '../controllers/LikedBuriedController'
import { KEYS } from '../controllers/OslerData'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import { pathToTagSequence, tagSequenceToPath, tagSequenceToPathS } from '../utils/Utils'
import TreeNode from './TreeNode'
import TreeNodeJSX from './TreeNodeJSX'


export const TreeFilters = {
    INCLUDE_ANULADAS: 'includeAnuladas',
    NEW_TESTS_ONLY: 'newTestsOnly',
    PENDING_REVIEWS_ONLY: 'pendingReviewsOnly',
    ALL_REVIEWS_ONLY: 'allReviewsOnly',
    SEEN_TESTS_ONLY: 'seenTestsOnly'
}


class Tree {
    constructor() {
        this.start()
    }


    start(testType) {
        console.log('Tree: criando do zero...')
        this.testType = testType
        this.tagHierarchy = {}
        this.infoPerPath  = {}
        this.nodes        = []

        this.previousSearch = undefined
    }


    loadData(removeAnuladas = true) {
        /* 
            Para construir a árvore, as informações básicas são:
                - A hierarquia de tags, que dá a relação entre os nós
                - O número total de testes para cada tagpath
                - O número de revisões pendentes e futuras para cada tagpath

            A hierarquia é um documento do Firebase, já baixado. E o número de testes disponíveis,
            de revisões pendentes, e de revisões futuras já foram computados e estão disponíveis
            em UserReviewsInfo.infoPerPath.

            Por fim, lemrar que, no JS, cópias de objetos são por referência, então precisamosw
            deliberadamente fazer por valor. Não é tão trivial quanto parece, tem deep e shallow 
            copy.
        
            Isso é importante porque queremos modificar os dados da árvore dinamicamente
            (e.g., inserindo ou removendo anuladas), mas não afetar os dados originais.
        */
        console.log("Tree: copiando os dados...")
        this.tagHierarchy = JSON.parse(JSON.stringify(UserReviewsInfo.tagHierarchy[this.testType]))
        this.infoPerPath  = JSON.parse(JSON.stringify(UserReviewsInfo.infoPerPath[this.testType]))


        // Fazemos uma cópia por excesso de zelo, mas não é necessário.
        console.log('Tree: removendo os testes enterrados...')
        const buriedIDs = JSON.parse(JSON.stringify(LikedBuriedController.buried[this.testType]))
        this.removeTestsFromTree(buriedIDs)


        // Via de regra, removemos as anuladas.
        if (this.testType === 'Residencia') {
            console.log(`Deveria remover anulada? ${removeAnuladas}`)
            if (removeAnuladas) {
                // Cuidado com a pegadinha obscena. Se a anulada já foi buried, não podemos
                // eliminá-la duas vezes.
                console.log('Tree: removendo anuladas...')
                const anuladasIDs = Object.keys(UserReviewsInfo.anuladasInfo[KEYS.ALL_IDS])
                const anuladasNotBuried = anuladasIDs.filter(ID => !buriedIDs.includes(ID))
                this.removeTestsFromTree(anuladasNotBuried)
            }
        }
    }


    removeTestsFromTree(listTests) {
        // Para uma data lista de testIDs, removemos eles da árvore.
        for (let ID of listTests) {
            const tagPath = UserReviewsInfo.getGivenTestTagPath(this.testType, ID)

            // Precisamos derivar o status para saber se é available, pending, ou future.
            const key = UserReviewsInfo.getTestStatusAsKey(this.testType, ID)

            // Não adianta remover só da tagPath T1/T2/T3/T4, por exemplo.
            // Também queremos descontar no nó T1/T2/T3, T1/T2, e T1!
            const tagSeq   = pathToTagSequence(tagPath)
            const allPaths = tagSequenceToPathS(tagSeq) 

            for (let path of allPaths) {
                this.infoPerPath[path][key] -= 1
            }
        }
    }


    createTree() {
        console.log('Tree: criando a árvore...')
        this.nodes = []
        this.createSubTree(this.tagHierarchy, [], undefined)
    }


    createSubTree(tagHierarchy, tagsUntilHere, parentNode) {
        /*
            Essa função é recursiva com de manter a hierarquia entre os nós (objetos
            TreeNode) que serão criados.
            
            Para cada nível da tagHierarchy, que corresponde a um tagpath, vemos as 
            próximas tags, que correspondem aos nós-filhos. Criamos eles, passando as
            informações den úmero de testes totais, revisões pendentes, e revisões futuras.
        */  


        // Guardamos todos os nodes criados em um array, que serão retornados ao nó pai
        // para que seja guardado sob ele.
        let currentNodes = []

        // tagHierarchy são as tags SOB este nó, que vamos iterando de modo recursivo (veja abaixo)
        const keys = Object.keys(tagHierarchy)
        const ordered = keys.sort((a, b) => a < b ? -1 : 1)

        for (let tag of ordered) {
            const nodeTagSequence = [...tagsUntilHere]
            nodeTagSequence.push(tag)

            const path = tagSequenceToPath(nodeTagSequence)
            const info = this.infoPerPath[path]

            const node = new TreeNode(tag, info, parentNode, nodeTagSequence)
            
            this.nodes.push(node)
            currentNodes.push(node)

            const subHierarchy = tagHierarchy[tag]

            if (subHierarchy != null) {
                const children = this.createSubTree(subHierarchy, nodeTagSequence, node)
                node.setChildren(children)
            }
        }

        return currentNodes;
    }


    createTreeJSX(triggerNewRender, searchedString, mode, filter) {
        this.computeVisibleNodes(filter, searchedString)

        return this.nodes.map( (node) => {
            if (node.visible) {
                const nodeJSX = (
                    // Inforammos se o usuário está pesquisando por qualquer coisa
                    // porque isso trava a expansão. Ainda, informamos se há
                    // algum filtro, pois altera como exibimos as informações dos nós
                    // visíveis.
                    <TreeNodeJSX
                        testType = {this.testType}
                        node = {node}
                        mode = {mode}
                        triggerNewRender = {triggerNewRender}
                        userIsSearching = { searchedString !== '' }
                        filter = {filter} />
                )
                return (nodeJSX)
            }
            else {
                return <></>
            }
        })
    }


    computeVisibleNodes(filter, searchedString) {
        this.computeBasicVisibility()
        this.computeVisibilityBySearch(searchedString)
        this.computeVisibilityByFilter(filter)
    }


    computeBasicVisibility() {
        this.nodes.forEach(node => {
            const isRootNode = (node.parent === undefined)
            const parentExpanded = (node.parent && node.parent.isExpanded)
            node.setBeingVisible( isRootNode || parentExpanded)
            node.setBeingSearched(false)
        })
    }


    computeVisibilityBySearch(searchedString) {      
        /*
            Antigamente, quando o usuário pesquisava por um termo, ou deixava de pesquisar,
            nós resetávamos tudo que foi clicado e/ou expandido na árvore.

            Atualmente, com a perspectiva de seleção de vários temas, até para criação de listas
            de questões e afins, não fazemos isso.
        
            Se o desejo voltar (e.g., resetar a expansão), lembre-se que a lógica é: não basta
            só avaliar se searchedString é não nula, pois novas renderizações
            são realizadas continuamente, a cada alteração de checkbox, detalhamento da árvore,
            etc.

            Então, sempre que há nova renderização, *com* termo buscado, e o termo é novo,
            reseta-se.
        */
        console.log('Tree: computando visibilidade por busca...')

        // Antes eu comparava se o termo buscado era diferente do anterior, mas nós perdíamos
        // a busca após o clique de um checkbox, quando em uma nova renderização compreendia-se que não era
        // necessário filtrar de novo.
        // if (searchedString != this.previousSearch) {
            // Se o termo pesquisado é o mesmo, não precisamos recalcular tudo.
        let adjSearchedString = this.adjustString(searchedString)

        if (adjSearchedString != '') {


            this.previousSearch = searchedString
            this.nodes.forEach(node => node.setBeingVisible(false))

            this.nodes.forEach(node => {   
                if ( this.adjustString(node.title).includes( adjSearchedString ) ) {
                    node.setBeingVisible(true)
                    node.setBeingSearched(true)

                    let parent = node.parent
                    while (parent) {
                        parent.setBeingVisible(true)
                        parent = parent.parent
                    }
                    
                    // Hoje, fazemos todas as crianças sob o nóde visíveis. *TODAS.
                    // Uma alternativa futura é só fazeras crianças imediatas, e devolver
                    // o botão de "expand". Ambas são igualmente fáceis, é que essa aqui
                    // ressalta a quantidade de conteúdo.
                    this.makeChildrenVsibile(node)
                }
            })


        }


    }   


    makeChildrenVsibile(node) {
        let children = node.children

        if (children) {
            for (let child of node.children) {
                child.setBeingVisible(true)
                this.makeChildrenVsibile(child) 
            }
        }
    }


    adjustString(str) {
        var unidecode = require('unidecode')
        return unidecode(str.trim().toLowerCase())
    }


    computeVisibilityByFilter(filter) {
        if (filter) {
            this.nodes.forEach(node => {
                // Na lógica do "se satisfizer a condição, então é visível", (quase) toda a 
                // árvore ficaria visível e expandida, e não é o que queremos.
                //
                // A ideia é que "se não satisfaz a condição, nunca deve ser visível".



               if ( !this.satisfyFilterCondition(filter, node) ) {
                    node.setBeingVisible(false)
                    node.setBeingFiltered(true)
               }
            })
        }
    }


    satisfyFilterCondition(filter, node) {
        // ATenção que precisa ser processado em treeJSX
        
        if (filter === TreeFilters.NEW_TESTS_ONLY) {  
            return node.info['availableTests'] > 0
        }
        else if (filter === TreeFilters.PENDING_REVIEWS_ONLY) {
            return node.info['pendingReviews'] > 0
        }
        else if (filter === TreeFilters.ALL_REVIEWS_ONLY) {
            return (node.info['pendingReviews'] + node.info['futureReviews']) > 0
        }        
        else if (filter === TreeFilters.SEEN_TESTS_ONLY) {
            // Lembrando que flashcards não tem
            let d = node.info
            let s = d['pendingReviews'] + d['futureReviews'] + ( d['solved'] ?? 0)
            return s > 0
        }
    }


    extractCheckedNodes() {
        return this.nodes.flatMap(node => {

            if (node.isChecked) {
                return [tagSequenceToPath(node.tagSequence)]                
            }
            else {
                return []
            }
        })
    }


    extractCheckedLeafNodes() {
        return this.nodes.flatMap(node => {
            if (node.isChecked && !node.children) {
                return [tagSequenceToPath(node.tagSequence)]                
            }
            else {
                return []
            }
        })
    }
}

export default new Tree()
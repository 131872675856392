import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Dumbbell from './../assets/tree/dumbbell.png';
import Notebook from './../assets/tree/notebook.png';
import Books from './../assets/books.png';
import Balloons from './../assets/tree/balloons.png';
import SandboxIcon from './../assets/sandbox.png';
import { css } from '@emotion/react';

const StatusRowIcon = styled.img`
    width: 1.2em;
    height: 1.2em;
    object-fit: contain;

    @media (max-width: 500px) {
        width: 1em;
        height: 1em;
    }
`;

const StatusRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: ${({ depth, mainViewMarginLeft }) =>
        depth === 0 ? `${mainViewMarginLeft}px` : `${mainViewMarginLeft - 2}px`};

    @media (max-width: 500px) {
        margin-top: 0.2em;
    }
`;

const StatusRowText = styled.p`
    font-size: 0.85em;
    margin: 0;
    padding: 0;
    margin-left: ${({ depth, textMarginLeft }) =>
        depth === 0 ? `${textMarginLeft}px` : `${textMarginLeft - 8}px`};

    
    color: ${({ rowType, theme, mode }) => {
        let color;

        if (theme.darkMode) {
            if (rowType === 'review' || mode === 'clear-history') {
                color = 'rgba(255, 71, 87, 0.8)';
            } else {
                color = 'rgb(200, 200, 200)';
            }
        } else {
            if (rowType === 'review' || mode === 'clear-history') {
                color = '#8E0011';
            } else {
                color = '#808080'; 
            }
        }

        return color;
    }};

    font-weight: ${({ mode }) => (mode === 'allDone' ? 'bold' : 'normal')};

    @media (max-width: 500px) {
        font-size: 0.8em;
    }
`;

export default function FullStatusRow(props) {
    const [text, setText] = useState('');
    const [imgSrc, setImgSrc] = useState('');
    const [depth, setDepth] = useState(-1);
    const [nTests, setN] = useState(-1);

    useEffect(() => {
        setDepth(props.depth);
        setN(props.nTests);

        if (props.rowType === 'review') {
            setText(
                props.reviewsOnly
                    ? `${props.nTests} revisões`
                    : `${props.nTests} revisões pendentes`
            );
            setImgSrc(Dumbbell);
        } else if (props.rowType === 'new') {
            setText(`${props.nTests} atividades novas`);
            setImgSrc(Notebook);
        } else if (props.mode === 'consult-mode') {
            setText(`${props.nTests} testes do tema`);
            setImgSrc(Books);
        } else if (props.mode === 'playground-mode') {
            setText(`${props.nTests} testes do tema`);
            setImgSrc(SandboxIcon);
        }
        else if (props.mode === 'clear-history') {
            if (props.testType === 'Flashcards') {
                setText(`${props.nTests} revisões pendentes e futuras`);
            }
            else {
                setText(`${props.nTests} questões, entre revisões e resolvidas`);
            }

            setImgSrc(Dumbbell);
        }
        else {
            setText('você já fez tudo!');
            setImgSrc(Balloons);
        }
    }, [props, props.nTests]);

    const mainViewMarginLeft = window.innerWidth >= 500 ? 15 : 10;
    const textMarginLeft = window.innerWidth >= 500 ? 10 : 10;

    return (
        <StatusRowContainer depth={depth} mainViewMarginLeft={mainViewMarginLeft}>
            <StatusRowIcon src={imgSrc} />
            <StatusRowText depth={depth} rowType={props.rowType} mode = {props.mode} textMarginLeft={textMarginLeft}>
                {depth === 0 ? text : nTests}
            </StatusRowText>
        </StatusRowContainer>
    );
}
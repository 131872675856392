import React from 'react'
import './About.css'

import About1 from './../assets/videos/imagem1.png'

import Demo1 from './../assets/new-landing/demo1.png'
import Demo2a from './../assets/new-landing/demo2a.png'
import Demo2b from './../assets/new-landing/demo2b.png'
import Demo3 from './../assets/new-landing/demo3.png'
import Demo4 from './../assets/new-landing/demo4.png'
import Demo6b from './../assets/new-landing/demo6b.png'
import Demo7 from './../assets/new-landing/demo7.png'
import Demo8 from './../assets/new-landing/demo8.png'
import Demo9 from './../assets/new-landing/demo9.png'

import SectionTitle from './SectionTitle'
import Explanation from './Explanation'

export default function About() {

    return (
        <>

            <div className="section section-light-bg about-container">
                <Explanation
                    text={<>
                        <h1>Bibliografia</h1>
                        <p><strong>Conteúdo criado primariamente a partir do UpToDate.</strong> Padrão-ouro em educação médica, atualizado todos os meses, revisão de pares entre especialistas, e metodologia de avaliação da evidência — e o mesmo rigor vem para nossos flashcards.</p>

                        <p><strong>Complementado com diretrizes e livros clássicos.</strong> Utilizamos referências específicas quando apropriado: material de Dengue do Ministério da Saúde, Zugaib para nuances de obstetrícia, complementamos DPOC com o GOLD, etc.</p>

                        <p><strong><em>E se fosse seu avô sendo atendido?</em></strong> Nada é embasado por apostilas, slides de aula, etc. Damos um material de excelência para você se tornar o médico que você gostaria que atendesse seus avós.</p>
                    </>
                    }

                    img={<><img class='about-img about-img-bigger' src={About1} /></>}

                    biggerImage={true} 
                    
                    
                    reverseMobile = {true}


/>
            </div>


            <div className="section section-dark-bg about-container">
                <Explanation
                    text={<>
                        <h1>Banco de Questões</h1>

                        <p><strong>Temos de +25 mil questões</strong>. Das principais instituições, de 2015 a 2023! Treinando para elas, você passa em qualquer lugar — não adianta comprar infinitas questões e não resolver nenhuma.</p>
                    </>
                    }

                    img={<>
                        <img class='about-img about-img-shadow' src={Demo4} />
                    </>}

                    reverseMobile = {true}

                />


                <Explanation
                    text={<>

                        <p><strong>Milhares de questões <u>comentadas</u>!</strong> Em 2023, começamos a comentar as questões, e já temos milhares com explicação, acompanhando os temas do Extensivo/Intensivão.</p>

                        <p><strong>Explicação geral e de cada alternativa.</strong> Iniciamos com uma discussão que retoma os principais pontos da matéria e contextualiza o caso do enunciado. Na sequência, <u>explicamos cada uma das alternativas</u>.</p>


                    </>
                    }

                    img={<>
                        <img class='about-img about-img-shadow' src={Demo7} />
                    </>}

                    reverseMobile = {true}

                />
            </div>


            <div className="section section-light-bg about-container">
                <Explanation
                    text={<>
                        <h1>Extensivo<br />& Intensivão</h1>
                        <p><strong>Os principais assuntos até o final do ano.</strong> Nossos cronogramas garantem que você estudará os temas mais prevalentes.</p>

                        <p><strong>Preparação robusta.</strong> O foco é na prova de R1, mas pode ser usado no internato e no ciclo clínico para organizar seus estudos.</p>

                        <p><strong>Flashcards & Questões.</strong> Diariamente, você recebe decks de flashcards e/ou questões comentadas. Os temas são abordados de ordem lógica para o seu aprendizado.</p>

                        <p><strong>Menos de 2h/dia.</strong> Como não há leitura e vídeoaula, o estudo é otimizado, e demora muito menos que 2h por dia — sobra tempo para estudar para o internato, realizar um preparatório tradicional, etc.</p>

                        <p><strong>Curadoria do conteúdo.</strong> O Extensivo e o Intensivão são nosso xodó, e o conteúdo é especialmente caprichados.</p>

                        <p><strong>Utilizados por milhares de alunos.</strong> E com alto grau de aprovação — foram os produtos que fizeram a Osler explodir! 🚀 🚀 🚀</p>
                    </>}

                    img={<><img class='about-img about-img-shadow' src={Demo8} /></>}

                    biggerImage={true}

                    reverseMobile = {true}

                />
            </div>



            <div className="section section-dark-bg section-full-height about-container">

                <SectionTitle
                    title="Detalhes do Produto"
                    subtitle="" />

                <Explanation
                    text={<>
                        <p><strong>Não são "só" flashcards.</strong> A maioria dos flashcards possui comentários junto da resposta. Às vezes, é para clarear uma nuance ou fazer uma piada. Muitas vezes, é uma explicação aprofundadíssima ou uma anedota histórica.</p>
                    </>}

                    img={<><img class='about-img about-img-shadow' src={Demo1} />      </>}

                    reverseMobile = {true}
                 />


                    



                <Explanation
                    text={<>
                        <p><strong>Faça centenas de flashcards em <u>minutos</u>.</strong> Com perguntas diretas e respostas curtas, cada flashcard é respondido em menos de 3 segundos.</p>

                        <p>Além disso, as perguntas nunca são ambíguas, e as respostas sempre são únicas. E temos flashcards tanto clássicos quanto de cloze.</p>

                        <p>Essa é a metodologia certa de fazer flashcards, e nem todos entendem. 😉 </p>
                    </>}

                    img={<>
                        <img class='about-img about-img-shadow' src={Demo2a} />
                        <img class='about-img about-img-shadow' src={Demo2b} />   </>} 
                    
                    reverseMobile = {true}

                    />




                <Explanation
                    text={<>
                        <p><strong>O melhor algoritmo de repetição espaçada é o do Anki, e o nosso é <u>igual</u>.</strong> Não tem melhor que o do Anki, e não é à toa que é o utilizado por todos os alunos. Como  ocódigo do Anki é público, a gente fez só mínimos ajutes.</p>

                        <p><strong>Não existe repetição espaçada específica para aluno de medicina.</strong> E não tem <u>nada</u> a ver com "inteligência artificial", que é papo de quem tá só querendo te vender algo.</p>
                    </>}

                    img={<><img class='about-img about-img-shadow' src={Demo3} />  </>} 
                    
                    reverseMobile = {true}/>





                <Explanation
                    text={<>
                        <p><strong>Conteúdo organizado por tema.</strong> Tanto os flashcards quanto as questões são classificados por grande área → área → tema → subtema, de modo extremamente granular! Você não fica limitado a "Endocrinologia", e pode estudar especificamente "Diagnóstico da DM2".</p>

                        <p><strong>As questões podem ser filtradas por instituição e ano.</strong> Parece óbvio, mas em 2023 tem preparatório que ainda não permite isso de modo fácil.</p>

                        <p><strong>Estude o que quiser.</strong> Nosso cronograma é só uma sugestão. Você tem acesso livre a todo o nosso conteúdo, e pode se organizar para conciliar com outro preparatório, com o internato, com as aulas da faculdade, etc.</p>
                    </>}

                    img={<><img class='about-img about-img-shadow' src={Demo6b} />  </>}
                    
                    reverseMobile = {true}
                    />

                <Explanation
                    text={<>
                        <p><strong>Estude onde quiser.</strong> A plataforma adapta-se para rodar no seu computador, no tablet, ou no celular, funcionando como um aplicativo.</p>

                        <p><strong>Plataforma veloz.</strong> Precisa de internet, mas a plataforma é muito rápida, e consome muito pouco dado. Se for o Wi-Fi movido a manivela do hospital, funciona.</p>
                    </>}

                    img={<><img class='about-img ' src={Demo9} />  </>} 
                    
                    reverseMobile = {true}/>

            </div>

        </>
    )
}

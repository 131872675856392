import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { auth } from './../firebase/firebase-setup'

// Ícones
import AppContainer from '../app-container/AppContainer'
import ProfileIcon from './../assets/profile.png'
import DoorIcon from './../assets/door.png'
import SettingsIcon from './../assets/settings.png'
import ShoppingCart from './../assets/shopping-cart.png'
import SubscriptionIcon from './../assets/credit_card_icon.png'
import SupportIcon from './../assets/support.png'
import HeartIcon from './../assets/heart.png'
import BookClubIcon from './../assets/book_club.png'
import SkullIcon from './../assets/skull_color.png'
import DangerIcon from '../assets/danger.png'
import FeedbackIcon from '../assets/correcao.png'
import AmbassadorIcon from '../assets/piramide.png'

// Componentes
import TileGrid from './TileGrid';
import Subscription from './Subscription.js';
import Quit from './Quit.js'
import Support from './Support.js'
import BookClub from './BookClub.js'
import ScreenContainer from '../components/ScreenContainer.js';
import ScreenCard from '../components/ScreenCard.js';

// Outros
import { checkActiveSubscription, getSubscriptionType, reloadUser, shouldAllowPayment } from '../firebase/firebaseUtils';
import { toastMsg } from '../utils/Utils'

import './UserScreen.css'
import '../scss/Main.scss'
import { useEffect } from 'react';
import { setIsLoading } from '../redux/loadingSlice';
import Profile from './Profile.js';
import IsLoading from '../main/IsLoading.js';

import ChoosePlan from './checkout/ChoosePlan.js';
import Buy from './Buy.js';
import ClearHistory from './ClearHistory.js';



export default function UserScreen() {    
    // const [user, setUser] = useState( useSelector(state => state.user.data) )
    // const [isActive, setIsActive] = useState( checkActiveSubscription(user) )
    // const [buyingAllowed, setBuyingAllowed] = useState(false)
    // const [subscriptionType, setSubscriptionType] = useState('')
    // const [userIsEmailVerified, setUserIsEmailVerified] = useState(false) 


    const [loaded, setLoaded] = useState(false)
    const [options, setOptions] = useState([])

    const buyingAllowed = useRef()
    const isEmailVerified = useRef()
    const subscriptionType = useRef()
    const isActive = useRef()

    let navigate = useNavigate();
    const dispatch = useDispatch();

    
    async function loadScreen() {
        console.log(' * Iremos atualizar o status do usuário...')

        dispatch( setIsLoading(true) )

        const newUser = await reloadUser()


        subscriptionType.current = getSubscriptionType(newUser)
        buyingAllowed.current = shouldAllowPayment(subscriptionType.current)
        isEmailVerified.current = newUser.isEmailVerified

        isActive.current = checkActiveSubscription(newUser)

        // setBuyingAllowed( shouldAllowPayment(subscriptionType) )

        // setSubscriptionType( subscriptionType )
        // setUser(user)
        // setIsActive( isActive )
 
        createOptions()

        
        setLoaded(true)
        
        dispatch( setIsLoading(false) )  
    }

    

    useEffect(() => {
        loadScreen()
    }, [])



    function createOptions() {
        const appAvailable = isActive.current

        let options_tmp = [
            {
                icon : ProfileIcon,
                title : 'Cadastro',
                available: true,
                warning: !isEmailVerified.current,
                JSX : <Profile 
                            updateUserState = { loadScreen } />
            },
        ]
        
        if (!appAvailable) {
            options_tmp.push({
                icon : ShoppingCart,
                title : 'Comprar',
                available: isEmailVerified.current && !isActive.current,
                warning: isEmailVerified.current && !isActive.current,
                JSX: <Buy
                        updateScreen = { loadScreen }
                        buyingAllowed = {buyingAllowed.current}
                        subscriptionType = {subscriptionType.current} />


                // action : () => navigate('/ambassador')
            })
        }
        else {
            options_tmp.push({
                icon : SubscriptionIcon,
                title : 'Assinatura',
                available: true,
                warning: false,
                JSX : <Subscription
                            subscriptionType = {subscriptionType.current} />
            })
        }
        

        options_tmp.push(...[
            {
                icon : AmbassadorIcon,
                title : 'Embaixadores',
                available: appAvailable,
                warning: false,
                action : () => navigate('/ambassador')
            },
            {
                icon : BookClubIcon,
                title : 'Clube do Livro',
                available: appAvailable,
                warning: false,
                JSX: <BookClub />
            },
            {
                icon : FeedbackIcon,
                title : 'Feedback do Conteúdo',
                available: appAvailable,
                warning: false,
                action : () => navigate('/feedback')
            },
            {
                icon : HeartIcon,
                title : 'Testes Curtidos',
                available: appAvailable,
                warning: false,
                action : () => navigate('/liked')
            },
            {
                icon : SkullIcon,
                title : 'Testes Enterrados',
                available: appAvailable,
                warning: false,
                action : () => navigate('/buried')
            },
            {
                icon : DangerIcon,
                title : 'Resetar Histórico',
                available: appAvailable,
                warning: false,
                // JSX: <ClearHistory />
                action : () => navigate('/clear')
            },
            {
                icon : SupportIcon,
                title : 'Suporte & Ajuda',
                available: true,
                warning: false,
                JSX : <Support />
            },
            {
                icon : DoorIcon,
                title : 'Sair',
                available: true,
                warning: false,
                JSX : <Quit />
            },
        ])


        setOptions(options_tmp)
    }


    function calculateDefaultExpandedJSX() {
        // Se não verificou email, expandir
        if (!isEmailVerified.current) {
            return 0
        }
        else if (buyingAllowed.current || !isActive.current) {
            return 1
        }
        else {
            return false;
        }
    }
    

    return (
        <AppContainer>
            <ScreenContainer>
                { loaded && 
                    <ScreenCard title = {'Configurações'} iconSrc = {SettingsIcon} showGoBack = {false}>
                        <TileGrid
                            options = {options}
                            defaultExpanded = {calculateDefaultExpandedJSX()} />
                    </ScreenCard>
                }
                { !loaded &&
                    <IsLoading />
                }
            </ScreenContainer>

{/* 
            <UserScreenContainer>
                { !user.isEmailVerified && 
                    <VerifyEmail onVerify = { updateUserState }/>
                }

                { user.isEmailVerified && buyingAllowed &&
                    <ChoosePlan onBuy = { updateUserState } />
                }

                { user.isEmailVerified && subscriptionType === 'yearly-pending' &&
                    <PaymentProcessing verify = {updateUserState} />
                }

                { user.isEmailVerified && subscriptionType === 'yearly-refused' &&
                    <PaymentRefused verify = {updateUserState} />
                }


                { user.isEmailVerified && loaded && isActive && !buyingAllowed && user['subscription'] && user['subscription']['validUntil'] == 'DEZ-23' &&
                    <>
                        <RenewSubscription
                            onBuy = { updateUserState } />
                    </>
                }
                
                
                { (user.isEmailVerified && loaded && isActive && !buyingAllowed) && !(user['subscription'] && user['subscription']['validUntil'] == 'DEZ-23') &&
                    <>
                        <ManageSubscription
                            subscriptionType = {subscriptionType} />
                    </>
                }


                <UserScreenCard
                    icon = {KeyIcon}
                    title = {'Trocar sua senha'}
                    content = {(
                        <>
                        { !changedPassword && 
                            <>
                                <p>Clique no botão e você receberá um email para mudar sua senha.</p>

                                <NiceButton
                                    action = {changePassword}
                                    color = '#8e0011'
                                    style = {{marginTop: '1em', marginBottom: '2em'}}
                                    text = 'TROCAR SENHA' />
                            </>                   
                        }
                        { changedPassword &&
                            <>
                                <p><strong style={{color: 'darkgreen'}}>Enviamos um link para seu email.</strong> Se não chegou, procure na caixa de spam.</p>    
                            </>
                        
                        }

                        </>
                    )} />

                    
                    { user.isEmailVerified && isActive &&
                        <UserScreenCard
                            icon={FeedbackIcon}
                            title={"Feedback do Conteúdo"}
                            content={
                                <NiceButton
                                    action = {() => navigate('/feedback', {id: 189})}
                                    color = '#8e0011'
                                    style = {{marginTop: '1em', marginBottom: '2em'}}
                                    text = 'VER SEUS FEEDBACKS' />
                            } />
                    }


                    { user.isEmailVerified && isActive &&
                        <UserScreenCard
                            icon={AmbassadorIcon}
                            title={"Embaixadores"}
                            content={
                                <NiceButton
                                    action = {() => navigate('/ambassador', {id: 189})}
                                    color = '#8e0011'
                                    style = {{marginTop: '1em', marginBottom: '2em'}}
                                    text = 'Área do embaixador' />
                            } />
                    }

                    { user.isEmailVerified && isActive &&
                        <UserScreenCard
                            icon={BookClubIcon}
                            title={"Clube do Livro"}
                            content={
                                <>
                                <ul>
                                <li><b>Próxima reunião:</b> 30/04 (terça) às 19:30h.</li>

                                <li><b>Livro:</b> Anotações de um jovem médico e outras narrativas (Mikhail Bulgákov)</li>

                                <li><i>Sugerimos a edição da Editora 34, pela qualidade excepcional e consistente das traduções.</i></li>

                                <li><b>Grupo do WhatsApp:</b> <Link to="https://chat.whatsapp.com/EDtz4QvQugdASVMd37wRzm" target="_blank" rel="noopener noreferrer">clique aqui</Link></li>
                                </ul>

                                </>
                            } />
                    }
                    

                    { user.isEmailVerified && isActive &&
                        <UserScreenCard
                            icon={HeartIcon}
                            title={"Questões e flashcards curtidos"}
                            content={
                                <NiceButton
                                    action = {() => navigate('/liked', {id: 189})}
                                    color = '#8e0011'
                                    style = {{marginTop: '1em', marginBottom: '2em'}}
                                    text = 'VER' />
                            } />
                    }

                    { user.isEmailVerified && isActive &&
                        <UserScreenCard
                            icon={SkullIcon}
                            title={"Questões e flashcards enterrados"}
                            content={
                                <NiceButton
                                    action = {() => navigate('/buried')}
                                    color = '#8e0011'
                                    style = {{marginTop: '1em', marginBottom: '2em'}}
                                    text = 'VER' />
                            } />
                    }

                    { user.isEmailVerified && isActive &&
                        <UserScreenCard 
                            icon={DangerIcon}
                            title={"Resetar histórico"}
                            content={
                                <div className='container row'>
                                    <span className='col d-flex justify-content-center align-items-center'>
                                        <DeleteStatisticsButton testType = {testType_flashcard} />
                                    </span>

                                    <span className='col d-flex justify-content-center align-items-center'>
                                        <DeleteStatisticsButton testType = {testType_mcq} />
                                    </span>
                                </div>
                            }
                        />
                    }

                        
            </UserScreenContainer> */}
            
        </AppContainer>

    )
}






export function txtInput(holder, field, setField, type = 'text') {
    return (
        <input 
            className = 'loginInput'
            placeholder = {holder}
            type = {type} 
            value = {field}
            onChange = {(e) => setField(e.target.value)} />
    )
}


export function emailInput(holder, field, setField, type = 'email') {

    let modified = field.trim().toLowerCase()

    return (
        <input 
            className = 'loginInput'
            placeholder = {holder}
            type = {type} 
            value = {modified}
            onChange = {(e) => setField(e.target.value)} />
    )
}
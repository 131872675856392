import React from 'react';
import MSCard from './MSCard';
import BackIcon from '../assets/back_colored.png';
import BrainIcon from '../assets/brain.png'
import Strong from './../assets/strong.png'
import { useState } from 'react';
import { testType_flashcard, testType_mcq } from '../utils/FeedbackUtils';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { db } from '../firebase/firebase-setup';
import { getLastSessionPath } from '../utils/SessionUtils';

export default function PreviousSessionCard(props) {
    const [nFlashcards, setNFlashcards] = useState()
    const [nResidencia, setNResidencia] = useState()
    const [text, setText] = useState("")

    const user = useSelector(state => state.user.data)

    const f1 = () => resumeLastSession(testType_flashcard)
    const f2 = () => resumeLastSession(testType_mcq)

    async function getUserLastSession(userID) {
        let doc = await db.doc(getLastSessionPath(userID)).get()

        let obj = {};

        if(!doc.exists) {
            obj[testType_flashcard] = []
            obj[testType_mcq] = []
            return obj
        }

        let data = doc.data()
        obj[testType_flashcard] = data[testType_flashcard] ? data[testType_flashcard] : []
        obj[testType_mcq] = data[testType_mcq] ? data[testType_mcq] : []
        return obj
    }

    async function loadTests() {
        let lastSessions = await getUserLastSession(user.id)
        setNFlashcards(lastSessions[testType_flashcard].length)
        setNResidencia(lastSessions[testType_mcq].length)
    }

    useEffect(() => {
        loadTests()
    }, [])


    useEffect(() => {
        setText(<>
            <p><strong>Retome suas sessões para zerar seus <i>flashcards</i> e suas questões. 💪🏻</strong></p>
        </>)
    }, [])

    function resumeLastSession(testType) {
        props.loadTests(testType, 'last_session', true)
    }

    return (
        <MSCard
            title = {"Sessão anterior"}
            icon = {BackIcon}

            flashcards = {nFlashcards}
            flashcardsAction = {f1}
            flashcardsTxt = {'Retomar sessão de flashcards'}

            residencia = {nResidencia}
            residenciaAction = {f2}
            residenciaTxt = {'Retomar sessão de questões'}

            text = {text}
            noBttnImg = {BrainIcon}
            oneBttnImg = {Strong} />
    );
}
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import session        from './../controllers/Session'
import { useIdleTimer } from 'react-idle-timer'
import TestScreen from './TestScreen';
import FlashcardQuestionJSX from './FlashcardQuestionJSX';
import McqQuestionJSX from './McqQuestionJSX';
import { sleep, toastMsg } from '../utils/Utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../redux/loadingSlice';
import { testType_flashcard, testType_mcq } from '../utils/FeedbackUtils';


export default function QuestionScreen() {
    const dispatcher = useDispatch();
    let navigate = useNavigate();
    const [q, setQ] = useState()
    

    useEffect(() => {
        if (!session || !session.created) {
            console.log("QuestionScreen: session not loaded, going back.")
            navigate('/app')
        }
        else {
            if ( session.noMoreTests() ) {
                console.log("QuestionScreen: no more tests, going back")

                // TODO Se ainda estamos submetendo em /reviewed tests/ os dados
                // da última revisão, tem chance dela aparecer na última tela? E.g., caso
                // o get() seja mais rápido que set() do último teste.
                exitSession()
            }
            else {
                console.log("QuestionScreen: have more tests, loading")
                tryToLoadScreen()
            }
        }

    }, [])


    

    function goToPreviousQuestion() {
        // Se usuário ainda não voltou uma questão, deixe ele voltar
        if ( session.userCanGoBack() ) {
            session.goBackOnAnswer()
            loadScreen()
        } else {
            // Caso contrário, não deixe ele voltar e exiba uma mensagem na tela.
            // Essa mensagem NÃO deveria aparecer, pois temos a redundância
            // de não mostrar o botão.
            toastMsg('⚠️ Você pode voltar apenas uma questão por vez!')
        }
    }


    async function onExitAction() {
        // Essa função (em teoria) é chamda sempre que o usuário sai da sessão,
        // seja através do botão de voltar ou por ter feito o último teste.
        console.log("onExitAction(): finishing up...")
        dispatcher(setIsLoading(true))

        // Salvamos no Firebase as respostas anteriores do usuário e, também, o ponto de onde ele parou (caso queira retornar futuramente à sessão)
        // Em teoria, j-2 já foi salvo quando ele chegou aqui, então só
        // resta j-1.
        if (session.currentIndex >= 1) {
            session.updateStatisticsForQuestion(session.currentIndex - 1)
            session.updateLastSession(session.currentIndex - 1)
        }

        if (session.currentIndex >= session.session.length) {
            // Se finalizou a sessão de fato, excluímos
            // Passamos algo maior para o slice zerar
            session.updateLastSession(session.session.length + 1)
        }
        else {
            // Se saiu antes de responder todas, salvamos
            session.updateLastSession(session.currentIndex)
        }

        await session.waitForAllStatisticsToBeUpdated()
        dispatcher(setIsLoading(false))
    }


    async function exitSession() {
        await onExitAction()
        navigate('/app')
    }


    const handleOnIdle = event => {
        console.log('QuestionScreen: user is idle, going back')
        exitSession()
    }


    // To avoid logging non-sense use times if user let the tab open
    // and go away, we use an idle timer. Detects 90 seconds.
    //  https://github.com/SupremeTechnopriest/react-idle-timer
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: handleOnIdle,
    })



    async function tryToLoadScreen() {
        // Se a próxima questão não foi baixada, peça para a session avisar quando o download terminar
        if( !session.questionIsDownloaded() ) {
            dispatcher(setIsLoading(true))
            console.log(`QuestionScreen >>> questão ainda não foi baixada... aguardando!`)

            await sleep(500)
            session.addTemporaryListener(tryToLoadScreen)
        } else {
            // Se a questão já foi baixada, carregue a tela
            loadScreen()
        }
    }


    function loadScreen() {
        dispatcher(setIsLoading(false))

        // Quando exibimos a questão, iniciamos o timer.
        // É fidedigno para registrar quanto tempo o usuário passou
        // de fato respondendo, mas não irá mensurar dois tempos de uso:
        //      - tempo antes de clicar em "Pular questão"
        //      - tempo antes de sair da sessão 
        //
        session.startMeasuringTime()
        setQ( getTestJSX() )
    }


    function getTestJSX() {   
        if (session.testType === testType_flashcard) {
            return (
                <FlashcardQuestionJSX
                    id           = {Math.random()}
                    moveForward  = {showAnswer}
                    goToPreviousQuestion = {goToPreviousQuestion} />
            )
        }
        else if (session.testType === testType_mcq) {
            return (
                <McqQuestionJSX
                    id = {Math.random()}
                    moveForward  = {showAnswer}
                    goToPreviousQuestion = {goToPreviousQuestion} />
            )
        }
        else {
            console.log("QuestionScreen: invalid test type")
        }
    }



    function showAnswer() {
        // Raramente, o teste é inválido -- por alguma razão não foi baixado. Nesse caso,
        // testamos por isso.
        const valid = session.currentTestValid()
        if (!valid) {
            console.log('Teste inválido')
            session.jumpInvalidTest()
            loadScreen()
        }
        else {
            navigate("/answer")
        }
    }
    

    return (
        <>
        { session && session.created && 
            <TestScreen
                allowGoBack = {true}
                goBackAction = {onExitAction}
                testJSX = { q } />
        }
        </> 
    )

}
import React, { useState } from 'react';
import { useSelector } from 'react-redux' 

import './FeedbackReviewCard.css'
import { db } from '../firebase/firebase-setup';
import { mapIcon } from './FeedbackUtils';
import OslerButton from '../components/OslerButton';


export default function FeedbackReviewCard({ handleResolve, feedback }) {

    const user = useSelector(state => state.user.data)
    const [willSolve, setWillSolve] = useState(false)
    const [willConfirm, setWillConfirm] = useState(false)
    const [responseType, setResponseType] = useState(false)
    const [text, setText] = useState('')

    function getMsg(response) {
        switch (response) {
            case 'accepted':
                return 'Seu feedback foi aceito e implementado.'

            case 'lack-information':
                return 'Não aceitamos seu feedback porque faltou uma referência e/ou clareza.'

            case 'declined':
                return text

            case 'is-question':
                return 'Este canal visa a correção de erros, e não a resposta de dúvidas.'

            case 'is-bug':
                return 'Por favor, encaminhe bugs para suporte@osler-ensino.com. Este canal é para a correção do conteúdo.'

            case 'custom-response':
                return text

            // Não deveria ocorrer.
            default:
                return 'Obrigado!'
        }
    }


    function solveFeedback() {
        const currentDate = new Date();

        const data = {
            'solved' : true,
            'osler_answer' : getMsg(responseType),
            'osler_decision' : responseType,
            'solved_date': currentDate.toLocaleString(),
            'solved_date_ms': currentDate.getTime(),
            'solved_by' : user.email
        }

        let path = `/users/${feedback['user_id']}/feedback/Flashcards/disliked/${feedback['user_feedback_doc_id']}`
        db.doc(path).set(data, {merge: true})
        
        let path2 = `/feedback/Flashcards/disliked/${feedback['docId']}`
        db.doc(path2).set(data, {merge: true})
        
        handleResolve(feedback['docId'])
    }


    return (
        <div key={feedback.id} className = 'FeedbackGroupCard-Container'>
            
            <div className={'FeedbackGroupCard-Content'}>
                <p className = 'FeedbackGroupCard-Top'>
                    <span className = 'FeedbackGroupCard-Icon'> { mapIcon(feedback.type)} </span>
                    <span className = 'FeedbackGroupCard-TestID'>{feedback['test_id']} </span>
                    {/* <span className = 'FeedbackGroupCard-Type'> { mapMethodology(feedback.type)}</span> */}
                </p>

                <p className = 'FeedbackGroupCard-Detail'>
                    <span>{feedback['date'].split(',')[0]} - </span>
                    <span>{feedback['user_email']} </span>
                </p>


                <p className = 'FeedbackGroupCard-Text'>{feedback.user_feedback}</p>
            </div>

            { !feedback['solved'] && 
                <div className = 'FeedbackGroupCard-Buttons'>
                    { !willSolve &&
                        <div className = 'FeedbackGroupCard-Bttn dashboard-bttn button-blue' onClick={() => setWillSolve(true)}>
                            Resolver
                        </div>
                    }
                    { willSolve && !responseType && !willConfirm && 
                    <>

                    <OslerButton
                        onClick={() => setWillSolve(false)}
                        text='Voltar'
                        icon='🔙'
                        bgColor='red'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('accepted')}
                        text='Aceito'
                        icon='✅'
                        bgColor='grey'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('lack-information')}
                        text='Impreciso'
                        icon='🤷🏻‍♂️'
                        bgColor='grey'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('custom-response')}
                        text='Customizado'
                        icon='✍🏼'
                        bgColor='grey'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('is-question')}
                        text='Dúvida'
                        icon='🙅🏻‍♂️'
                        bgColor='grey'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('is-bug')}
                        text='Bug'
                        icon='🐛'
                        bgColor='grey'
                        style = {{marginBottom: '1em'}} />

                    <OslerButton
                        onClick={() => setResponseType('declined')}
                        text='Recusado'
                        icon='👎🏻'
                        bgColor='grey' />

                    </>
                    }
                    { willSolve && responseType && (responseType != 'declined') && (responseType != 'custom-response') && !willConfirm && 
                    <>
                    <>
                        <div><p>Enviaremos: "{getMsg(responseType)}"</p></div>

                        <div className = 'Input-Confirm-Bttns'>
                            <OslerButton
                                onClick={() => {
                                    setWillSolve(true)
                                    setResponseType(false)
                                }}
                                bgColor = 'red'
                                text = 'Voltar'
                                style = {{marginRight: '1em'}} />


                            <OslerButton
                                onClick={() => solveFeedback()}
                                bgColor = 'green'
                                text = 'Confirmar' />
                            </div>
                    </>

                    </>
                    }
                    { willSolve && responseType && (responseType == 'declined' || responseType == 'custom-response') && !willConfirm && 
                    <>

                        <div className="TextBox-Input">
                            <textarea
                                className = "TextBox-Area"
                                id = {'feedbackTextInput'}
                                placeholder = {'Ingrid, seja educada.'}
                                value = {text}
                                onChange={e => setText(e.target.value)}
                                required
                                // rows={5}
                                // cols = {50}
                                // value={props.previousText}
                                />
                        </div>
                        <div className = 'Input-Confirm-Bttns'>
                            <OslerButton
                                onClick={() => {
                                    setWillSolve(true)
                                    setResponseType(false)
                                    setText('')
                                }}
                                bgColor = 'red'
                                text = 'Cancelar'
                                style = {{marginRight: '1em'}} />

                            
                            <OslerButton
                                onClick={() => solveFeedback()}
                                bgColor = 'green'
                                text = 'Confirmar' />

                        </div>
                    </>
                    }

                </div>
            }
            { feedback['solved'] && 
                <div className = 'FeedbackGroupCard-Buttons'>
                    <p className = 'FeedbackReviewCard-Check'>✅</p>
                </div>
            }
        </div>
    );
}



import Test from './Test.js'

import { changeCommandsForHtmlImages } from './../utils/ImageLoader'

import Stetho from './../assets/stethoscope.png'


class MultipleChoiceQuestion extends Test {
    constructor(testID, data) {
        super(testID, data, 'Residencia')


        this.question = data.question
        this.answer = data.answer

        this.institution = data.exam_institution
        this.year        = data.exam_year
        this.observations = data.observations

        this.alternatives = data.list_alternatives
        this.explanations = data.list_explanations

        this.nAlternatives = this.alternatives.length

        // Improvável que tenhamos mais do que 8 alternativas.
        this.letters = [" A) ", " B) ", " C) ", " D) ", " E) ", "F) ", "G) ", "H) "];
        this.dataWasLoaded = false
    }


    async loadData(statisticsManager) {
        if (this.dataWasLoaded) {
            return;
        }

        await super.loadStatistics(statisticsManager)

        this.question = await changeCommandsForHtmlImages(this.question, "-mcq-img")

        // TODO E pensar que poderíamos fazer isso em paralelo...
        // Antes de 'this.explanations.length', ele usava this.nAlternatives, mas isso não faz sentido.
        for (let i = 0; i < this.explanations.length; i++) {
            let explanation = this.explanations[i]

            if (explanation === "null") {
                explanation = ""
            }

            // explanation = this.processLinkCommand(explanation)
            explanation = await changeCommandsForHtmlImages(explanation, "-mcq-img")

            this.explanations[i] = explanation
        }


        for (let i = 0; i < this.alternatives.length; i++) {
            this.alternatives[i] = await changeCommandsForHtmlImages( this.alternatives[i] )
        }

        
        this.dataWasLoaded = true;
    }



    getQuestion() {
        if (this.observation === "ANULADA") {
            // A expectativa é inserir o (ANULADA) dentro do parágrafo, mas lembre-se
            // que o enunciado começa com um <p>.
            return `<p> <b>(ANULADA!)</b> ${this.question.substring(3)}`
        }
        else {
            return this.question
        }
    }






}

export default MultipleChoiceQuestion;
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Search from './../assets/search.png';
import X from './../assets/x-icon.png';
import { textWhiteBlack } from '../tests/FlashcardsStyles';
import debounce from 'lodash/debounce';


const StyledSearchBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;


    ${props => props.$showBorder && `
        border: 1px solid #cecece;
        border-radius: 0.3em;
    `}

    ${props => props.theme.darkMode ? `
        background-color: rgb(90, 90, 90);
        color: rgb(200, 200, 200);
    ` : `
        background-color: white;
        color: black;
    `}


    @media (max-width: 2400px) {
        width: 40%;
    }

    @media (max-width: 1800px) {
        width: 80%;
    }

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const StyledSearchBarIcon = styled.img`
    max-height: 1.5em;
    position: absolute;
    left: 0.8em;
`;

const StyledSearchBarCancel = styled.img`
    cursor: pointer;
    max-height: 1em;
    position: absolute;
    right: 0.5em;
`;

const StyledSearchInput = styled.input`
    font-size: 0.9em;
    padding: 0.4em 0 0.4em 2.6em;
    border-radius: 0.3em;
    outline: none;
    border: none;

    margin-left: 0.5em;


    background-color: transparent;
    width: 100%;

    ${textWhiteBlack}

    @media (max-width: 500px) {
        margin: 0 0 0 0.8em;
        height: 3em;
        font-size: 0.8em;
    }
`;

export default function SearchBar(props) {
    const [localValue, setLocalValue] = useState(props.currentString);

    // com 200ms, o usuário escreve o necessário, e evitamos que seja enviado coisas
    // como só uma letra, o que obviamente deixa o search malco

    const debouncedSearch = useCallback(
        debounce((value) => {
            props.setSearch(value);
        }, 200),
        [props.setSearch]
    );

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const handleChange = (e) => {
        const value = e.target.value;
        setLocalValue(value);
        debouncedSearch(value);
    };

    const handleClear = () => {
        setLocalValue('');
        props.setSearch('');
    };

    return (
        <StyledSearchBar $showBorder = {props.showBorder}>
            {props.visible && (
                <>
                    <StyledSearchBarIcon src={Search} />
                    <StyledSearchInput
                        type="text"
                        value={localValue}
                        onChange={handleChange}
                    />

                    { localValue && localValue != "" &&
                        <StyledSearchBarCancel src={X} onClick={handleClear} />
                    }
                </>
            )}
        </StyledSearchBar>
    );
}
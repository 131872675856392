import React, { useEffect, useState } from 'react'
import { htmlToReact } from '../utils/HtmlToReact'
import session        from '../controllers/Session'
import ShortcutsDialog from './ShortcutsDialog'
import { useHotkeys } from 'react-hotkeys-hook'
import TestContainer from './TestContainer'
import MoveForwardBttn from './MoveForwardBttn'
import styled from 'styled-components'
import { TestArea, clozeStyle, contentStyle, flashcardStyle } from './FlashcardsStyles'
import QuestionScreenHelperBttns from './QuestionScreenHelperBttns'
import { ColumnCSS, SelectableText } from '../components/BasicComponents'




const FlashcardQuestion = styled.div`
    ${flashcardStyle}
    text-align: center;
    ${SelectableText}
`


const BttsContainer = styled.div`
    width: 100%;
    ${ColumnCSS}
`

export default function FlashcardQuestionJSX(props) {

    const [flashcard, setFlashcard] = useState("")

    const [showDialog, setShowDialog] = useState(false)

    useEffect(() => {
        // Mecanismo para forçar uma nova renderização, garantindo
        // que o conteúdo está atualizado quando há mudança no parent.
        update()
    }, [props.id])



    function update() {
        const content = session.getQuestion()
        const HTML = htmlToReact( content )
        setFlashcard(HTML)
    }



    function moveEndQueue() {
        session.moveCurrentTestToEndOfQueue()
        update()
    }


    function moveForward() {
        props.moveForward()
    }


    // https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/setting-callback-dependencies
    useHotkeys('ctrl+z', props.goToPreviousQuestion)
    useHotkeys('shift+p', moveEndQueue)

    useHotkeys('space', moveForward)
    useHotkeys('enter', moveForward)

    
    return (
        <>


        <TestArea>
            <TestContainer
                jsx = {
                    <FlashcardQuestion>
                        {flashcard}
                    </FlashcardQuestion> 
            } />

                
            <BttsContainer>
                <QuestionScreenHelperBttns
                    goToPreviousQuestion = {props.goToPreviousQuestion}
                    moveEndQueue = {moveEndQueue}
                    testType = {'Flashcards'} />


                <MoveForwardBttn
                    moveForward = {moveForward} />
            </BttsContainer>
        </TestArea>
        </>
    );
}
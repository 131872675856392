import React, { useEffect, useRef, useState } from 'react';
import tree, { TreeFilters } from './Tree'

export default function TreeJSX(props) {
    const [treeJSX, setTreeJSX] = useState()

    const testType = useRef()
    const includeAnuladas = useRef()
    const treeFilter = useRef()
    
    useEffect(() => { 
        testType.current = props.testType
        tree.start(testType.current)

        includeAnuladas.current = props.filters.includes('include_anuladas')
        tree.loadData(!includeAnuladas.current)
        tree.createTree()

        renderTree()
    }, [])



    useEffect(() => {
        const testTypeChanged = props.testType != testType.current
        const anuladasChanged = includeAnuladas.current != props.filters.includes('include_anuladas')

        if (testTypeChanged || anuladasChanged) {
            if (testTypeChanged) {
                console.log(`TreeJSX(): mudou o tipo de teste para ${props.testType} vamos recarregar os dados...`)
                testType.current = props.testType
                tree.start(testType.current)
            }

            if (anuladasChanged) {
                console.log('TreeJSX(): mudou opção de anulada, vamos recarregar os dados...')
                includeAnuladas.current = props.filters.includes('include_anuladas')
            }

            tree.loadData(!includeAnuladas.current)
            tree.createTree()
        }


        let newTreeFilter;
        if (props.filters.includes('only_reviews')) {
            newTreeFilter = TreeFilters.PENDING_REVIEWS_ONLY
        }
        else if (props.filters.includes('only_news')) {
            newTreeFilter = TreeFilters.NEW_TESTS_ONLY
        }
        else if (props.filters.includes(TreeFilters.ALL_REVIEWS_ONLY)) {
            newTreeFilter = TreeFilters.ALL_REVIEWS_ONLY
        }
        else if (props.filters.includes(TreeFilters.SEEN_TESTS_ONLY)) {
            newTreeFilter = TreeFilters.SEEN_TESTS_ONLY
        }

        if (newTreeFilter != treeFilter.current) {
            treeFilter.current = newTreeFilter
        }


        console.log('o filtro será')
        console.log(treeFilter.current)


        renderTree()
    }, [props])


    function renderTree() {
        console.log("Nova renderização...")
        
        setTreeJSX(
            tree.createTreeJSX(renderTree, props.searchedString, props.mode, treeFilter.current )
        )
    }
    

    return (
        <>
            {treeJSX}
        </>
    )
}
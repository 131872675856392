import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { blue, blueHover } from '../tests/FlashcardsStyles'

// Definições de cores para os botões

const colorStyles = {
    grey: css`
      background-color: ${props => props.$selected ? (props.theme.darkMode ? '#4a4a4a' : '#d8d8d8') : (props.theme.darkMode ? '#333333' : '#eeeeee')};
      color: ${props => props.theme.darkMode ? 'white' : 'black'};
      &:hover {
        background-color: ${props => props.$selected ? (props.theme.darkMode ? '#4a4a4a' : '#d8d8d8') : (props.theme.darkMode ? '#3d3d3d' : '#e6e6e6')};
      }
    `,
    darkGrey: css`
      background-color: ${props => props.$selected ? (props.theme.darkMode ? '#777777' : '#484f53') : (props.theme.darkMode ? '#a6a6a6' : '#6c757d')};
      color: ${props => props.theme.darkMode ? 'white' : 'white'};
      &:hover {
        background-color: ${props => props.$selected ? (props.theme.darkMode ? '#777777' : '#484f53') : (props.theme.darkMode ? '#999999' : '#5a6268')};
      }
    `,
    blue: css`
      background-color: ${props => props.$selected ? (props.theme.darkMode ? blue : blueHover) : (props.theme.darkMode ? blueHover : blue)};
      color: white;
      &:hover {
        background-color: ${props => props.theme.darkMode ? blue : blueHover};
      }
    `,
    red: css`
      background-color: ${props => props.$selected ? (props.theme.darkMode ? '#cc3d3d' : '#b12925') : (props.theme.darkMode ? '#ff6b6b' : '#d9534f')};
      color: white;
      &:hover {
        background-color: ${props => props.$selected ? (props.theme.darkMode ? '#cc3d3d' : '#b12925') : (props.theme.darkMode ? '#e84a4a' : '#c9302c')};
      }
    `,
    green: css`
      background-color: ${props => props.$selected ? (props.theme.darkMode ? '#2d612d' : '#0f610f') : (props.theme.darkMode ? '#3c763c' : '#199419')};
      color: white;
      &:hover {
        background-color: ${props => props.$selected ? (props.theme.darkMode ? '#2d612d' : '#0f610f') : (props.theme.darkMode ? '#336033' : '#137813')};
      }
    `,
  };

  
const CustomButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.$align};
    align-items: center;
    align-self: center;

    text-align: left;
    margin-bottom: 0px;
    border: none;
    font-weight: bold;
    ${props => props.$uppercase && `text-transform: uppercase;`}
    
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0.2, 0.2, 0.2, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;

    ${props => props.$size === 'small' ? `
        padding: 5px 10px; // Reduzir o padding para um botão menor
        font-size: 0.7em; // Reduzir ligeiramente o tamanho da fonte
        max-width: 16em;
        border-radius: 0.5em;
    `:`
        padding: 10px 20px;
        font-size: 1em;
        max-width: 16em;
        border-radius: 5px;
    `}

    
    ${props => colorStyles[props.$bgColorNormalized] || css`
        background-color: white;
        color: black;
    `}
    ${props => props.$selected && css`
        box-shadow: none;
        cursor: default;
    `}

    @media (max-width: 500px) {
        font-size: 0.8em;
        align-self: flex-start;
    }
`

const ButtonIcon = styled.span`
    font-size: 1.1em;
    margin-right: 0.3em;
`

// Definindo o estilo para a imagem do botão
const ButtonImage = styled.img`
    height: 1.4em;
    margin-right: 0.3em;
`


export default function OslerButton({ 
    icon, img, text, bgColor, selected, onClick, style, size = 'normal', children, 
    align = 'center', uppercase = true}) {
    
    
    const [bgColorNormalized, setBgColorNormalized] = useState(bgColor)

    useEffect(() => {
        let normalizedColor = bgColor?.toLowerCase() || 'grey'

        if (normalizedColor === 'gray') normalizedColor = 'grey'
        if (normalizedColor === 'dark-gray') normalizedColor = 'darkGrey'
        setBgColorNormalized(normalizedColor)
    }, [bgColor])


    return (
        <CustomButton
            $align={align}
            $size={size}
            $bgColorNormalized={bgColorNormalized}
            $selected={selected}
            $uppercase={uppercase}
            onClick={!selected ? onClick : undefined}
            style={style}
        >
            {icon && <ButtonIcon>{icon}</ButtonIcon>}
            {img && <ButtonImage src={img} />}
            {text && text}
            {children}
        </CustomButton>
    )
}
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import ChoosePlan from './checkout/ChoosePlan';
import PaymentProcessing from './PaymentProcessing';
import PaymentRefused from './PaymentRefused';


export default function Buy(props) {
    
    console.log(props)

    return (
        <>

        { props.buyingAllowed &&
           <ChoosePlan onBuy = { props.updateScreen } />
        }
        { !props.buyingAllowed && props.subscriptionType === 'yearly-pending' &&
            <PaymentProcessing verify = { props.updateScreen } />
        }
        { !props.buyingAllowed && props.subscriptionType === 'yearly-refused' &&
            <PaymentRefused verify = { props.updateScreen } />
        }
        </>
    )
}
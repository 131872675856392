import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsLoading } from '../../redux/loadingSlice'

import './CheckoutPage.css'

import { functions } from '../../firebase/firebase-setup'

import MaskInput from 'react-maskinput';

import Select from 'react-select'

import InsertCoupon from './InsertCoupon'
import { sleep, toastMsg } from '../../utils/Utils';

import OslerButton from '../../components/OslerButton'
import PaymentMethodSelector from './PaymentMethodSelector'
import { Element, scroller } from 'react-scroll';


export default function CheckoutPage(props) {
	const dispatch = useDispatch();
    const user = useSelector(state => state.user.data)

    const [loaded, setLoaded] = useState(false)

    const [cardName, setCardName] = useState("")
	const [cardNumber, setCardNumber] = useState("")
    const [celular, setCelular] = useState("")
	const [CPF, setCPF] = useState("")
	const [expirationDate, setExpirationDate] = useState("")
	const [security, setSecurity] = useState("")
    const [cvvMask, setCvvMask] = useState("000")

    const [paymentMethod, setPaymentMethod] = useState(false)

    const [nInstallments, setInstallments] = useState()

    const [errorMessage, setErrorMessage] = useState(false)

    // O planID pode ser modificado se o usuário inserir um cupom
    // Mas nós guardamos o original para evitar o bug onde, ao
    // tentar confirmar o cupom ou inserir outro, não era reconhecido.
    const planID = useRef(props.planID)
    const originalPlanID = useRef(props.planID)

    const coupon = useRef("")
    const planData = useRef(undefined)

    const [chosenInstallments, setChosenInstallments] = useState(false)

    const blockBuying = useRef(false)

    useEffect(() => {
        if (props.planID) {
            async function loadPlan() {
                dispatch(setIsLoading(true))
                
                const getPlans = functions.httpsCallable('getTransactionPlan')
                const response = await getPlans({ planID : props.planID})

                if (response && response.data) {
                    updatePlan(props.planID, response.data)

                    setLoaded(true)
                    dispatch(setIsLoading(false))
                }
                else {
                    props.goBack()
                }
            }

            loadPlan()

        }
        else {
            props.goBack();
        }
    }, [])



    function updatePlan(paramPlanID, paramPlanData, paramCouponCode = "") {
        planID.current = paramPlanID
        planData.current = paramPlanData
        coupon.current = paramCouponCode

        let options = []
        for (let i = 1; i <= planData.current.maxInstallments; i++) {
            options.push({
                value: `${i}`,
                label: getParcelasText(i)
            })
        }

        setInstallments( options )
    }


    function getReadablePrice() {
        return Math.round(planData.current.amount / 100).toLocaleString('pt-BR')
    }

    function getParcelasText(numberInstallments) {
        const price = planData.current.amount / 100
        const installmentPrice = Math.round(price / numberInstallments)
        const readablePrice = installmentPrice.toLocaleString('pt-BR')

        return (
            `${numberInstallments}x de R$${readablePrice}`
        )
    }


    async function buySubscription() {

        if (!paymentMethod) {
            createError(<p><b>Escolha um método de pagamento.</b></p>)
        }

        if (!isPersonalInfoValid()) {
            // Não criamos diálogo porque isso já é feito dentro da função.
            console.log("Dados pessoais inválidos")
            return;
        }

        // I.e., se for falso, se não está bloqueado
        // então permitimos a compra, mas bloqueamos
        // uma nova logo antes.
        if ( !blockBuying.current ) {
            blockBuying.current = true;
            if (paymentMethod === 'credit_card') {
                buyWithCard()
            }
            else {
                buyWithBoleto()
            }
        }
        else {
            // Isso nem deveria aparecer.
            createError(<p><b>Devagar, jovem.</b> Parece que você já tentou comprar. Cuidado para não clicar mais de uma vez e criar mais de uma transação por equívico.</p>)

            blockBuying.current = false;
        }
	}


    function isPersonalInfoValid() {
        if (!paymentMethod) {
			createError(<p><b>Escolha um modo de pagamento</b> Cartão (em até 12x) ou boleto (à vista), selecionando no topo.</p>)

            return false
        }
        else if (celular === "") {
			createError(<p><b>Preencha o celular.</b> Para evitar fraudes, o gateway de pagamento exige um celular váido.</p>)

            return false
		}
		else if (CPF === "") {
            createError(<p><b>Você não preencheu o CPF.</b> Lembre, precisa ser do comprador (i.e., do dono do cartão de crédito ou da conta corrente que pagará o boleto).</p>)

            return false
		}
        else if (CPF.length < 14) {
            // 11 digitos + 02 pontos + traço
            createError(<p><b>Verifique se o CPF está correto.</b></p>)
            return false;
        }
        else {
            return true
        }
    }


	function isCreditCardDataValid() {
		if (cardName === "") {
			createError(<p><b>Você não inseriu o <u>nome</u> do responsável pelo cartão!</b></p>)
			return false;
		}
		else if ( cardNumber.toString().length < 16) {
			createError(<p><b>Atente-se ao <u>número do cartão</u>.</b> Parece ter algo errado.</p>)
			return false;
		}
		else if ( expirationDate.toString().length < 4 ) {
			createError(<p><b>Atente-se ao <u>data de expiração</u> do cartão.</b></p>)
			return false;
		}
		else if ( security.toString().length < 3 ) {
			createError(<p><b>Atente-se ao <u>código de segurança</u> do cartão.</b></p>)
			return false;
		}
        else if (!chosenInstallments) {
            createError(<p><b>Escolha o número de parcelas.</b></p>)
            return false
        }
		else {
            const parts = expirationDate.split('/')
            const month = parseInt(parts[0])
            const year = parseInt(parts[1])

            console.log(month)
            console.log(year)
            
            if (month <= 0 || month > 12) {
                createError(<p><b>Mês de validade do cartão está errado.</b></p>)
                return false
            }
            else if (year < 23) {
                createError(<p><b>Ano de validade do cartão está errado.</b></p>)
                return false
            }
            else {
                return true
            }
		}
	}


	function createInput(label, value, setValue, mask, placeholder = "", type = 'number') { 
        return (
            <div className='PaymentInformation-InputDiv'>
                <label className='BuySubscription-InputLabel' > { label } </label>
				    
                    { mask && 
                        <MaskInput
                            className='PaymentInfo-InputTxt'
                            onChange={ (event) => { setValue(event.target.value)}}
                            mask={mask}
                            maskString={mask}
                            value={value}
                            placeholder={placeholder}
                            size={50}
                            type = {'tel'}/>
                    }

                    {/* Only for the NAME on the credit card */}
                    { !mask && 
                        <input
                            placeholder = {placeholder}
                            type = {'text'}
                            value = { value.replace(/[0-9]+/g, '').toUpperCase() }
                            onChange = {(e) => setValue(e.target.value)}
                            className='PaymentInfo-InputTxt' />
                    }
					
            </div>
        )
	}


    async function buyWithBoleto() {
        if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}

        try {
            // TODO TUD OISSO QUE ESTAMOS TESTANDO EM RESULT.DATA.DATA NÃO EXISTE. NÃO ESTAMOS MANDANDO DE VOLTA. E NO CARTÃO DE CRÉDITO TAMBÉM NÃO.
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('boletoTransaction')
			const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,

                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
					email: user.email,
                    CPF: (CPF.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: celular.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '') 
				},
			});

            // Não temos garantia, mesmo que tenha ocorrido algum erro, que não houve
            // mudança do perfil do usuário (e.g., pagamento correto, mas algum crash
            // inexplicável do Firebase). Para ser redundante, verificamos alterações
            // no profile de qualquer jeito.
            console.log('boleto: Chamando onBuy()')
            props.onBuy()


            dispatch(setIsLoading(false))
            if (result.data.success) {
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                createError(<><p><b>Seu pagamento foi recusado.</b> O que é muito estranho, pois você está pagando com boleto. Por favor, tire um print e envie para o suporte.</p><p>Mais informações: {JSON.stringify(result.data)} </p></>)
			}

            blockBuying.current = false;
            
        } catch (error) {
            props.onBuy()

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }



    async function buyWithCard() {
        if (!isCreditCardDataValid()) {
            console.log("Dados do cartão inválidos")
            blockBuying.current = false;
            return;
        }
        
		if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}        
        
        try {
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('creditCardTransaction')
			const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,

                card_number: cardNumber.replace(/ /g, ''),
				card_cvv: security,
				card_expiration_date: expirationDate.replace(/[/]/g, ''), 
				card_holder_name: cardName,

                installments: chosenInstallments,

                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
					email: user.email,
                    CPF: (CPF.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: celular.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '') 
				},
			});

            dispatch(setIsLoading(false))

            /*
                O objeto result contém o objeto data, que contém os dados retornados pelo Firebase.
                Hoje, data contém os campos:
                    success - booleano - indica se a transação deu certo ou não
                    msg - string - uma mensagem explicando qual erro ocorreu, e que podemos mostrar ao usuário
                    code - string - uma codificação do tipo de erro, para identificarmos e tratarmos casos específicos
            */
            console.log(result)
            props.onBuy('credit_card', planData.current.amount, coupon.current)

            if (result.data.success) {
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                // A realidade é que isso aqui raramente ocorre. Na maioria das vezes, vai para o pending payment (!)

                if (result.data.code === 'duplicated_transaction') {
                    createError(<><b>Parece que você já tentou comprar.</b> Para sua segurança, bloqueamos com o intuito de evitar uma transação duplicada. Recarregue a página e, se o erro persistir, envie um e-mail para suporte@osler-ensino.com que retornaremos em menos de 24 horas.</>)
                }
                else {
                    createError(<>
                        <p><b>Seu pagamento foi recusado.</b> Com cartão, é comum que ocorra. A seguir, as principais causas.</p>                
                        
                        <p><b>Tem limite?</b> É dura a vida do interno. Você precisa ter o limite do valor cheio. Se não tiver, é comum usar o cartão de algum familiar.</p>
    
                        <p><b>Você preencheu os dados do dono do cartão?</b> Lembre-se, precisamos do nome que está aparecendo no cartão, e do CPF dessa pessoa. Se o cartão é de um familiar, não adianta colocar seus dados.</p>
    
                        <p><b>O cartão é ELO?</b> Infelizmente, não é aceito.</p>
    
                        <p><b>O cartão bloqueou?</b> É relativamente comum o banco bloquear a transação por excesso de zelo. Ligue para eles.</p>
    
                        <p><b>Não é nada disso?</b> Mande um alô para a gente, via suporte@osler-ensino.com <u>enviando um print dessa tela</u>.</p>
    
                        <p>Mais informações: {JSON.stringify(result.data)} </p>
                    </>)
                }
			}

            blockBuying.current = false;
            
        } catch (error) {
            props.onBuy('credit_card', planData.current.amount, coupon.current)

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }
	


	function checkCardNumber(cardNumber) {
		// Se é um AmericanExpress, o CVV precisa ter quatro dígitos.
		if (cardNumber.substring(0, 2) == '34' || cardNumber.substring(0, 2) == '37') {
			setCvvMask('0000')
		}
		else {
			setCvvMask('000')
		}
		setCardNumber(cardNumber)
	}


    function setMethod(chosenMethod) {
        setPaymentMethod(chosenMethod)
        createError(false)
    }



    function isAnyFieldEmpty() {
        return cardName == '' || cardNumber == '' || celular == '' || CPF == '' || expirationDate == '' || security == ''
    }

    function boletoIsAnyFieldEmpty() {
        return celular == '' || CPF == ''
    }


    function goBack() {
        if (paymentMethod) {
            setMethod(false)
            
            console.log('SCROLLING')
            scroller.scrollTo('CheckoutPage-Top', {
                duration: 100,
                delay: 0,
                smooth: true,
                offset: -250, // Scrolls to element + 50 pixels down the page
              });
        }
        else {
            props.goBack()
        }
    }


    function createError(messageJSX) {
        if (!messageJSX) {
            setErrorMessage(false)
        }
        else {
            console.log('oi')
            setErrorMessage(messageJSX)
            scroller.scrollTo('CheckoutPage-Error', {
                duration: 100,
                delay: 0,
                smooth: true,
                offset: -50, // Scrolls to element + 50 pixels down the page
              });
        }
    }

    function getJSX() {
        return (
            <>
            
            <div className = 'CheckoutPage-Container'>

                <Element name="CheckoutPage-Top" />


                <PaymentMethodSelector
                    setMethod = {setMethod} />

            
                { paymentMethod == 'boleto' && 
                    <div className = 'PaymentInformationInput-Container'>
                        <p><b>O CPF é do <u>titular da conta corrente</u> que será utilizada para pagar o boleto.</b>O celular pode ser o seu, é só para garantir uma via de contato além do e-mail.</p>

                        <form className='PaymentInformation-Form' >
                            { createInput('Seu celular', celular, setCelular, '+ 00 (00) 0 0000 0000', '+55 (DD) 9 0000 0000') }

                            { createInput('CPF de quem pagará', CPF, setCPF, '000.000.000-00', '000.000.000-00') }
                        </form>

                        <InsertCoupon
                            originalPlanID = {originalPlanID.current}
                            planID = {planID.current}
                            changePlan = { updatePlan } />
                    </div>
                }

                { paymentMethod == 'credit_card' && 
                    <div className = 'PaymentInformationInput-Container'>
                        <p><b>Atenção: os dados são do <u>titular do cartão</u>.</b> Se você está usando o cartão de um familiar, coloque o nome e o CPF dele. O celular pode ser o seu, é só para garantir uma via de contato além do e-mail.</p>

                        <form className='PaymentInformation-Form' >
                            { createInput('Seu celular', celular, setCelular, '+ 00 (00) 0 0000 0000', '+55 (DD) 9 0000 0000') }

                            { createInput('Nome que está no cartão', cardName, setCardName, null, 'WILLIAM OSLER') }

                            { createInput('CPF do titular do cartão', CPF, setCPF, '000.000.000-00', '000.000.000-00') }
                            
                            { createInput('Número do cartão', cardNumber, checkCardNumber, '0000 0000 0000 0000', '4242 4242 4242 4242') }

                            { createInput('Data de vencimento', expirationDate, setExpirationDate, '00/00', 'MM/YY') }

                            { createInput('Código de segurança', security, setSecurity, cvvMask, 'CVV') }
                        </form>

                        <InsertCoupon
                            originalPlanID = {originalPlanID.current}
                            planID = {planID.current}
                            changePlan = { updatePlan } />


                        <div className = 'PaymentInput-ChooseInstallments'>
                            <label className='BuySubscription-InputLabel' > À vista ou parcelado? </label>

                            <Select
                                placeholder = 'Escolha o número de parcelas'
                                options = {nInstallments}
                                onChange={e => {
                                    console.log(e)
                                    setChosenInstallments(Number(e.value))}} />

                        </div>
                    </div>
                }


                <div className = 'CheckoutSummary'>
                    <p><strong>DETALHES DA COMPRA</strong></p> 
                    <ul> 
                        <li>Acesso completo até <strong>{planData.current.validUntilFormatted}</strong>.</li>
    
                        { !paymentMethod && 
                            <li><strong className = 'CheckoutError'>Escolha um método de pagamento.</strong></li>    
                        }

                        { paymentMethod == 'credit_card' && 
                            <li>Pagamento via cartão de crédito.</li>
                        }

                        { paymentMethod == 'credit_card' && !chosenInstallments && isAnyFieldEmpty() && 
                            <>
                            <li><strong className = 'CheckoutError'>Insira seus dados e escolha o número de parcelas.</strong></li>
                            </>                                
                        }

                        { paymentMethod == 'credit_card' && chosenInstallments && isAnyFieldEmpty() && 
                            <>
                            <li><strong className = 'CheckoutError'>Falta inserir os dados.</strong></li>
                            </>                                
                        }

                        { paymentMethod == 'credit_card' && !chosenInstallments && !isAnyFieldEmpty() && 
                            <>
                            <li><strong className = 'CheckoutError'>Escolha o número de parcelas.</strong></li>
                            </>                                
                        }

                        { paymentMethod == 'credit_card' && chosenInstallments && 
                            <li><strong>{getParcelasText(chosenInstallments)}</strong> (total de <strong>R${getReadablePrice()}</strong>).</li>                                
                        }

                        { paymentMethod === 'boleto' &&
                            <>
                            <li>Pagamento via boleto.</li>
                            <li>Valor <strong>à vista</strong> de <strong>R${getReadablePrice()}</strong>.</li>
                            </>
                        }
                        
                        { paymentMethod == 'boleto' && boletoIsAnyFieldEmpty() && 
                            <>
                            <li><strong className = 'CheckoutError'>Falta inserir seus dados.</strong></li>
                            </>                                
                        }
                    </ul>         
                </div>

            </div>


            {errorMessage &&
                <div className = 'CheckoutError-Component'>
                    <div className = 'CheckoutError-Header'>
                        <div>
                            <p className = 'CheckoutError-Title'>
                            <span className = 'CheckoutError-Emoji'>⚠️</span>
                            Habemus quaestio
                        </p>
                        <p className = 'CheckoutError-Detail'>Porque não basta dar problema, tem que ter Latim no meio.</p>
                        </div>

                        <div className = 'CheckoutError-Close' onClick = {() => setErrorMessage(false)}>
                            X
                        </div>
                    </div>


                    {errorMessage}
                </div>
            }


            <div className = 'BuySubscriptionBttns'>

                {/* Deixamos aqui para garantir que o usuário verá toda a mensagem, até os botões. */}
                <Element name="CheckoutPage-Error" />

                <OslerButton
                    onClick = {goBack}
                    text = 'Voltar'
                    bgColor = 'dark-grey' />

                <OslerButton
                    onClick = {buySubscription}
                    text = 'Comprar agora'
                    bgColor = 'green' />

            </div>
                            
    </>)
    }

    return (
        <>
        { loaded && 
            getJSX()  
        }
        </>
    )
    
}
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import OslerButton from '../components/OslerButton'
import OlserDropdown from '../components/OslerDropdown'
import { db, functions } from '../firebase/firebase-setup';
import { ColumnCSS, RowCSS } from '../components/BasicComponents';
import AmbassadorsController from './AmbassadorsController';
import { Toast } from 'bootstrap';
import { toastMsg } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice';


const PersonalData = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    align-self: flex-start;

    width: 100%;
`


const TermSelection = styled.div`
    ${ColumnCSS}

    align-self: center;
    margin-top: 3em;
    margin-bottom: 3em;

    padding: 2em;
    border: 1px solid red;
    border-radius: 0.5em;
`

const Notice = styled.p`
    font-size: 1.25em;
    font-weight: bold;
`

const TermOptions = styled.div`
    ${RowCSS}
    margin-top: 1em;
`

export default function AmbassadorDashboardProfile ({ambassador}) {
    
    const user = useSelector(state => state.user.data)
    const dispatch = useDispatch()

    const [chosenTerm, setChosenTerm] = useState('')

    const [allowEditPeriod, setAllowEditPeriod] = useState(false)

    const termOptions = useMemo(() => [
        { text: '1', id: 1 },
        { text: '2', id: 2 },
        { text: '3', id: 3 },
        { text: '4', id: 4 },
        { text: '5', id: 5 },
        { text: '6', id: 6 },
        { text: '7', id: 7 },
        { text: '8', id: 8 },
        { text: '9', id: 9 },
        { text: '10', id: 10 },
        { text: '11', id: 11 },
        { text: '12', id: 12 },
        { text: 'Já sou formado', id: 'Já formado' }
    ], [])

    
    const [localAmbassador, setLocalAmbassador] = useState(ambassador)


    async function handleUpdateProfile() {

        dispatch(setIsLoading(true))
        const date = new Date().toLocaleString('pt-BR');
        const schoolStage = {
            date_updated: date,
            term: chosenTerm === 'Já formado' ? 'Já formado' : parseInt(chosenTerm),
        }


        if (AmbassadorsController.dataLoaded) {
            // Estamos fazendo via dashboard admin
            AmbassadorsController.changeSchoolTerm(ambassador, schoolStage)

        } else {
            // É o próprio embiaxador atualizando
            const update = functions.httpsCallable('updateAmbassadorTerm')
            const result = await update({ updatedData: schoolStage });
            
            if (result.data) {
                toastMsg('Sucesso')
            } else {
                toastMsg('Deu problema, peça ajuda')
            }

        }
        
        await db.doc(`users/${user.id}`).set({ school_stage: schoolStage }, { merge: true })

        setLocalAmbassador(prevState => ({
            ...prevState,
            school_stage: schoolStage,
        }))

        setAllowEditPeriod(false)

        dispatch(setIsLoading(false))
    }


    
    return (
        <PersonalData>
            <h1>Dados Cadastrais</h1>
            <ul>
                <li><b>Nome:</b> {ambassador.name}</li>
                <li><b>Contato</b>: {ambassador.email} | {ambassador.phone}</li>
                <li><b>Instituição:</b> {ambassador.school}</li>
                <li><b>Semestre:</b> {localAmbassador.school_stage ? localAmbassador.school_stage.term : <b style={{ color: 'red' }}>sem informação de semestre</b>}</li>

                <li><b>Cupom atual:</b> <b style = {{color: 'green'}}>{ambassador.current_coupon}</b></li>
                <li>
                    <b>Cupons anteriores: </b> 
                    {ambassador.previous_coupons.length >= 1 
                        ? ambassador.previous_coupons.join(' | ') 
                        : '(nunca mudou de cupom)'}
                    </li>
                <li><b>Entrada no programa:</b> {ambassador.signup_date}</li>
                <li><b>Assinatura da Osler está ativa?</b> {ambassador.isActive ? 'Sim.' : 'NÃO'}</li>
                <li><b>Está ativo no programa de embaixadores?</b> {ambassador.status === 'active' ? 'Sim.' : <b style = {{fontSize: '1.2em', color: 'red'}}>NÃO</b>}</li>
            </ul>

            
            { (localAmbassador.school_stage && !allowEditPeriod) ? 
                (
                    <OslerButton
                        text='Mudar semestre'
                        bgColor = 'red'
                        size='small'
                        style = {{alignSelf: 'flex-end', marginBottom: '3em'}}
                        onClick={() => setAllowEditPeriod(true)} />
                ) : (
                    <TermSelection>

                    <Notice>Atualize sua informação de semestre</Notice>

                    <TermOptions>
                        <OlserDropdown
                            style={{ marginRight: '1em' }}
                            options={termOptions}
                            signal={id => setChosenTerm(id)}
                        />

                        <OslerButton
                            text='Confirmar'
                            icon = '🧾'
                            onClick={handleUpdateProfile}
                            bgColor={'blue'}
                            />

                    </TermOptions>

                    </TermSelection>
                )
            }
        </PersonalData>

    )
};

import React, { useEffect, useState } from 'react'

import CustomStudyTopBarBttn from './CustomStudyTopBarBttn'
import SearchBar from './SearchBar'
import Owl from './../assets/owl.png'
import Books from './../assets/books.png'
import Hospital from './../assets/hospital.png'
import Schedule from './../assets/schedule.png'
import Config from './../assets/control.png'
import Custom from './../assets/custom.png'


import './CustomStudyTopBar.css'
import styled from 'styled-components'



const CustomStudyTopBarContainer = styled.div`
    margin-top: 0vh;
    width: 80%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.5em;

    background-color: ${props => props.theme.darkMode ? 
        `rgb(40, 84, 131, 0.9);` : 
        `rgb(39, 120, 196, 0.7);`
    }


    @media (max-width: 1800px) {
        width: 80%;
        flex-direction: column-reverse;
        justify-content: space-between;
        border-radius: 0.5em;
        padding: 1em 1em 1em 1em;

        flex-wrap: wrap;
    }

    @media (max-width: 500px) {
        width: 100%;
        flex-direction: column-reverse;
        justify-content: space-between;
        border-radius: 0;
        padding: 1em 1em 1em 1em;
        border-radius: 10px;
        
    }
`


export default function CustomStudyTopBar(props) {
    const [selected, setSelected] = useState('Temas')

    function registerBttnClick (clickedBttn) {
        props.setSelectedTab(clickedBttn)
        setSelected(clickedBttn)
    }

    return (
        <CustomStudyTopBarContainer>

            <SearchBar
                currentString = {props.searchString}
                setSearch = {props.setSearch}
                visible = {selected === 'Temas' || selected === 'Instituições'}/>  

            <div className = 'CustomStudyTopBar-BttnsContainer'>
                
                <CustomStudyTopBarBttn
                    icon = {Owl}
                    text = {'Temas'}
                    setSelected = {registerBttnClick}
                    clicked = {selected == 'Temas'} />   


                <CustomStudyTopBarBttn
                    icon = {Books}
                    text = {'Modo'}
                    setSelected = {registerBttnClick}
                    clicked = {selected == 'Modo'} />   

                
                { props.testType === 'Residencia' &&    
                    <> 
                    <CustomStudyTopBarBttn
                        icon = {Hospital}
                        text = {'Instituições'}
                        setSelected = {registerBttnClick}
                        clicked = {selected == 'Instituições'} />

                    <CustomStudyTopBarBttn
                        icon = {Schedule}
                        text = {'Anos'}
                        setSelected = {registerBttnClick}
                        clicked = {selected == 'Anos'} />
                    
                    {
                        props.renderCustomFilters &&
                        <CustomStudyTopBarBttn
                            icon = {Custom}
                            text = {'Meus filtros'}
                            setSelected = {registerBttnClick}
                            clicked = {selected == 'Meus filtros'} />
                    }
                    
                    </>
                }
                { props.testType === 'Flashcards' &&
                    <>
                    {/* Não temos botões específicos aos flashcards. */}
                    </>
                }

                <CustomStudyTopBarBttn
                        icon = {Config}
                        text = {'Outros'}
                        setSelected = {registerBttnClick}
                        clicked = {selected == 'Outros'} /> 

            </div>
        </CustomStudyTopBarContainer>
    )
}
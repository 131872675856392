import React, { useEffect, useState } from 'react'
import MSCard from './MSCard'

import Statue from './../assets/statue.png'
import Strong from './../assets/strong.png'
import DumbbellIcon from './../assets/dumbbell.png'

import ResidenciaIcon from './../assets/residencia.png'
import SurpriseIcon from './../assets/surprise-box.png'

import FlashcardIcon from './../assets/flashcard.png'

import './ButtonsCard.css'
import sessionBuilder from '../controllers/SessionBuilder'
import MSCardBttn from './MSCardBttn'


export default function SurpriseSessionCard(props) {

    function start(testType) {
        props.loadTests(testType, 'random_subject', true)
    }

    return (
        <MSCard
            title = {'Surpresa'}
            icon  = {SurpriseIcon}

            flashcards = {'?'}
            flashcardsAction = {() => start('Flashcards')}

            residencia = {'?'}
            residenciaAction = {() => start('Residencia')} 
            
            text = {(<><b>Quer estudar algo, mas tá difícil escolher um tema?</b> A Osler te ajuda, vamos pegar um tema aleatório que você nunca estudou.</>)} />

    )
}